import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { TailSpin } from 'react-loader-spinner'

import { utils, writeFileXLSX } from 'xlsx';

import FilterDropdown from '../../../sharedComponents/FilterDropdown/FilterDropdown';
import Pagination from '../../../sharedComponents/Pagination/Pagination';
import CreateArticleModal from './CreateArticleModal';

import { listArticlesAPI, saveCurrentArticle, setArticleError, updateArticles } from '../../../api/articleSlice';

import filterLogo from '../../../assets/images/filterLogo.svg'
import downloadLogo from '../../../assets/images/downloadLogo.svg'
import createLogo from '../../../assets/images/createLogo.svg'
import upIcon from '../../../assets/images/upIcon.svg';

function Articles() {
    const dispatch = useDispatch();
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [ascSort, setAscSort] = useState([true, true, true]);
    const [filterArticles, setFilterArticles] = useState([]);
    const [currentRecords, setCurrentRecords] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const { articleLoading, articleError, articles } = useSelector(state => state.articles);

    useEffect(() => {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        let oldArticles = [...articles];

        if (filterArticles.length > 0) {
            const newArticles = oldArticles.filter(handleFilter);
            if (newArticles.length <= recordsPerPage) {
                setCurrentRecords(oldArticles);
            } else {
                setCurrentRecords(newArticles.slice(indexOfFirstRecord, indexOfLastRecord))
            }
        } else {
            setCurrentRecords([...oldArticles.slice(indexOfFirstRecord, indexOfLastRecord)])
        }
    }, [currentPage, recordsPerPage, articleLoading, articles, filterArticles]);

    useEffect(() => {
        if (articles.length === 0) {
            fetchAllArticles();
        }
    }, [])

    const sortArticles = () => {
        let oldSort = [true, true, true];
        if (ascSort[0]) {
            let oldArticles = [...articles];
            oldArticles.sort(function (a, b) {
                return a.id.localeCompare(b.id)
            });
            dispatch(updateArticles([...oldArticles]))
            oldSort[0] = false;
            setAscSort([...oldSort]);
        } else {
            let oldArticles = [...articles];
            oldArticles.sort(function (a, b) {
                return -1 * a.id.localeCompare(b.id)
            });
            dispatch(updateArticles([...oldArticles]))
            oldSort[0] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortByDate = (flag = false) => {
        let oldSort = [true, true, true];
        if (flag ? ascSort[1] : ascSort[2]) {
            let oldArticles = [...articles];
            if (flag) {
                oldArticles.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldArticles.sort(function (a, b) {
                    return new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateArticles([...oldArticles]))
            if (flag) {
                oldSort[1] = false;
            } else {
                oldSort[2] = false;
            }
            setAscSort([...oldSort]);
        } else {
            let oldArticles = [...articles];
            if (flag) {
                oldArticles.sort(function (a, b) {
                    return -1 * new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldArticles.sort(function (a, b) {
                    return -1 * new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateArticles([...oldArticles]))
            if (flag) {
                oldSort[1] = true;
            } else {
                oldSort[2] = true;
            }
            setAscSort([...oldSort]);
        }
    }


    const fetchAllArticles = () => {
        dispatch(listArticlesAPI());
    }

    const handleFilter = (article) => {
        if (filterArticles.length === 0) {
            return true;
        }
        const id = filterArticles.findIndex(e => e === article?.articleType);
        return id !== -1;
    }

    const handleOpenFilterMenu = () => {
        setShowFilterMenu(prev => !prev);
    }

    const handleCurrentArticle = (data) => {
        dispatch(saveCurrentArticle(data));
        setEditFlag(true);
        setOpenCreateModal(true);
    }

    const closeModal = () => {
        dispatch(setArticleError(""));
        setEditFlag(false);
        setOpenCreateModal(false);
    }

    const convertToXLSX = async (data) => {
        try {
            let cleanData = [];
            data.map((ele) => {
                let temp = {};
                temp["ID"] = ele["id"];
                temp["Article URL"] = ele["articleUrl"]
                temp["API URL"] = ele["apiUrl"]
                temp["Article Title"] = ele["articleTitle"]
                temp["Short Title"] = ele["shortTitle"]
                temp["Image URL"] = ele["imageUrl"]
                temp["Slug"] = ele["slug"] || "";
                if (ele.articleContent && ele.articleContent.length > 10000) {
                    let trimmedDesc = ele.articleContent.replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, "").trim();
                    if (trimmedDesc.length <= 25000) {
                        temp["Article Content"] = trimmedDesc;
                    } else {
                        temp["Article Content"] = trimmedDesc.slice(0, 10000);
                    }
                } else {
                    temp["Article Content"] = ele.articleContent.replace(/<[^>]+>/g, '').trim()
                }
                temp["First 80 Characters"] = ele["first80Characters"]
                temp["Article Type"] = ele["articleType"]
                temp["Created At"] = new Date(ele["createdAt"]).toLocaleString()
                temp["Updated At"] = new Date(ele["updatedAt"]).toLocaleString()
                cleanData.push(temp);
            })
            const ws = utils.json_to_sheet(cleanData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "articles");
            writeFileXLSX(wb, "article_data.xlsx");

        } catch (error) {
            console.log(error);
        }
    }

    const tableHeadingClasses = "text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading"

    const tableCellClasses = "min-h-[40px] flex items-center justify-start"

    return (
        <>
            {(articleLoading || currentRecords.length === 0) ? (
                <>
                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                        <TailSpin
                            height="120"
                            width="120"
                            color="#62A6FF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            visible={true}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="container max-w-full">
                        <div className='w-full flex justify-between items-center min-w-max'>
                            <h1 className='text-[32px] font-bold m-[40px]'>Articles</h1>
                            <div className='w-[200px] mr-10 flex justify-around'>
                                <div className='cursor-pointer'>
                                    <img onClick={handleOpenFilterMenu} src={filterLogo} alt="" />
                                    <FilterDropdown
                                        filterItems={filterArticles}
                                        setFilterItems={setFilterArticles}
                                        showFilterMenu={showFilterMenu}
                                        type="Articles"
                                    />
                                </div>
                                <div className='cursor-pointer'>
                                    <img onClick={() => convertToXLSX(currentRecords.filter(handleFilter))} src={downloadLogo} alt="" />
                                </div>
                                <div className='cursor-pointer' onClick={() => { setOpenCreateModal(prev => !prev) }}>
                                    <img src={createLogo} alt="" />
                                </div>
                            </div>
                        </div>

                        <div id='articles-table' className='flex flex-col justify-center text-center overflow-x-auto flex-1 overflow-y-hidden pb-12 min-w-max'>
                            <>
                                <div className='w-full flex'>
                                    <div className={`w-[35vw] lg:w-[20vw] ${tableHeadingClasses} ml-10 pl-4 rounded-l-lg`}>
                                        ID
                                        <img onClick={sortArticles} className={`ml-2 ${ascSort[0] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[45vw] lg:w-[20vw] pl-10 text-sm text-darkGray font-bold h-[51px] flex items-center bg-tableHeading`}>
                                        Article Title
                                    </div>
                                    <div className={`w-[45vw] lg:w-[20vw] text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-center md:justify-start bg-tableHeading`}>
                                        Short Title
                                    </div>
                                    <div className={`w-[60vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Article URL
                                    </div>
                                    <div className={`w-[60vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        API URL
                                    </div>
                                    <div className={`w-[75vw] lg:w-[25vw] ${tableHeadingClasses}`}>
                                        First 80 Characters
                                    </div>
                                    <div className={`w-[35vw] lg:w-[13vw] ${tableHeadingClasses}`}>
                                        Article Type
                                    </div>
                                    <div className={`w-[60vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Slug
                                    </div>
                                    <div className={`w-[75vw] lg:w-[25vw] ${tableHeadingClasses}`}>
                                        Article Content
                                    </div>
                                    <div className={`w-[60vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                        Image URL
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Added
                                        <img onClick={() => sortByDate(true)} className={`ml-2 ${ascSort[1] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses} mr-10 rounded-r-lg`}>
                                        Updated
                                        <img onClick={() => sortByDate(false)} className={`ml-2 ${ascSort[2] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                </div>

                                {articles && currentRecords.filter(handleFilter).map((ele, i) => {
                                    if (ele === null || ele === undefined) {
                                        return;
                                    }
                                    const {
                                        id,
                                        articleUrl,
                                        apiUrl,
                                        articleTitle,
                                        shortTitle,
                                        imageUrl,
                                        first80Characters,
                                        articleContent,
                                        articleType,
                                        createdAt,
                                        updatedAt,
                                        slug
                                    } = ele

                                    const parser = new DOMParser();
                                    const charsDesc = parser.parseFromString(first80Characters, "text/html")
                                    const content = parser.parseFromString(articleContent, "text/html")

                                    return (
                                        <div className='w-full flex my-3'>
                                            <div onClick={()=>handleCurrentArticle(ele)} className={`w-[35vw] lg:w-[20vw] cursor-pointer underline pl-4 text-blue-500 text-left truncate ${tableCellClasses} ml-10`}>
                                                <div className='w-[95%] overflow-x-auto'>
                                                    {id}
                                                </div>
                                            </div>
                                            <div className={`w-[45vw] lg:w-[20vw] pl-10 cursor-pointer flex items-center text-left justify-center md:justify-start min-h-[40px]`}>
                                                <p className='w-[90%] overflow-x-auto'>
                                                    {articleTitle}
                                                </p>
                                            </div>
                                            <div className={`w-[45vw] lg:w-[20vw] cursor-pointer ml-2 min-h-[40px] flex items-center text-left justify-center md:justify-start`}>
                                                <p className='w-[90%] overflow-x-auto'>
                                                    {shortTitle}
                                                </p>
                                            </div>
                                            <div className={`w-[60vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                <a href={articleUrl} target="_blank" style={{ width: "90%" }} className='truncate ml-2 text-left'>
                                                    {articleUrl}
                                                </a>
                                            </div>
                                            <div className={`w-[60vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                <a href={apiUrl} target="_blank" style={{ width: "90%" }} className='truncate ml-2 text-left'>
                                                    {apiUrl}
                                                </a>
                                            </div>
                                            <div style={{ justifyContent: "flex-start" }} className={`w-[75vw] lg:w-[25vw] p-9 lg:p-0 ${tableCellClasses} text-justify`}>
                                                <div style={{ width: "90%", maxHeight: "300px" }} className='overflow-auto whitespace-pre-line'>
                                                    {charsDesc.documentElement.textContent.trim()}
                                                </div>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[13vw] ${tableCellClasses}`}>
                                                {articleType}
                                            </div>
                                            <div className={`w-[60vw] lg:w-[20vw] ${tableCellClasses} truncate`}>
                                                {slug}
                                            </div>
                                            <div style={{ justifyContent: "flex-start" }} className={`w-[75vw] lg:w-[25vw] p-9 lg:p-0 ${tableCellClasses} text-justify`}>
                                                <div style={{ width: "90%", maxHeight:"300px" }} className='overflow-auto whitespace-pre-line'>
                                                    {content.documentElement.textContent.trim()}
                                                </div>
                                            </div>
                                            <div className={`w-[60vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                <a href={imageUrl} target="_blank" style={{ width: "90%" }} className='truncate ml-2 text-left'>
                                                    {imageUrl}
                                                </a>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses} mr-10`}>
                                                {new Date(createdAt).toLocaleDateString()}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses} mr-10`}>
                                                {updatedAt ? new Date(updatedAt).toLocaleDateString() : new Date().toLocaleDateString()}
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        </div>

                        <Pagination
                            size={articles.filter(handleFilter).length || 0}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setRecordsPerPage={setRecordsPerPage}
                            type='Articles'
                            recordsPerPage={recordsPerPage}
                        />

                            {/* Create Article Modal */}
                            <Transition appear show={openCreateModal} as={Fragment}>
                                <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 overflow-y-auto">
                                        <div className="flex min-h-full justify-center p-4">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 scale-95"
                                                enterTo="opacity-100 scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 scale-100"
                                                leaveTo="opacity-0 scale-95"
                                            >
                                                <Dialog.Panel className="transition-all">
                                                    <CreateArticleModal editFlag={editFlag} setEditFlag={setEditFlag} setOpenCreateModal={setOpenCreateModal} openCreateModal={openCreateModal} />
                                                </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition>
                    </div>
                </>
            )}
        </>
    )
}

export default Articles