import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import {
  getEmployer,
  listEmployees,
  listGoals,
  getGoal,
} from '../graphql/queries';
import { updateEmployee } from '../graphql/mutations';
import { handleAPICall } from './APIUtils';
import { fetchAllDataFromGraphQLQuery } from '../common/Utility';

const initialState = {
  loading: false,
  error: '',
  user: {},
  userListLoading: false,
  userList: [],
  currentUser: {},
  id: '',
  goalLoading: false,
  goalError: '',
  goals: [],
  broadcastLoading: false,
  broadcastError: '',
  broadcastSuccess: '',
  createUserLoading: false,
  createUserError: ""
};

export const getUserData = createAsyncThunk(
  'user/fetchUser',
  async (userId) => {
    return (
      await API.graphql({
        query: getEmployer,
        variables: { id: userId },
      })
    ).data.getEmployer;
  },
);

export const getUserListAPI = createAsyncThunk(
  'user/fetchUserList',
  async (_, thunkAPI) => {
    try {
      const data = await handleAPICall("merchantApi", "/merchant/user?page=1&limit=1000", {}, "GET");

      if (data.error && Object.keys(data.error).length > 0) {
        return thunkAPI.rejectWithValue(data.error || 'Something went wrong!');
      }
      return data.success?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getUserGoalsAPI = createAsyncThunk(
  'user/goals',
  async (body, thunkAPI) => {
    try {
      const { id } = body;
      const res = await fetchAllDataFromGraphQLQuery({
        query: listGoals,
        variables: {
          limit: 2000,
          filter: {
            userId: {
              eq: id,
            },
          },
        },
        queryName: 'listGoals',
      });

      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createUserAPI = createAsyncThunk(
  'user/create',
  async ({ body, callback = () => { } }, thunkAPI) => {
    try {
      const options = {
        body: body
      };
      const res = await handleAPICall("merchantApi", "/merchant/create/user", options, "POST")
      callback(res)
      if (res.error && Object.keys(res.error).length > 0) {
        return thunkAPI.rejectWithValue(res.error || 'Something went wrong!');
      }
      return res.success;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const updateUserAPI = createAsyncThunk(
  'user/update',
  async ({ body, callback = () => { } }, thunkAPI) => {
    try {
      const id = body.id;
      delete body.id;
      const options = {
        body: body
      };
      const res = await handleAPICall("merchantApi", `/merchant/user/${id}`, options, "UPDATE")
      callback(res)
      if (res.error && Object.keys(res.error).length > 0) {
        return thunkAPI.rejectWithValue(res.error || 'Something went wrong!');
      }
      return { data: res.success.data, merchant_user_id: id, merchant_id: body?.merchant_id };
    } catch (error) {
      callback({
        errors: [
          {
            message: error.message,
          },
        ],
      });
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const sendBroadcastMessageAPI = createAsyncThunk(
  'broadcast/send',
  async ({ body, callback = () => { } }, thunkAPI) => {
    try {
      const options = {
        body: body,
      };
      const data = await handleAPICall(
        'broadcastMessageApi',
        '',
        options,
        'POST',
      );
      callback(data);
      if (data.errors && Object.keys(data.errors).length > 0) {
        return thunkAPI.rejectWithValue(
          data.errors[0]?.message || 'Something went wrong!',
        );
      }
      return data.success;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    saveCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    updateUserList: (state, action) => {
      state.userList = action.payload;
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.user = action.payload;
      state.id = action.payload?.id;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.user = {};
    });

    builder.addCase(getUserListAPI.pending, (state) => {
      state.userListLoading = true;
      state.error = '';
    });
    builder.addCase(getUserListAPI.fulfilled, (state, action) => {
      state.userListLoading = false;
      state.userList = action.payload?.items;
      state.error = '';
    });
    builder.addCase(getUserListAPI.rejected, (state, action) => {
      state.userListLoading = false;
      try {
        state.error = action.payload.error.response.data.error.message;
      } catch (error) {
        state.error = 'Something went Wrong';
      }
    });

    builder.addCase(getUserGoalsAPI.pending, (state, action) => {
      state.goalLoading = true;
      state.goalError = '';
    });
    builder.addCase(getUserGoalsAPI.fulfilled, (state, action) => {
      state.goalLoading = false;
      state.goalError = '';
      state.goals = [...action.payload];
    });
    builder.addCase(getUserGoalsAPI.rejected, (state, action) => {
      state.goalLoading = false;
      try {
        state.goalError = action.payload.error.response.data.error.message;
      } catch (error) {
        state.goalError = 'Something went Wrong';
      }
    });

    builder.addCase(updateUserAPI.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updateUserAPI.fulfilled, (state, action) => {
      state.loading = false;
      try {
        const updatedEmployee = action.payload.data;
        const id = action.payload['merchant_user_id'];
        const it = state.userList.findIndex((e) => e.merchant_user_id === id);
        if (it !== -1) {
          state.userList[it] = { ...state.userList[it], ...updatedEmployee, merchant_user_id: id };
        }
      } catch (error) { console.log(error); }
      state.error = '';
    });
    builder.addCase(updateUserAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(sendBroadcastMessageAPI.pending, (state, action) => {
      state.broadcastLoading = true;
      state.broadcastError = '';
    });

    builder.addCase(sendBroadcastMessageAPI.fulfilled, (state, action) => {
      state.broadcastLoading = false;
      try {
        state.broadcastSuccess = action.payload[0].message;
      } catch (error) {
        state.broadcastSuccess = 'Message Sent Successfully';
      }
    });

    builder.addCase(sendBroadcastMessageAPI.rejected, (state, action) => {
      state.broadcastLoading = false;
      state.broadcastError = action.payload || 'Something went wrong!';
    });

    builder.addCase(createUserAPI.pending, (state) => {
      state.createUserLoading = true;
      state.createUserError = ""
    })
    builder.addCase(createUserAPI.fulfilled, (state, action) => {
      state.userList = [...state.userList, action.payload.data]
      state.createUserLoading = false;
    })
    builder.addCase(createUserAPI.rejected, (state, action) => {
      state.createUserLoading = false;
      try {
        state.error =
          action.payload.error.response.data.error.message;
      } catch (error) {
        state.error = 'Something went Wrong';
      }
    })
  },
});

export const { saveCurrentUser, updateUserList, setUserError } =
  userSlice.actions;

export default userSlice.reducer;
