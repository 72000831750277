import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { listMerchantData } from '../../api/merchantSlice';
import Sidebar from '../../sharedComponents/Sidebar/Sidebar';
import SearchInput from '../../sharedComponents/SearchInput/SearchInput';
import AkbarTravelLogo from '../../assets/images/merchantLogo/akbarTravel.svg';
import ResponsiveNavLogo from '../../assets/images/responsiveNavLogo.svg';

import { getUserData } from '../../api/userSlice';
import { listMerchantProductCategoryAPI } from '../../api/productCategorySlice';

import './DashboardWrapper.scss';

const DashboardWrapper = ({ children: wrappedPage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { merchantList } = useSelector((state) => state.merchant);
  const { merchantProductCategories } = useSelector(
    (state) => state.merchantProductCat
  );
  const menuRef = useRef(null);
  const sidebarRef = useRef(null);
  const [active, setActive] = useState(location.pathname.split('/')[1]);

  useEffect(() => {
    dispatch(getUserData('saathiadmin'));
    if (!merchantProductCategories || merchantProductCategories.length === 0) {
      dispatch(listMerchantProductCategoryAPI());
    }
    // List merchant data and store in Redux:
    if (!merchantList || merchantList.length === 0) {
      dispatch(listMerchantData());
    }
  }, []);

  function handleNavigation(active) {
    setActive(active);
    navigate(active);
  }

  // Update the Sidebar nav if user clicks the back button in their browser:
  window.onpopstate = () => {
    setActive(window.location.href.split('/').pop());
  };

  const handleMenuBar = () => {
    menuRef.current.classList.toggle('change');
    sidebarRef.current.classList.toggle('left-[-300px]');
  };

  const sidebarClasses =
    'border border-1 h-full absolute z-40 left-[-300px] w-[248px] lg:left-0 lg:w-[17vw]';

  const dashboardLayoutClasses =
    'border overflow-y-auto border-1' +
    ' h-full absolute left-0 lg:left-[17vw] lg:w-[calc(100%_-_17vw)] w-full';

  const searchBarClasses =
    'w-full h-[56px] flex items-center justify-between border-b border-sidebarActive';

  return (
    <div id="admin-dashboard" className="min-h-[100vh]">
      <div ref={sidebarRef} className={sidebarClasses}>
        <Sidebar
          handleMenuBar={handleMenuBar}
          active={active}
          handleNavigation={handleNavigation}
        />
      </div>
      <div id="dashboard-layout" className={dashboardLayoutClasses}>
        <div
          id="searchbar"
          className={`${searchBarClasses} ${
            active === 'Merchants' && ' min-w-[1200px]'
          }`}
        >
          <div
            className="lg:hidden z-50 inline-block ml-2 cursor-pointer"
            onClick={handleMenuBar}
            ref={menuRef}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
          <SearchInput />
          {/* <img src={AkbarTravelLogo} className="mr-[42px]" alt="" /> */}
        </div>
        <div id="layout" className="w-full h-full mb-14">
          {wrappedPage}
        </div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
