import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { handleAPICall, checkStatusCode } from './APIUtils';
import { listMerchantProducts } from '../graphql/queries';
import { fetchAllDataFromGraphQLQuery } from '../common/Utility';

const initialState = {
  productList: [],
  productListError: '',
  productListLoading: false,
  currentProduct: {},
};

export const listMerchantProductAPI = createAsyncThunk(
  'product/listProduct',
  async (_, thunkAPI) => {
    try {
      const res = await fetchAllDataFromGraphQLQuery({
        query: listMerchantProducts,
        variables: {
          limit: 2000,
        },
        queryName: 'listMerchantProducts',
      });
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createProductAPI = createAsyncThunk(
  'product/createProduct',
  async ({ body, callback = () => { } }, thunkAPI) => {
    try {
      const options = {
        body: body,
        isBase64Encoded: true,
      };
      const data = await handleAPICall(
        'merchantApi',
        '/merchant/product',
        options,
        'POST',
      );
      callback(data);
      if (data.error && Object.keys(data.error).length > 0) {
        return thunkAPI.rejectWithValue(data.error || 'Something went wrong!');
      }
      return data.success;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateProductAPI = createAsyncThunk(
  'product/updateProduct',
  async ({ body, callback = () => { } }, thunkAPI) => {
    try {
      const options = {
        body: body,
        isBase64Encoded: true,
      };
      const data = await handleAPICall(
        'merchantApi',
        `/merchant/product`,
        options,
        'UPDATE',
      );
      callback(data);
      if (data.error && Object.keys(data.error).length > 0) {
        return thunkAPI.rejectWithValue(data.error || 'Something went wrong!');
      }
      return data.success;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    saveCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
    },
    setProductError: (state, action) => {
      state.productListError = action.payload;
    },
    updateProduct: (state, action) => {
      state.productList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listMerchantProductAPI.pending, (state) => {
      state.productListLoading = true;
      state.productListError = '';
    });
    builder.addCase(listMerchantProductAPI.fulfilled, (state, action) => {
      state.productListLoading = false;
      state.productListError = '';
      state.productList = action.payload;
    });
    builder.addCase(listMerchantProductAPI.rejected, (state, action) => {
      state.productListLoading = false;
      state.productList = action.payload?.data?.listMerchantProducts?.items;
      // console.log(action.payload);
      try {
        state.productListError =
          action.payload.error.response.data.error.message;
      } catch (error) {
        state.productListError = 'Something went Wrong';
      }
    });

    builder.addCase(createProductAPI.pending, (state) => {
      state.productListLoading = true;
      state.productListError = '';
    });
    builder.addCase(createProductAPI.fulfilled, (state, action) => {
      state.productListLoading = false;
      state.productListError = '';
      state.productList = [...state.productList, action.payload?.data];
    });
    builder.addCase(createProductAPI.rejected, (state, action) => {
      state.productListLoading = false;
      try {
        state.productListError =
          action.payload.error.response.data.error.message;
      } catch (error) {
        state.productListError = 'Something went Wrong';
      }
    });

    builder.addCase(updateProductAPI.pending, (state) => {
      state.productListLoading = true;
      state.productListError = '';
    });
    builder.addCase(updateProductAPI.fulfilled, (state, action) => {
      state.productListError = '';
      let index = -1;
      let oldArr = [...state.productList];
      for (let i = 0; i < oldArr.length; i++) {
        const element = oldArr[i];
        if (element.productID === action.payload?.data.productID) {
          for (const key in action.payload.data) {
            if (action.payload.data.hasOwnProperty(key)) {
              oldArr[i][key] = action.payload.data[key];
            }
          }
          break;
        }
      }
      state.productList = [...oldArr];
      state.productListLoading = false;
    });
    builder.addCase(updateProductAPI.rejected, (state, action) => {
      state.productListLoading = false;
      try {
        state.productListError =
          action.payload.error.response.data.error.message;
      } catch (error) {
        state.productListError = 'Something went Wrong';
      }
    });
  },
});

export const { saveCurrentProduct, setProductError, updateProduct } =
  productSlice.actions;
export default productSlice.reducer;
