import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';
import upload from '../../assets/images/upload.svg';
type Props = {
    onDrop: any,
    files: any[]
}

function DropZone({ onDrop, files }: Props) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [],
            "application/vnd.ms-excel": [],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
            "text/csv": [],
            "application/x-ms-excel": [],
            "application/xls": []
        },
        onDrop: onDrop
    });
    return (
        <div {...getRootProps({ className: 'w-full h-full border border-onboardingBorder border-dashed rounded-lg' })}>
            <input {...getInputProps()} />
            {files.length > 0 ? (
                <>
                    <p className='text-center mt-2 cursor-pointer'>{files[0]?.name}</p>
                </>
            ) : (
                <div className='flex flex-col justify-center items-center h-full cursor-pointer'>
                    <p className='text-mutedText'>
                        Drag and drop to upload or
                    </p>
                    <div className='flex justify-center'>
                        <h2 className="text-xl mr-2">Browse</h2>
                        <img src={upload} alt="upload" />
                    </div>
                </div>
            )}

        </div>
    )
}

export default DropZone