import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Compact from '@uiw/react-color-compact';
import toast from 'react-hot-toast';
import SelectMenu from '../../../sharedComponents/SelectMenu/SelectMenu';

import InputField from '../../../sharedComponents/InputField/InputField'

import {
    createMerchantProductCategoryAPI,
    updateMerchantProductCategoryAPI,
    setMerchantProductCatError
} from '../../../api/productCategorySlice';

function CreateCategoryModal({ setOpenCreateModal, editFlag, setEditFlag, openCreateModal }) {
    const [hexColor, setHexColor] = useState("");
    const dispatch = useDispatch();
    const { currentProductCategory, productCategoryError } = useSelector(
        state => state.merchantProductCat
    );

    useEffect(() => {
        if (editFlag) {
            const color = currentProductCategory.backgroundColourHex ? `${currentProductCategory.backgroundColourHex}` : "#FFFFFF";
            setHexColor(color)
        }
    }, [])


    const callback = (data, flag = false) => {
        if (data.error && Object.keys(data.error).length > 0) {
            try {
                toast.error(`${data.error.error.response.data.error.message}`);
            } catch (error) {
                toast.error(`Error while ${flag ? "Editing" : "Creating"} Category`);
            }
            return;
        }
        if (data.success.code) {
            toast.success(`${data.success.message}`);
        }
    }

    const onSubmitForm = (e) => {
        if (!openCreateModal) {
            e.preventDefault();
            return;
        }
        if (!e.currentTarget.checkValidity()) {
            toast.error("Please fill the details correctly");
            return;
        }
        e.preventDefault();
        try {
            const formElements = e.target.elements;
            let formData = {};
            for (let i = 0; i < formElements.length; i++) {
                const ele = formElements[i];
                const name = ele.name;
                if (name.length > 0) {
                    formData[name.trim()] = ele.value;
                }
            }
            formData["backgroundColourHex"] = hexColor;
            if (editFlag) {
                formData["ID"] = currentProductCategory.category_id;
                dispatch(updateMerchantProductCategoryAPI({ body: formData, callback }));
            } else {
                dispatch(createMerchantProductCategoryAPI({ body: formData, callback }))
            }
            setOpenCreateModal(false);
        } catch (error) {
            setOpenCreateModal(true);
            dispatch(setMerchantProductCatError(error));
        }
    }

    const closeModal = () => {
        dispatch(setMerchantProductCatError(""))
        setEditFlag(false);
        setOpenCreateModal(false);
    }
    const statusItem = [
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Inactive",
            value: "inactive"
        }
    ]
    return (
        <div id='create-category-modal' className='absolute top-1/2 left-1/2 w-[80vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'>
            <div className="container p-3 py-6 flex flex-col items-center md:p-10">
                <h1 className="text-[24px] mb-4">
                    {editFlag ? "Edit Cateogry" : "Create Cateogry"}
                </h1>
                <form onSubmit={onSubmitForm} className="w-full flex flex-col items-center">
                    <div className='w-full md:w-4/5'>
                        <InputField req name='categoryName' value={!editFlag ? "" : currentProductCategory?.category_name} label="Cateogry Name" placeholder='Ex: lifestyle' />
                    </div>
                    <div className="mt-4 w-full md:w-4/5">
                        <InputField req name='categoryImage' value={!editFlag ? "" : currentProductCategory?.category_icon_url} label="Cateogry Image" placeholder='http://....' />
                    </div>

                    <div className='mt-4 w-full md:w-4/5'>
                        <label htmlFor="backgroundColor" className="block text-sm font-medium text-gray-700">
                            Background Color
                            <span className="text-red-700">
                                *
                            </span>
                        </label>
                        <div className='mt-1 w-full flex justify-center md:justify-start'>
                            <Compact color={hexColor} onChange={(color) => setHexColor(color.hex)} />
                        </div>
                    </div>

                    <div className="mt-4 w-full md:w-4/5">
                        <SelectMenu req value={editFlag && currentProductCategory?.category_active} name='status' label="Status" options={statusItem} />
                    </div>

                    <div className="grid grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full md:w-full'>
                            <button onClick={() => closeModal()} className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center' >
                                Cancel
                            </button>
                        </div>
                        <div className='w-full md:w-full'>
                            <button type='submit' className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full flex justify-center items-center' >
                                {editFlag ? "Save" : "Add"}
                            </button>
                        </div>
                    </div>
                </form>
                {(productCategoryError && productCategoryError.length > 0) && (
                    <div className='block text-sm text-red-600'>{productCategoryError}</div>
                )}
            </div>
        </div>
    )
}

export default CreateCategoryModal