import dashboard from '../../assets/images/sidebarLogo/dashboard.svg';
import articles from '../../assets/images/sidebarLogo/articles.svg';
import merchant from '../../assets/images/sidebarLogo/merchant.svg';
import products from '../../assets/images/sidebarLogo/products.svg';
import reports from '../../assets/images/sidebarLogo/reports.svg';
import users from '../../assets/images/sidebarLogo/users.svg';
import category from '../../assets/images/sidebarLogo/categoryLogo.svg';
import upload from '../../assets/images/sidebarLogo/upload.svg';
import bucket from '../../assets/images/sidebarLogo/bucket.svg';
import token from '../../assets/images/sidebarLogo/tokens.svg';

const sidebarLinks: {
  svgLogo: string;
  link: string;
}[] = [
  {
    svgLogo: dashboard,
    link: 'Dashboard',
  },
  {
    svgLogo: merchant,
    link: 'Merchants',
  },
  {
    svgLogo: products,
    link: 'Products',
  },
  {
    svgLogo: category,
    link: 'Categories',
  },
  {
    svgLogo: articles,
    link: 'Articles',
  },
  // {
  //   svgLogo: token,
  //   link: 'Tokens',
  // },
  {
    svgLogo: token,
    link: 'Goals',
  },
  // {
  //     logo: reports,
  //     link: "Reports"
  // },
  {
    svgLogo: users,
    link: 'Users',
  },
  {
    svgLogo: upload,
    link: 'Uploads',
  },
  {
    svgLogo: bucket,
    link: 'Bucket List',
  },
];

export default ({
  active,
  setActive,
  handleMenuBar,
}: {
  active: any;
  setActive: any;
  handleMenuBar: any;
}) => {
  const handleActive = (link: string) => {
    handleMenuBar();
    setActive(link);
  };

  const classes = (flag: boolean) => {
    return `flex cursor-pointer items-center w-[216px] lg:w-[16vw] h-[40px] m-auto mt-[5px] rounded-[8px] ${
      flag && 'bg-white shadow-sidebarActive border-2 border-sidebarActive'
    }`;
  };

  return (
    <div className="link-group relative top-[-40px] lg:top-[-50px]">
      {sidebarLinks.map(({ link, svgLogo }, i) => {
        const flag = link === active;

        return (
          <div
            onClick={() => handleActive(link)}
            key={`link_${i}`}
            className={classes(flag)}
          >
            <img
              width={16}
              height={16}
              className="sidebar-logo mx-[16px]"
              src={svgLogo}
              alt={link}
            />
            <div className="text-[16px] font-bold">{link}</div>
          </div>
        );
      })}
    </div>
  );
};
