import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { createGoalConversionStatusAPI, updateConversionStatusAPI } from '../../api/goalSlice'
import SelectMenu from '../SelectMenu/SelectMenu'
import tick from '../../assets/images/tick.svg'

function ConversionStatusModal({ openModal, closeModal, goal, editFlag, currentOrderAmount }) {
    const { createConversionLoading, updateConversionLoading } = useSelector(state => state.goals);
    const dispatch = useDispatch();

    const opts = [
        {
            label: "Confirmed",
            value: "confirmed"
        },
        {
            label: "Refund",
            value: "refund"
        },
        {
            label: "Cancelled",
            value: "cancelled"
        },
        {
            label: "Purchased",
            value: "purchased"
        },
        {
            label: "Payment Cleared",
            value: "payment_cleared"
        },
    ]

    const callback = (data, flag = false) => {
        if (data.error && Object.keys(data.error).length > 0) {
            try {
                toast.error(`${data.error.error.response.data.error.message}`);
            } catch (error) {
                toast.error(`Error while ${flag ? "Editing" : "Creating"} Conversion Status`);
            }
            return;
        } else {
            toast.success(`Conversion Status Created Successfully`);
            closeModal();
        }

    }

    const onSubmit = () => {
        const form = document.getElementById("conversion-status-form");
        const formData = new FormData(form);
        const values = {};

        formData.forEach((value, key) => {
            values[key] = value;
        });

        if (editFlag) {
            updateConversionStatus(values);
        } else {
            createConversionStatus(values);
        }
    }

    const createConversionStatus = (body) => {
        dispatch(createGoalConversionStatusAPI({ body: body, callback: callback }));
    }

    const updateConversionStatus = (body) => {
        dispatch(updateConversionStatusAPI({ body: body, callback: callback, conversionId: goal?.conversions?.id }));
    }
    return (
        <div className='absolute top-1/2 left-1/2 w-[552px] min-h-[380px] rounded-[8px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'>
            <div className="container p-6 pb-0">
                <div className='w-full flex items-center'>
                    <div className='w-1 h-6 rounded-tr-sm rounded-br-sm bg-saathiPink relative -left-6' />
                    <h3 className='text-base'>{editFlag ? "Update Conversion Status" : "Create Conversion Status"}</h3>
                </div>

                <form id='conversion-status-form' className="flex flex-col justify-start gap-4 my-4">
                    <div className='flex flex-col justify-center mb-3'>
                        <label className='login-label' htmlFor="first_name">Goal ID</label>
                        <input className='login-input' minLength={2} readOnly type="text" name='goalId' defaultValue={goal?.id} placeholder='Goal ID' />
                    </div>
                    <div className='flex-col justify-center mb-3 hidden'>
                        <label className='login-label' htmlFor="first_name">User ID</label>
                        <input className='login-input' minLength={2} readOnly type="text" name='userId' defaultValue={goal?.userId} placeholder='User ID' />
                    </div>
                    <div className='flex flex-col justify-center mb-3'>
                        <label className='login-label' htmlFor="status">Conversion Status</label>
                        <SelectMenu value={goal?.conversions?.status} name='status' options={opts} classes='login-input' />
                    </div>
                    <div className='flex flex-col justify-center mb-3'>
                        <label className='login-label' htmlFor="last_name">Order Amount</label>
                        {editFlag ? (
                            <input className='login-input' min={0} minLength={2} type="text" name='orderAmount' readOnly defaultValue={currentOrderAmount} placeholder='Order Amount' />
                        ) : (
                            <input className='login-input' min={0} minLength={2} type="number" name='orderAmount' defaultValue={currentOrderAmount} placeholder='Order Amount' />
                        )}

                    </div>

                </form>
            </div>
            <div style={{ boxShadow: "0px -1px 0px 0px #E0E0E0" }} className='rounded-bl-lg rounded-br-lg flex justify-end gap-4'>
                {/* <div className='col-span-4' /> */}
                <div>
                    <button onClick={closeModal} className='flex items-center justify-center mx-auto my-3 py-3 px-6 rounded-lg bg-white border-[1.5px] border-onboardingBorder'>
                        Cancel
                    </button>
                </div>
                <div className="mr-6">
                    <button disabled={editFlag ? updateConversionLoading : createConversionLoading} onClick={() => onSubmit()} className='bg-black flex items-center justify-center rounded-lg text-white pl-6 pr-3 py-3 mx-auto my-3 disabled:bg-slate-800'>
                        <div className='flex items-center gap-3'>
                            <h3>Confirm</h3>
                            <img src={tick} alt="confirm" />
                        </div>
                    </button>
                </div>

            </div>
        </div>
    )
}

export default ConversionStatusModal