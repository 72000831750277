import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { listUserRewardDetailss, listSaathiTokenActivitys } from '../graphql/queries';
import { fetchAllDataFromGraphQLQuery } from '../common/Utility';
import { handleAPICall } from './APIUtils';
import { docClient } from '../aws-dynamodb-settings';

const initialState = {
    rewardsLoading: false,
    rewards: [],
    rewardsError: "",
    tokenActivityLoading: false,
    tokenActivities: [],
    tokenActivityError: ""
}

async function fetchAllElementsFromDB(params) {
    let scanResults = [];
    let items;
    do {
        items = await docClient.scan(params).promise();
        items.Items.forEach((item) => scanResults.push(item));
        params.ExclusiveStartKey = items.LastEvaluatedKey;
    } while (typeof items.LastEvaluatedKey !== "undefined");

    return scanResults;
}

async function querySingleItemFromDB(params) {
    try {
        const data = await docClient.get(params).promise();
        return data.Item;
    } catch (err) {
        console.error("Error getting item:", err);
        return null;
    }
}

async function queryUserData(id) {
    try {
        const data = await handleAPICall("saathiAPI", `/user/${id}`, {}, "GET");
        if (data.error && Object.keys(data.error).length > 0) {
            return null;
        }
        return data;
    } catch (err) {
        console.error("Error getting item:", err);
        return null;
    }
}

export const listAllUserRewards = createAsyncThunk("rewards/list", async(_, thunkApi) => {
    try {
        const res = await fetchAllDataFromGraphQLQuery({
            query: listUserRewardDetailss,
            variables: {
                limit: 2000,
            },
            queryName: 'listUserRewardDetailss',
        });
        // console.log(res);
        return res;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})

export const listAllTokenActivity = createAsyncThunk("rewards/token/list", async(_, thunkApi) => {
    try {
        const TABLE_NAME = process.env.REACT_APP_ENVIRONMENT === 'production' ? "SaathiTokenActivity-k66lii7zgbhmni3saovaf3j62m-burner" : "SaathiTokenActivity-nfbore755namdaboqdcvmj7azy-devtwo";
        const params = {
            TableName: TABLE_NAME
        }

        let allTokenActivities = await fetchAllElementsFromDB(params);

        await Promise.all(allTokenActivities.map(async (ele) => {
            try {
                const { linkedCustomerId, id, goalId } = ele;
                if(linkedCustomerId) {
                    const user = await queryUserData(linkedCustomerId);
                    if(user) {
                        ele["user"] = user;
                        return ele;
                    } else {
                        ele["user"] = false;
                        return ele
                    }
                    
                } else {
                    ele["user"] = false;
                    return ele
                }
            } catch (error) {
                console.log("USER NOT FOUND");
                ele["user"] = false;
                return ele
            }
        }));
        allTokenActivities = allTokenActivities.filter((e) => {
            return e["user"] !== false;
        });
        console.log(allTokenActivities);
        return allTokenActivities;

    } catch (error) {
        console.log(error);
        
        return thunkApi.rejectWithValue(error);
    }
});

export const rewardsSlice = createSlice({
    name: "rewards",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(listAllUserRewards.pending, (state) => {
            state.rewardsLoading = true;
            state.rewardsError = "";
        })
        builder.addCase(listAllUserRewards.fulfilled, (state, action) => {
            state.rewardsLoading = false;
            state.rewards = action.payload;
        })
        builder.addCase(listAllUserRewards.rejected, (state, action) => {
            state.rewardsLoading = false;
            try {
                state.rewardsError =
                    action.payload.error.response.data.error.message;
            } catch (error) {
                state.rewardsError = 'Something went Wrong';
            }
        })
        builder.addCase(listAllTokenActivity.pending, (state) => {
            state.tokenActivityLoading = true;
            state.tokenActivityError = ""
        })
        builder.addCase(listAllTokenActivity.fulfilled, (state, action) => {
            state.tokenActivityLoading = false;
            state.tokenActivities = action.payload;
        })
        builder.addCase(listAllTokenActivity.rejected, (state, action) => {
            state.tokenActivityLoading = false;
            state.tokenActivityError = action.payload;
        })
    }

})

export default rewardsSlice.reducer;