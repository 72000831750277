import { useState } from 'react';
import { handleCategoryFileUpload } from '../../utils';
import { classify } from '../../../../../common/Utility';

export type CategoryInput = {
  name?: string;
  description?: string;
  merchantId?: string;
  horizontalImageUrl?: string;
  verticalImageUrl?: string;
};

const UploadCategoriesFile = ({
  allExistingCategories,
  setAllCategories,
  closeModal,
}: {
  allExistingCategories: IdeaCategory[];
  setAllCategories: (newList: IdeaCategory[]) => void;
  closeModal: () => void;
}) => {
  const [fileUploading, setFileUploading] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');

  async function processUpload(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setFileUploading(true);
    const targetFile = e.target.files[0];

    handleCategoryFileUpload(
      targetFile,
      allExistingCategories,
      setAllCategories,
      (err: string) => {
        setUploadErrorMessage(err);
        setFileUploading(false);
      },
      () => {
        setFileUploading(false);
        closeModal();
      }
    );
  }

  return (
    <div className="flex flex-col items-start p-6">
      <div className="flex flex-col gap-0 mb-4 mt-6 w-full">
        <h1>UPLOAD FILE</h1>

        <h4 className="mb-0 mt-6">
          Columns must be the following name and order:
        </h4>
        <p className="mb-0">
          'Name', 'Description', 'Image URL(Vertical)', 'Image URL(Horizontal)',
          'Merchant ID'
        </p>

        <h4 className="mb-0 mt-4">The following fields are mandatory:</h4>
        <p className="mb-4">
          'Name', 'Description', 'Image URL(Vertical)', 'Image URL(Horizontal)'
        </p>

        <input
          className="my-4"
          type={'file'}
          accept={'.csv, .xlsx'}
          onChange={processUpload}
        />

        {fileUploading && <h1>LOADING</h1>}

        {uploadErrorMessage && (
          <p className={classify(['text-red-500'])}>{uploadErrorMessage}</p>
        )}
      </div>
    </div>
  );
};
export default UploadCategoriesFile;
