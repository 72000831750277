import Compact from '@uiw/react-color-compact';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
// import imageToBase64 from 'image-to-base64';
import { createMerchantAPI, updateMerchantAPI } from '../../../api/merchantSlice';
import { defaultBackground } from '../../../constants/DefaultBackground';
import DropZone from '../../../sharedComponents/DropZone/DropZone';
import FileUploadInput from '../../../sharedComponents/FileUploadInput/FileUploadInput';
import InputField from '../../../sharedComponents/InputField/InputField';
import SelectMenu from '../../../sharedComponents/SelectMenu/SelectMenu';
import MerchantCategoryDropdown from './MerchantCategoryDropdown';

function CreateMerchantModal({ setOpenCreateModal, editFlag, setEditFlag, openCreateModal }) {
  const [merchantCat, setMerchantCat] = useState([]);
  const [logoBase64, setLogoBase64] = useState('');
  const [hexColor, setHexColor] = useState('#000');
  const [bPANFile, setBPANFile] = useState([]);
  const [bPANOwnerFile, setBPANOwnerFile] = useState([]);
  const [gstFile, setGstFile] = useState([]);
  const dispatch = useDispatch();
  const { currentMerchant } = useSelector((state) => state.merchant);
  const { merchantProductCategories } = useSelector((state) => state.merchantProductCat);

  useEffect(() => {
    if (editFlag) {
      setHexColor(currentMerchant.backgroundCircleHexColour || '#FFFFFF');
    }
    let s = new Set();
    let temp = [];
    merchantProductCategories.map((ele) => {
      s.add(ele.category_name);
    });
    for (const it of s) {
      temp.push({
        label: it,
        value: it,
      });
    }
    setMerchantCat(temp);
    return () => {
      bPANFile.forEach((file) => URL.revokeObjectURL(file.preview));
      bPANOwnerFile.forEach((file) => URL.revokeObjectURL(file.preview));
      gstFile.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, []);

  const onDropBPAN = (acceptedFiles) => {
    setBPANFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    // setFormData({ ...formData, merchant_pan_image: acceptedFiles[0] })
  };

  const onDropBOwnerPAN = (acceptedFiles) => {
    setBPANOwnerFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    // setFormData({ ...formData, merchant_owner_pan_image: acceptedFiles[0] })
  };

  const onDropGSTCertificate = (acceptedFiles) => {
    setGstFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSubmitForm = async (e) => {
    if (!openCreateModal) {
      e.preventDefault();
      return;
    }
    e.preventDefault();
    const formElements = e.target.elements;
    let formData = {};
    let logoBase64Element = null;
    for (let i = 0; i < formElements.length; i++) {
      const ele = formElements[i];
      const name = ele.name;
      if (name.length > 0) {
        if (name === 'merchant_logo_Base64') {
          const file = ele.files[0];
          logoBase64Element = file;
        } else if (name === 'SNPLAllowedBySaathi') {
          formData[name] = ele.checked;
        } else {
          formData[name] = ele.value;
        }
      }
    }
    console.log('FORM DATA LINE 132', formData);
    formData['merchant_pan_image'] =
      `https://saathi-dev2.s3.ap-south-1.amazonaws.com/merchant/${formData['merchant_name']}/private/merchant_pan.jpg`;
    console.log('FORM DATA LINE 136', formData);

    formData['merchant_owner_pan_image'] =
      `https://saathi-dev2.s3.ap-south-1.amazonaws.com/merchant/${formData['merchant_name']}/private/merchant_owner_pan.jpg`;
    console.log('FORM DATA LINE 142', formData);

    formData['merchant_gst_certificate'] =
      `https://saathi-dev2.s3.ap-south-1.amazonaws.com/merchant/${formData['merchant_name']}/private/gst_certificate.jpg`;
    console.log('FORM DATA LINE 148', formData);

    formData['backgroundCircleHexColour'] = hexColor;
    formData['tenant'] = 'd2c';
    formData['merchant_is_sole_trader'] = false;
    console.log('FORM DATA LINE 151', formData);

    if (bPANFile[0]) {
      const res = await axios.post(
        'https://a2jb9iadgc.execute-api.ap-south-1.amazonaws.com/devtwo/file/s3/upload',
        {
          filePath: `merchant/${formData['merchant_name']}/private/merchant_pan.jpg`,
          fileType: `${bPANFile[0].type}`,
        },
      );
      console.log('RES FROM AXIOS POST LINE 161', res);

      const data = res.data;
      if (data.success && data.success.length > 0) {
        const url = data.success[0]?.data?.PresignedUrl;

        if (url && url.length > 0) {
          await axios.put(url, bPANFile[0]);
        }
      } else {
        return toast.error("Merchant PAN Image couldn't be uploaded");
      }
    }

    if (bPANOwnerFile[0]) {
      const res2 = await axios.post(
        'https://a2jb9iadgc.execute-api.ap-south-1.amazonaws.com/devtwo/file/s3/upload',
        {
          filePath: `merchant/${formData['merchant_name']}/private/merchant_owner_pan.jpg`,
          fileType: `${bPANOwnerFile[0].type}`,
        },
      );
      console.log('RES FROM AXIOS POST LINE 161', res2);

      const data2 = res2.data;
      if (data2.success && data2.success.length > 0) {
        const url = data2.success[0]?.data?.PresignedUrl;

        if (url && url.length > 0) {
          await axios.put(url, bPANOwnerFile[0]);
        }
      } else {
        return toast.error("Merchant Owner PAN Image couldn't be uploaded");
      }
    }

    if (gstFile[0]) {
      const res3 = await axios.post(
        'https://a2jb9iadgc.execute-api.ap-south-1.amazonaws.com/devtwo/file/s3/upload',
        {
          filePath: `merchant/${formData['merchant_name']}/private/gst_certificate.jpg`,
          fileType: `${gstFile[0].type}`,
        },
      );
      console.log('RES FROM AXIOS POST LINE 161', res3);

      const data3 = res3.data;
      if (data3.success && data3.success.length > 0) {
        const url = data3.success[0]?.data3?.PresignedUrl;

        if (url && url.length > 0) {
          await axios.put(url, gstFile[0]);
        }
      } else {
        toast.error("GST Certificate couldn't be uploaded");
      }
    }

    if (editFlag) {
      formData['ID'] = currentMerchant.id;
      dispatch(updateMerchantAPI(formData));
    } else {
      dispatch(createMerchantAPI(formData));
    }

    setOpenCreateModal(false);
  };

  const typeMenuItem = [
    {
      label: 'Affiliate',
      value: 'affiliate',
    },
    {
      label: 'Merchant',
      value: 'merchant',
    },
  ];

  const offerTypeItem = [
    {
      label: 'Amount',
      value: 'amount',
    },
    {
      label: 'Percentage',
      value: 'percentage',
    },
  ];

  return (
    <div
      id='create-merchant-modal'
      className='absolute left-1/2 top-1/2 h-[calc(100vh_-_100px)] w-[80vw] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded-[16px] bg-white shadow-modal md:w-[60vw]'
    >
      <div className='container flex flex-col items-center p-3 py-6 md:p-10'>
        <h1 className='mb-4 text-[24px]'>{editFlag ? 'Edit Merchant' : 'Create Merchant'}</h1>
        <form onSubmit={onSubmitForm} className='flex w-full flex-col items-center'>
          <div className='mt-4 grid w-full grid-cols-2 gap-2 md:w-4/5 md:gap-4'>
            {/* <div className='w-full'>
              <InputField
                req
                name='id'
                value={!editFlag ? '' : currentMerchant?.id}
                label='Merchant ID'
              />
            </div> */}
            <div className='w-full'>
              <InputField
                req
                name='merchant_name'
                value={!editFlag ? '' : currentMerchant?.merchant_name}
                label='Merchant Name'
                placeholder='Ex: Flipkart'
              />
            </div>
            <div className='w-full'>
              <InputField
                req
                name='merchant_support_email'
                value={!editFlag ? '' : currentMerchant?.merchant_support_email}
                label='Merchant Support Email'
                placeholder='Ex: abc@gmail.com'
              />
            </div>
          </div>
          <div className='mt-4 grid w-full grid-cols-1 gap-2 md:w-4/5 md:gap-4'>
            <div className='w-full md:w-full'>
              <SelectMenu
                req
                value={editFlag && currentMerchant?.merchantType}
                name='merchantType'
                label='Type'
                options={typeMenuItem}
              />
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <div className='mb-3 w-full md:w-full'>
              <InputField
                name='merchant_pan_number'
                value={!editFlag ? '' : currentMerchant?.merchant_pan_number}
                placeholder='Ex: ABCDE1234A'
                label='Merchant PAN Number'
              />
            </div>
            <label className='block text-sm font-medium text-gray-700'>
              Upload Business PAN Card
            </label>
            <DropZone onDrop={onDropBPAN} files={bPANFile} />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <div className='mb-3 w-full md:w-full'>
              <InputField
                name='merchant_owner_pan_number'
                value={!editFlag ? '' : currentMerchant?.merchant_owner_pan_number}
                placeholder='Ex: ABCDE1234A'
                label='Merchant Owner PAN Number'
              />
            </div>
            <label className='block text-sm font-medium text-gray-700'>
              Upload Business Owner PAN Card
            </label>
            <DropZone onDrop={onDropBOwnerPAN} files={bPANOwnerFile} />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <div className='grid grid-cols-2 gap-3'>
              <div className='col-span-1'>
                <div className='mb-3 w-full md:w-full'>
                  <InputField
                    name='merchant_gst_number'
                    value={!editFlag ? '' : currentMerchant?.merchant_gst_number}
                    label='Merchant GST Number'
                  />
                </div>
              </div>
              <div className='col-span-1'>
                <div className='mb-3 w-full md:w-full'>
                  <InputField
                    name='merchant_cin_number'
                    value={!editFlag ? '' : currentMerchant?.merchant_cin_number}
                    label='Merchant CIN Number'
                  />
                </div>
              </div>
            </div>
            <label className='block text-sm font-medium text-gray-700'>
              Upload GST Certificate
            </label>
            <DropZone onDrop={onDropGSTCertificate} files={gstFile} />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              name='merchant_registered_address'
              value={!editFlag ? '' : currentMerchant?.merchant_registered_address}
              label='Merchant Registered Address'
            />
          </div>
          <div className='mt-4 grid w-full grid-cols-1 gap-4 md:w-4/5'>
            {/* <div className='w-full md:w-full'>
              <FileUploadInput
                req
                inputName='merchant_logo_Base64'
                label='Logo'
              />
            </div> */}
            <div className='w-full md:w-full'>
              <InputField
                req
                value={!editFlag ? '' : currentMerchant?.merchant_logo_url}
                label='Logo URL'
                placeholder='https://....'
                name='merchant_logo_url'
              />
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              value={!editFlag ? '' : currentMerchant?.merchantBackgroundImageURL}
              label='Background Image'
              placeholder='https://....'
              name='merchantBackgroundImageURL'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <label className='mb-1 block text-sm font-medium text-gray-900'>
              Merchant Description
              <span className='text-red-700'>*</span>
            </label>
            <textarea
              placeholder='Add Something nice...'
              name='merchantDescription'
              id='merchantDesc'
              cols='auto'
              rows='10'
              className='w-full rounded-md border border-inputBorder bg-white p-3 text-sm shadow-sidebarActive outline-none'
            >
              {!editFlag ? '' : currentMerchant?.merchantDescription}
            </textarea>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <MerchantCategoryDropdown
              req
              value={editFlag && currentMerchant?.merchantCategory}
              label='Merchant Category'
              options={merchantCat}
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              value={!editFlag ? '' : currentMerchant?.merchantWebsiteURL}
              name='merchantWebsiteURL'
              label='Merchant Website'
              placeholder='https://....'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              value={!editFlag ? '' : currentMerchant?.merchant_checkout_url}
              name='merchant_checkout_url'
              label='Checkout URL'
              placeholder='https://....'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              value={!editFlag ? '' : currentMerchant?.merchant_add_id_to_checkout_url}
              name='merchant_add_id_to_checkout_url'
              label='Add ID to Checkout URL'
              placeholder='https://....'
            />
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              value={!editFlag ? '' : currentMerchant?.afiliateUrl}
              name='afiliateUrl'
              label='Afiliate URL'
              placeholder='https://....'
            />
          </div>

          <div className='mt-4 grid w-full grid-cols-2 gap-2 md:w-4/5 md:gap-4'>
            <div className='w-full md:w-full'>
              <SelectMenu
                req
                value={editFlag && currentMerchant?.merchantOfferType}
                name='merchantOfferType'
                label='Offer Type'
                options={offerTypeItem}
              />
            </div>
            <div className='w-full md:w-full'>
              <InputField
                req
                value={!editFlag ? '' : currentMerchant?.merchantOfferAmount}
                type='number'
                name='merchantOfferAmount'
                label='Offer Amount'
              />
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              name='merchantOfferTitle'
              value={!editFlag ? '' : currentMerchant?.merchant_name + ' Offer'}
              label='Merchant Offer Title'
              placeholder='Offer Title'
            />
          </div>

          {/* <div className='grid grid-cols-1 mt-4 w-full md:w-4/5 gap-4'>
            <div className='w-full'>
              <InputField
                name='shopifyStoreID'
                value={!editFlag ? '' : currentMerchant?.shopifyStoreID}
                label='Shopify Store ID'
              />
            </div>
          </div> */}

          <div className='mt-4 w-full md:w-4/5'>
            <label htmlFor='backgroundColor' className='block text-sm font-medium text-gray-700'>
              Background Color
              <span className='text-red-700'>*</span>
            </label>
            <div className='mt-1 flex w-full justify-center md:justify-start'>
              <Compact
                style={{ width: '70%' }}
                color={hexColor}
                onChange={(color) => setHexColor(color.hex)}
              />
            </div>
          </div>

          <div className='mt-4 flex h-10 w-4/5 items-center'>
            <input
              className='h-5 w-5 rounded-md border border-inputBorder bg-white shadow-sidebarActive outline-none'
              type='checkbox'
              name='SNPLAllowedBySaathi'
              defaultChecked={editFlag && currentMerchant?.SNPLAllowedBySaathi}
              id='snblOpt'
            />
            <div className='ml-3 text-sm'>Save Now Pay later enabled</div>
          </div>

          <div className='mt-4 grid w-full grid-cols-2 gap-2 md:w-4/5 md:gap-4'>
            <div className='w-full md:w-full'>
              <button
                onClick={() => setOpenCreateModal((prev) => !prev)}
                className='flex h-10 w-full items-center justify-center rounded-lg bg-btnGray font-bold shadow-sidebarActive'
              >
                Cancel
              </button>
            </div>
            <div className='w-full md:w-full'>
              <button
                type='submit'
                className='flex h-10 w-full items-center justify-center rounded-lg bg-btnGray shadow-sidebarActive'
              >
                {editFlag ? 'Save' : 'Add'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateMerchantModal;
