import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { TailSpin } from 'react-loader-spinner';
import { utils, writeFileXLSX } from 'xlsx';
import FilterDropdown from '../../../sharedComponents/FilterDropdown/FilterDropdown';
import InfoMenu from '../../../sharedComponents/InfoMenu/InfoMenu';
import {
  listMerchantProductAPI,
  saveCurrentProduct,
  updateProduct,
} from '../../../api/productSlice';

import Pagination from '../../../sharedComponents/Pagination/Pagination';
import CreateProductModal from './CreateProductModal';

import filterLogo from '../../../assets/images/filterLogo.svg';
import downloadLogo from '../../../assets/images/downloadLogo.svg';
import createLogo from '../../../assets/images/createLogo.svg';
import upIcon from '../../../assets/images/upIcon.svg';

function Product() {
  const dispatch = useDispatch();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showInfoDropdown, setShowInfoDropdown] = useState(false);
  const [infoContent, setInfoContent] = useState('');
  const [mousePointer, setMousePointer] = useState({
    x: 0,
    y: 0,
  });
  const [editFlag, setEditFlag] = useState(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [ascSort, setAscSort] = useState([true, true, true, true, true]);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const { productListLoading, productList, productListError } = useSelector(
    (state) => state.product
  );

  const setHeadingClass = (width) => {
    return `text-sm text-darkGray font-bold h-[51px] flex items-center justify-center bg-tableHeading w-[${width}px]`;
  };

  const tableHeadingClasses =
    'text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading';

  const tableCellClasses = 'min-h-[40px] flex items-center justify-start';

  useEffect(() => {
    if (!productList || productList.length === 0) {
      fetchMerchantProductList();
    }
  }, []);

  useEffect(() => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    let oldProducts = [...productList];

    if (filterProducts.length > 0) {
      const newProducts = oldProducts.filter(handleFilter);
      if (newProducts.length <= recordsPerPage) {
        setCurrentRecords(oldProducts);
      } else {
        setCurrentRecords(
          newProducts.slice(indexOfFirstRecord, indexOfLastRecord)
        );
      }
    } else {
      setCurrentRecords([
        ...oldProducts.slice(indexOfFirstRecord, indexOfLastRecord),
      ]);
    }
  }, [
    currentPage,
    recordsPerPage,
    productListLoading,
    productList,
    filterProducts,
  ]);

  const sortProduct = () => {
    let oldSort = [true, true, true, true, true];
    try {
      if (ascSort[0]) {
        let oldProducts = [...productList];
        // console.log(oldProducts);
        oldProducts.sort(function (a, b) {
          return (
            a &&
            b &&
            a.productID &&
            b.productID &&
            a.productID.localeCompare(b.productID)
          );
        });
        dispatch(updateProduct([...oldProducts]));
        oldSort[0] = false;
        setAscSort([...oldSort]);
      } else {
        let oldProducts = [...productList];
        oldProducts.sort(function (a, b) {
          return -1 * a.productID.localeCompare(b.productID);
        });
        dispatch(updateProduct([...oldProducts]));
        oldSort[0] = true;
        setAscSort([...oldSort]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortByMerchantId = () => {
    let oldSort = [true, true, true, true, true];
    if (ascSort[1]) {
      let oldProducts = [...productList];
      oldProducts.sort(function (a, b) {
        return a.merchantID.localeCompare(b.merchantID);
      });
      dispatch(updateProduct([...oldProducts]));
      oldSort[1] = false;
      setAscSort([...oldSort]);
    } else {
      let oldProducts = [...productList];
      oldProducts.sort(function (a, b) {
        return -1 * a.merchantID.localeCompare(b.merchantID);
      });
      dispatch(updateProduct([...oldProducts]));
      oldSort[1] = true;
      setAscSort([...oldSort]);
    }
  };

  const sortByPrice = () => {
    let oldSort = [true, true, true, true, true];
    if (ascSort[2]) {
      let oldProducts = [...productList];
      oldProducts.sort(function (a, b) {
        return a.price - b.price;
      });
      dispatch(updateProduct([...oldProducts]));
      oldSort[2] = false;
      setAscSort([...oldSort]);
    } else {
      let oldProducts = [...productList];
      oldProducts.sort(function (a, b) {
        return -1 * a.price - b.price;
      });
      dispatch(updateProduct([...oldProducts]));
      oldSort[2] = true;
      setAscSort([...oldSort]);
    }
  };

  const sortByDate = (flag = false) => {
    let oldSort = [true, true, true, true, true];
    if (flag ? ascSort[3] : ascSort[4]) {
      let oldProducts = [...productList];
      if (flag) {
        oldProducts.sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
      } else {
        oldProducts.sort(function (a, b) {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
      }
      dispatch(updateProduct([...oldProducts]));
      if (flag) {
        oldSort[3] = false;
      } else {
        oldSort[4] = false;
      }
      setAscSort([...oldSort]);
    } else {
      let oldProducts = [...productList];
      if (flag) {
        oldProducts.sort(function (a, b) {
          return -1 * new Date(a.createdAt) - new Date(b.createdAt);
        });
      } else {
        oldProducts.sort(function (a, b) {
          return -1 * new Date(a.updatedAt) - new Date(b.updatedAt);
        });
      }
      dispatch(updateProduct([...oldProducts]));
      if (flag) {
        oldSort[3] = true;
      } else {
        oldSort[4] = true;
      }
      setAscSort([...oldSort]);
    }
  };

  const convertToXLSX = async () => {
    try {
      let cleanData = [];
      const header = [
        'Merchant ID',
        'Product ID',
        'Product Name',
        'Product Description',
        'Product Parameters',
        'Price',
        'Delivery Packing Change',
        'Delivery Fee',
        'Packing Fee',
        'Product Offer',
        'Product Offer Type',
        'Cashkaro Offer List',
        'Product Tags',
        'Product Link URL',
        'Product Image URL',
        'Related Articles',
        'Featured',
        'Offer Description',
        'Platform',
        'Status',
        'Tracking Link',
        'Created At',
        'Updated At',
      ];
      currentRecords.filter(handleFilter).map((product) => {
        let temp = {};

        temp[header[0]] = product.merchantID;
        temp[header[1]] = product.productID;
        temp[header[2]] = product.productName || '';
        if (
          product.productDescription &&
          product.productDescription.length > 10000
        ) {
          let trimmedDesc = product.productDescription
            .replace(/<[^>]+>/g, '')
            .replace(/(\r\n|\n|\r)/gm, '')
            .trim();
          if (trimmedDesc.length <= 25000) {
            temp[header[3]] = trimmedDesc;
          } else {
            temp[header[3]] = trimmedDesc.slice(0, 10000);
          }
        } else {
          temp[header[3]] = product.productDescription;
        }
        temp[header[4]] = product.productParameters || '{}';
        temp[header[5]] = product.price || '0';
        temp[header[6]] = product.deliveryPackingChange || '0';

        temp[header[7]] = product.deliveryFee || '0';
        temp[header[8]] = product.packingFee || '0';
        temp[header[9]] = product.productOffer || '0';
        temp[header[10]] = product.productOfferType || '';
        temp[header[11]] = product.cashkaroOfferList || '[]';
        temp[header[12]] = product.productTags || '[]';
        temp[header[13]] = product.productLinkURL || '';
        temp[header[14]] = product.productImageURL || '';
        temp[header[15]] = product.relatedArticles || '{}';
        temp[header[16]] = product.featured || false;
        temp[header[17]] = product.offerDescription || '';
        temp[header[18]] = product.platform || '';
        temp[header[19]] = product.status || '';
        temp[header[20]] = product.trackingLink || '';
        temp[header[21]] = product.createdAt
          ? new Date(product.createdAt).toLocaleString()
          : new Date().toLocaleString();
        temp[header[22]] = product.updatedAt
          ? new Date(product.updatedAt).toLocaleString()
          : new Date().toLocaleString();

        cleanData.push(temp);
      });
      const ws = utils.json_to_sheet(cleanData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'products');
      writeFileXLSX(wb, 'products_data.xlsx');
    } catch (error) {
      console.log(error);
    }
  };

  const handleMousePointer = (x, y) => {
    const oldPt = mousePointer;
    let newCoord = {
      x: parseInt(x),
      y: parseInt(y),
    };
    setMousePointer(newCoord);
  };

  const handleShowInfo = (e, content) => {
    const element = e.target;
    const rect = element.getBoundingClientRect();
    const topPosition = rect.top + window.pageYOffset;
    const leftPosition = rect.left + window.pageXOffset;
    setInfoContent(content);
    handleMousePointer(leftPosition, topPosition);
    setShowInfoDropdown(true);
  };

  const handleCloseInfo = () => {
    setMousePointer({});
    setShowInfoDropdown(false);
    setInfoContent('');
  };
  const handleCurrentProduct = (data) => {
    dispatch(saveCurrentProduct(data));
    setEditFlag(true);
    setOpenCreateModal(true);
  };

  const convertObjToString = (o) => {
    let s = '';
    for (const p in o) {
      s += `${p}: ${o[p]} \n`;
    }
    if (s.length === 0) {
      return 'No Information Available';
    }
    return s;
  };

  const convertCashkaroObjToString = (arr) => {
    let s = '';
    if (!arr || arr.length === 0) {
      return 'No Cashkaro List Available';
    }
    arr.map((ele, id) => {
      s += convertObjToString(ele);
      s += '----------------------\n';
    });

    return s;
  };

  const fetchMerchantProductList = async () => {
    console.log('CALLED');
    dispatch(listMerchantProductAPI());
  };

  const handleFilter = (product) => {
    if (filterProducts.length === 0) {
      return true;
    }
    console.log(product);
    const id = filterProducts.findIndex((e) => e === product?.productName);
    return id !== -1;
  };

  const handleOpenFilterMenu = () => {
    setShowFilterMenu((prev) => !prev);
  };

  return (
    <>
      {productListLoading ? (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <TailSpin
            height="120"
            width="120"
            color="#62A6FF"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      ) : (
        <div id="main-container" className="container max-w-full">
          <div className="w-full flex justify-between items-center min-w-max">
            <h1 className="text-[32px] font-bold m-[40px]">Products</h1>
            <div className="w-[200px] mr-10 flex justify-around">
              <div className="cursor-pointer">
                <img onClick={handleOpenFilterMenu} src={filterLogo} alt="" />
                {/* <FilterDropdown
                                        filterItems={filterProducts}
                                        setFilterItems={setFilterProducts}
                                        showFilterMenu={showFilterMenu}
                                        type="Product"
                                    /> */}
              </div>
              <div className="cursor-pointer">
                <img
                  onClick={() => convertToXLSX()}
                  src={downloadLogo}
                  alt=""
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => setOpenCreateModal((prev) => !prev)}
              >
                <img src={createLogo} alt="" />
              </div>
            </div>
          </div>

          <div id="product_table" className="flex min-w-[1200px]">
            <div className="flex flex-col">
              {/* Table Header */}
              <div className="flex">
                <div
                  className={`text-sm text-darkGray font-bold h-[51px] flex items-center bg-tableHeading justify-start w-[30vw] lg:w-[13vw] ml-10 pl-5 rounded-l-lg`}
                >
                  Merchant ID
                  <img
                    onClick={sortByMerchantId}
                    className={`ml-2 ${
                      ascSort[1] ? 'rotate-180' : ''
                    } cursor-pointer`}
                    src={upIcon}
                    alt=""
                  />
                </div>
                <div className={`${tableHeadingClasses} w-[28vw] lg:w-[10vw]`}>
                  Product ID
                  <img
                    onClick={sortProduct}
                    className={`ml-2 ${
                      ascSort[0] ? 'rotate-180' : ''
                    } cursor-pointer`}
                    src={upIcon}
                    alt=""
                  />
                </div>
              </div>
              {/* Table Rows */}
              {productList &&
                currentRecords.filter(handleFilter).map((ele, id) => {
                  const { merchantID, productID } = ele;
                  return (
                    <div className="flex">
                      <div
                        className={`justify-start items-start h-[40px] flex truncate w-[30vw] lg:w-[13vw] ml-10 pl-5`}
                      >
                        <div className="w-[95%] overflow-auto">
                          {merchantID}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          handleCurrentProduct(ele);
                        }}
                        className={`${tableCellClasses} text-blue-600 underline truncate w-[28vw] lg:w-[10vw] cursor-pointer border-r border-gray-200`}
                      >
                        <div className="w-[95%] overflow-auto">{productID}</div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* Horizontal Scrolling Section of Table */}
            <div className="flex flex-col w-[800px] overflow-x-auto">
              <div className="flex w-fit">
                <div
                  className={`text-sm text-darkGray font-bold h-[51px] flex items-center bg-tableHeading justify-start w-[32vw] lg:w-[13vw] pl-2 `}
                >
                  Product Name
                </div>
                {/* <div className={`${tableHeadingClasses} w-[75vw] lg:w-[55vw]`}>
                  Product Description
                </div> */}
                <div className={`${tableHeadingClasses} w-[75vw] lg:w-[25vw]`}>
                  Product Description
                </div>

                <div className={`${tableHeadingClasses} w-[35vw] lg:w-[15vw]`}>
                  Product Parameters
                </div>
                <div
                  className={`${tableHeadingClasses} justify-center md:justify-start w-[200px]`}
                >
                  Price
                  <img
                    onClick={sortByPrice}
                    className={`ml-2 ${
                      ascSort[2] ? 'rotate-180' : ''
                    } cursor-pointer`}
                    src={upIcon}
                    alt=""
                  />
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Delivery Packing Change
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Delivery Fee
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Packing Fee
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Product Offer
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Product Offer Type
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Cashkaro Offer List
                </div>
                <div className={`${tableHeadingClasses} w-[250px]`}>
                  Product Tags
                </div>
                <div className={`${tableHeadingClasses} w-[250px]`}>
                  Product Link URL
                </div>
                <div className={`${tableHeadingClasses} w-[250px]`}>
                  Product Image URL
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Related Articles
                </div>
                <div className={`${tableHeadingClasses} w-[100px]`}>
                  Featured
                </div>
                <div className={`${tableHeadingClasses} w-[300px]`}>
                  Offer Description
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>Tenant</div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Platform
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Is Expired
                </div>
                <div className={`${tableHeadingClasses} w-[100px]`}>Status</div>
                <div className={`${tableHeadingClasses} w-[300px]`}>
                  Tracking Link
                </div>
                <div className={`${tableHeadingClasses} w-[300px]`}>
                  Merchant Product Type
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Created At
                  <img
                    onClick={() => sortByDate(true)}
                    className={`ml-2 ${
                      ascSort[3] ? 'rotate-180' : ''
                    } cursor-pointer`}
                    src={upIcon}
                    alt=""
                  />
                </div>
                <div className={`${tableHeadingClasses} w-[200px]`}>
                  Updated At
                  <img
                    onClick={() => sortByDate()}
                    className={`ml-2 ${
                      ascSort[4] ? 'rotate-180' : ''
                    } cursor-pointer`}
                    src={upIcon}
                    alt=""
                  />
                </div>
              </div>

              {productList &&
                currentRecords.filter(handleFilter).map((ele, id) => {
                  try {
                    const {
                      merchantID,
                      productID,
                      deliveryFee,
                      deliveryPackingChange,
                      featured,
                      ID,
                      isExpired,
                      merchantName,
                      merchantProductType,
                      offerDescription,
                      packingFee,
                      platform,
                      price,
                      productDescription,
                      productImageURL,
                      productLinkURL,
                      productName,
                      productOffer,
                      productOfferType,
                      productParameters,
                      productTags,
                      relatedArticles,
                      rewardType,
                      status,
                      tenant,
                      trackingLink,
                      cashkaroOfferList,
                      createdAt,
                      updatedAt,
                    } = ele;

                    const parser = new DOMParser();
                    const desc = parser.parseFromString(
                      productDescription,
                      'text/html'
                    );
                    let params = null;
                    let cashkaroObj = [];
                    let articles = {};
                    let tags = '';
                    if (
                      productTags.length > 1 &&
                      typeof productTags === 'string'
                    ) {
                      tags = productTags.substring(1, productTags.length - 1);
                    } else {
                      if (typeof productTags === 'object') {
                        tags = JSON.stringify(productTags);
                      } else {
                        tags = '';
                      }
                    }
                    if (typeof relatedArticles === 'object') {
                      articles = relatedArticles;
                    } else {
                      articles = JSON.parse(relatedArticles);
                    }
                    if (typeof cashkaroOfferList === 'object') {
                      cashkaroObj = cashkaroOfferList;
                    } else {
                      cashkaroObj = JSON.parse(cashkaroOfferList);
                    }
                    if (typeof productParameters === 'object') {
                      params = productParameters;
                    } else {
                      params = JSON.parse(productParameters);
                    }

                    return (
                      <>
                        <div className="flex w-fit">
                          <div
                            className={`h-[40px] flex items-center justify-start w-[32vw] lg:w-[13vw] pl-2`}
                          >
                            <div
                              style={{ width: '95%' }}
                              className="truncate overflow-x-auto "
                            >
                              {productName}
                            </div>
                          </div>

                          <div
                            onClick={(e) =>
                              handleShowInfo(
                                e,
                                desc.documentElement.textContent
                              )
                            }
                            className={`${tableCellClasses} truncate w-[75vw] lg:w-[25vw] underline cursor-pointer`}
                          >
                            <p
                              style={{ width: '90%' }}
                              className="m-auto truncate"
                            >
                              {desc.documentElement.textContent}
                            </p>
                          </div>
                          <div
                            onClick={(e) =>
                              handleShowInfo(e, convertObjToString(params))
                            }
                            className={`${tableCellClasses} truncate w-[35vw] lg:w-[15vw] underline cursor-pointer`}
                          >
                            Click to View Params
                          </div>
                          <div
                            className={`${tableCellClasses} justify-center md:justify-start truncate w-[200px]`}
                          >
                            {price !== null ? price : '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {deliveryPackingChange !== null
                              ? deliveryPackingChange
                              : '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {deliveryFee !== null ? deliveryFee : '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {packingFee !== null ? packingFee : '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {productOffer !== null ? productOffer : '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {productOfferType || '-'}
                          </div>
                          <div
                            onClick={(e) => {
                              handleShowInfo(
                                e,
                                convertCashkaroObjToString(cashkaroObj)
                              );
                            }}
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            <div
                              style={{ width: '90%' }}
                              className="mx-2 truncate underline cursor-pointer"
                            >
                              Click to View Cashkaro List
                            </div>
                          </div>
                          <div
                            onClick={(e) => {
                              handleShowInfo(e, tags);
                            }}
                            className={`${tableCellClasses} truncate w-[250px]`}
                          >
                            <div
                              style={{ width: '90%' }}
                              className="mx-2 truncate underline cursor-pointer"
                            >
                              {tags}
                            </div>
                          </div>
                          <div className={`${tableCellClasses} w-[250px]`}>
                            <a
                              href={productLinkURL}
                              target="_blank"
                              style={{ width: '90%' }}
                              className="truncate"
                            >
                              {productLinkURL || '-'}
                            </a>
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[250px]`}
                          >
                            <a
                              href={productImageURL}
                              target="_blank"
                              style={{ width: '90%' }}
                              className="truncate"
                            >
                              {productImageURL || '-'}
                            </a>
                          </div>
                          <div
                            onClick={(e) => {
                              handleShowInfo(e, convertObjToString(articles));
                            }}
                            className={`${tableCellClasses} truncate w-[200px] underline cursor-pointer`}
                          >
                            Click to View Articles List
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[100px] ml-3`}
                          >
                            {featured ? 'True' : 'False'}
                          </div>
                          <div
                            onClick={(e) => handleShowInfo(e, offerDescription)}
                            className={`${tableCellClasses} truncate w-[300px] underline cursor-pointer`}
                          >
                            <p
                              style={{ width: '90%' }}
                              className="mx-2 truncate"
                            >
                              {offerDescription || 'None'}
                            </p>
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {tenant || '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {platform || '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {isExpired || '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[100px]`}
                          >
                            {status || '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[300px]`}
                          >
                            <a
                              href={trackingLink}
                              target="_blank"
                              style={{ width: '90%' }}
                              className="truncate"
                            >
                              {trackingLink || '-'}
                            </a>
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[300px]`}
                          >
                            {merchantProductType || '-'}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {new Date(createdAt).toLocaleDateString()}
                          </div>
                          <div
                            className={`${tableCellClasses} truncate w-[200px]`}
                          >
                            {updatedAt
                              ? new Date(updatedAt).toLocaleDateString()
                              : new Date().toLocaleDateString()}
                          </div>
                          {/* <div className={`${tableCellClasses} truncate w-[300px]`}>
                                                        {merchantProductBackgroundURL || '-'}
                                                    </div> */}
                        </div>
                      </>
                    );
                  } catch (error) {
                    console.log(error.message);
                    return <></>;
                  }
                })}
            </div>
          </div>

          {/* Create Product Modal */}
          <Transition appear show={openCreateModal} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => setOpenCreateModal(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full justify-center p-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="transition-all">
                      <CreateProductModal
                        openCreateModal={openCreateModal}
                        editFlag={editFlag}
                        setEditFlag={setEditFlag}
                        setOpenCreateModal={setOpenCreateModal}
                      />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          <Pagination
            type="Products"
            size={productList.filter(handleFilter).length || 0}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setRecordsPerPage={setRecordsPerPage}
            recordsPerPage={recordsPerPage}
          />

          {showInfoDropdown && (
            <InfoMenu
              handleClose={handleCloseInfo}
              left={mousePointer['x']}
              top={mousePointer['y']}
              content={infoContent}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Product;
