// import upIcon from '../../../assets/images/upIcon.svg';
import { useEffect, useState } from 'react';
import upIcon from '../../../../assets/images/upIcon.svg';
import {
  deleteIdeaFromDB,
  listAllIdeas,
  sortIdeasAlphabetically,
} from '../utils';
import filterLogo from '../../../../assets/images/filterLogo.svg';
import downloadLogo from '../../../../assets/images/downloadLogo.svg';
import createLogo from '../../../../assets/images/createLogo.svg';
import { classify } from '../../../../common/Utility';
import { String } from 'aws-sdk/clients/appstream';
import Pagination from '../../../../sharedComponents/Pagination/Pagination';
import PaginationNew from '../../../../sharedComponents/Pagination/PaginationNew';
import AddIdeasModal from './AddIdeasModal/AddIdeasModal';

const BucketListIdeas = ({
  allIdeas,
  allCategories,
  setAllIdeas,
}: {
  allIdeas: Idea[];
  allCategories: IdeaCategory[];
  setAllIdeas: (newIdeas: Idea[]) => void;
}) => {
  const [pageIdeas, setPageIdeas] = useState<Idea[]>([]);
  const [showAddIdeasModal, setShowAddIdeasModal] = useState(false);

  async function processDeleteIdea(ideaId: string) {
    deleteIdeaFromDB(ideaId).then((res) => {
      setAllIdeas(
        sortIdeasAlphabetically([
          ...allIdeas.filter((existingIdea) => existingIdea.idea_id !== ideaId),
        ])
      );
    });
  }

  const tableHeadingClasses =
    'text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading';
  const tableCellClasses =
    'min-h-[40px] flex items-center justify-start [&_*]:truncate';

  return (
    <div>
      <div className="w-full flex justify-between items-center min-w-max">
        <h1 className="text-[32px] font-bold m-[40px]">Ideas</h1>
        <div className="w-[200px] mr-10 flex justify-around">
          {/* <div className="cursor-pointer">
            <img onClick={() => {}} src={filterLogo} alt="" />
          </div>
          <div className="cursor-pointer">
            <img onClick={() => {}} src={downloadLogo} alt="" />
          </div> */}
          <div
            className="cursor-pointer"
            onClick={() => {
              setShowAddIdeasModal(true);
            }}
          >
            <img src={createLogo} alt="" />
          </div>
        </div>
      </div>

      <div className="container max-w-full">
        <div className="flex min-w-[1200px]">
          {/* FIXED LHS SECTION OF TABLE */}
          <div className="flex flex-col">
            <div className="flex">
              <div
                className={`text-sm text-darkGray font-bold h-[51px] flex items-center bg-tableHeading justify-start w-[30vw] lg:w-[13vw] ml-10 pl-5 rounded-l-lg`}
              >
                Name
                <img
                  onClick={() => {}}
                  // className={`ml-2 ${
                  //   ascSort[1] ? 'rotate-180' : ''
                  // } cursor-pointer`}
                  // src={upIcon}
                  alt=""
                />
              </div>
            </div>
            {/* Table Rows */}
            {pageIdeas.map((idea, i) => {
              return (
                <div className="flex" key={i}>
                  <div
                    className={`justify-start items-start h-[40px] flex truncate w-[30vw] lg:w-[13vw] ml-10 pl-5`}
                  >
                    <div className="w-[95%] overflow-auto">
                      {idea.idea_title}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* HORIZONTAL SCROLLING SECTION OF TABLE: */}
          <div className="flex flex-col w-[800px] overflow-x-auto">
            <div className="flex w-fit">
              <div
                className={`${tableHeadingClasses} justify-center md:justify-start w-[200px]`}
              >
                Categories
              </div>
              <div className={`${tableHeadingClasses} w-[75vw] lg:w-[25vw]`}>
                Description
              </div>
              <div className={`${tableHeadingClasses} w-[75vw] lg:w-[25vw]`}>
                Image URL
              </div>
              <div className={`${tableHeadingClasses} w-[75vw] lg:w-[25vw]`}>
                Voucher Code
              </div>
              <div className={`${tableHeadingClasses} w-[35vw] lg:w-[15vw]`}>
                Merchant ID
              </div>
              <div
                className={`${tableHeadingClasses} justify-center md:justify-start w-[200px]`}
              >
                Offer ID
              </div>

              {/* 'DELETE' button column: */}
              <div
                className={classify([tableHeadingClasses, 'w-[200px]'])}
              ></div>
            </div>

            {/* ACTUAL DATA LINES: */}
            {pageIdeas.map((idea, i) => {
              return (
                <div className="flex w-fit" key={i}>
                  <div className={classify([tableCellClasses, 'w-[200px]'])}>
                    <span>
                      {(idea.categories ?? [])
                        .map((cat) => cat.category_name)
                        .join(', ')}
                    </span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[75vw] lg:w-[25vw]',
                    ])}
                  >
                    <span>{idea.idea_content}</span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[75vw] lg:w-[25vw]',
                    ])}
                  >
                    <span>{idea.idea_image_url}</span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      ' w-[75vw] lg:w-[25vw]',
                    ])}
                  >
                    <span>{idea.voucher_code}</span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[35vw] lg:w-[15vw]',
                    ])}
                  >
                    <span>{idea.merchant_id}</span>
                  </div>
                  <div className={classify([tableCellClasses, 'w-[200px]'])}>
                    <span>{idea.offer_id}</span>
                  </div>

                  {/* 'DELETE' button: */}
                  <div className={classify([tableCellClasses, 'w-[200px]'])}>
                    <span
                      className={classify([
                        'text-red-500 border border-red-500 p-1 text-sm cursor-pointer hover:bg-red-500 hover:text-white',
                      ])}
                      onClick={() => {
                        processDeleteIdea(idea.idea_id);
                      }}
                    >
                      DELETE
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* PAGINATION AT BOTTOM: */}
      <PaginationNew
        type="Ideas"
        allFilteredData={allIdeas}
        setPageData={setPageIdeas}
      />

      {/* 'ADD IDEAS' MODAL: */}
      <AddIdeasModal
        show={showAddIdeasModal}
        allExistingIdeas={allIdeas}
        allExistingCategories={allCategories}
        setAllIdeas={setAllIdeas}
        closeModal={() => setShowAddIdeasModal(false)}
      />
    </div>
  );
};

export default BucketListIdeas;
