import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast';
import pauseBlueIcon from '../../../assets/images/pauseBlue.svg'
import cancelIcon from '../../../assets/images/cancel.svg'
import createLogo from '../../../assets/images/createLogo.svg';
import editIcon from '../../../assets/images/editIcon.svg';

function GoalsTable({ openModal, displayContent }) {
    const { usersList } = useSelector(state => state.goals);

    function formatIndianCurrency(number) {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: number % 1 === 0 ? 0 : 2,
            maximumFractionDigits: number % 1 === 0 ? 0 : 2
        }).format(number);
    }

    // const callback = (data) => {
    //     if (data.error && Object.keys(data.error).length > 0) {
    //         try {
    //             toast.error(`${data.error.error.response.data.error.message}`);
    //         } catch (error) {
    //             toast.error(`Error while Editing Offer`);
    //         }
    //         return;
    //     }
    //     if (data.success.code) {
    //         toast.success(`${data.success.message}`);
    //     }
    // }

    const itemsSvg = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_741_8393)">
                <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" fill="#888888" />
            </g>
            <defs>
                <clipPath id="clip0_741_8393">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );

    return (
        <div className='w-full overflow-auto max-h-[500px]'>
            <table className='table-auto w-full border-separate gap-10'>
                <thead>
                    <tr>
                        <th className='py-3 bg-white sticky z-40 top-0 border border-tableDivider min-w-[200px] max-w-[250px]'>Goal ID</th>
                        <th className='py-3 bg-white sticky z-40 top-0 border border-tableDivider min-w-[200px] max-w-[250px]'>User First Name</th>
                        <th className='py-3 bg-white sticky z-40 top-0 border border-tableDivider min-w-[200px] max-w-[250px]'>User Last Name</th>
                        <th className='py-3 bg-white sticky z-40 top-0 border border-tableDivider min-w-[200px] max-w-[250px]'>User Email</th>
                        <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>Goal Name</th>
                        {/* <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>Goal Image</th> */}
                        <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>Goal Amount</th>
                        <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>Amount Saved</th>
                        <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>No. of Members</th>
                        <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>Duration</th>
                        <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>Goal Start Date</th>
                        <th className='py-3 bg-white sticky z-40 border border-tableDivider top-0 min-w-[200px]'>Goal End Date</th>
                        <th className='py-3 bg-white sticky z-40  top-0 min-w-[200px] border border-tableDivider'>Goal Status</th>
                        <th className='py-3 bg-white sticky z-40  top-0 min-w-[200px] border border-tableDivider'>Order Amount</th>
                        <th className='py-3 bg-white sticky z-40  top-0 min-w-[250px] border border-tableDivider'>Conversion Status</th>
                    </tr>
                </thead>
                <tbody className='h-full overflow-y-auto'>
                    {displayContent?.map((ele, id) => {
                        const status = ele?.goalStatus;
                        const conversions = ele?.conversions;
                        const user = usersList.find((u) => u.id === ele.userId);
                        let borderColor = "border-successGreen"
                        let textColor = "text-successGreen"
                        let conversionText = "UNUSED";
                        let conversionBorderColor = "border-black"
                        let conversionTextColor = "text-black"
                        let orderAmount = "-";

                        if (conversions && Object.keys(conversions).length > 0) {
                            orderAmount = conversions.orderAmount;
                            switch (conversions?.status) {
                                case "confirmed":
                                    conversionBorderColor = "border-successGreen"
                                    conversionTextColor = "text-successGreen"
                                    conversionText = "CONFIRMED"
                                    break;
                                case "payment_cleared":
                                    conversionBorderColor = "border-successGreen"
                                    conversionTextColor = "text-successGreen"
                                    conversionText = "PAYMENT CLEARED"
                                    break;
                                case "hold":
                                    conversionBorderColor = "border-blue-600"
                                    conversionTextColor = "text-blue-600"
                                    conversionText = "HOLD"
                                    break;
                                case "purchased":
                                    conversionBorderColor = "border-blue-600"
                                    conversionTextColor = "text-blue-600"
                                    conversionText = "PURCHASED"
                                    break;
                                case "declined":
                                    conversionBorderColor = "border-red-600"
                                    conversionTextColor = "text-red-600"
                                    conversionText = "DECLINED"
                                    break;
                                case "cancelled":
                                    conversionBorderColor = "border-red-600"
                                    conversionTextColor = "text-red-600"
                                    conversionText = "CANCELLED"
                                    break;
                                case "refund":
                                    conversionBorderColor = "border-successGreen"
                                    conversionTextColor = "text-successGreen"
                                    conversionText = "REFUND"
                                    break;
                                case "pending":
                                    conversionBorderColor = "border-orange-400"
                                    conversionTextColor = "text-orange-400"
                                    conversionText = "PENDING"
                                    break;
                                default:
                                    conversionBorderColor = "border-black"
                                    conversionTextColor = "text-black"
                                    conversionText = "UNUSED"
                                    break;
                            }
                        }
                        switch (status) {
                            case "COMPLETED":
                                borderColor = "border-successGreen"
                                textColor = "text-successGreen"
                                break;
                            case "ACTIVE":
                                borderColor = "border-successGreen"
                                textColor = "text-successGreen"
                                break;
                            case "CANCELLED":
                                borderColor = "border-red-600"
                                textColor = "text-red-600"
                                break;
                            case "INITIALIZED":
                                borderColor = "border-orange-400"
                                textColor = "text-orange-400"
                                break;
                            default:
                                borderColor = "border-blue-600"
                                textColor = "text-blue-600"
                                break;
                        }
                        
                        return (
                            <tr>
                                <td className='w-[200px] border border-tableDivider text-left py-6 px-2'>
                                    <div className="overflow-x-auto">{ele?.id}</div>
                                </td>
                                <td className='w-[200px] border border-tableDivider text-center py-6 px-2'>
                                    {user?.UsrGivenName}
                                </td>
                                <td className='w-[200px] border border-tableDivider text-center py-6 px-2'>
                                    {user?.UsrFamilyName}
                                </td>
                                <td className='w-[200px] border border-tableDivider text-left py-6 px-2'>
                                    <div className="mx-auto w-[90%] text-blue-600 overflow-x-auto">{user?.UsrEmail}</div>
                                </td>
                                <td className='text-center border border-tableDivider min-w-[200px] py-6'>{ele?.name}</td>
                                {/* <td className='text-center border border-tableDivider w-[200px] py-6'>
                                    <img className='h-28 mx-auto' src={ele?.image || placeholder} onError={(e) => e.target.src = placeholder} alt="goal-image" />
                                </td> */}
                                <td className='text-center border border-tableDivider min-w-[200px] py-6'>{formatIndianCurrency(ele?.netGoalAmount || 0)}</td>
                                <td className='text-center border border-tableDivider min-w-[200px] py-6'>{formatIndianCurrency(ele?.savedAmount || 0)}</td>
                                <td className='text-center border border-tableDivider min-w-[200px] py-6'>{ele?.noOfMembers || 1}</td>
                                <td className='text-center border border-tableDivider min-w-[200px] py-6'>{ele?.length} Months</td>
                                <td className='text-center border border-tableDivider min-w-[200px] py-6'>{new Date(ele?.createdAt).toLocaleDateString()}</td>
                                <td className='text-center border border-tableDivider min-w-[200px] py-6'>{new Date(ele?.goalEndDate).toLocaleDateString()}</td>
                                <td className='text-center border border-l border-tableDivider h-full min-w-[200px] table-cell gap-4 py-6'>
                                    <button className={`h-7 min-w-[64px] px-2 m-auto rounded-xl flex items-center border ${borderColor} ${textColor} bg-white`}>{status}</button>
                                </td>
                                <td className='text-center border border-l border-tableDivider h-full min-w-[200px] table-cell gap-4 py-6'>
                                    {formatIndianCurrency(Number(orderAmount === '-' ? 0 : Number(orderAmount)))}
                                </td>
                                <td className='text-center border border-l border-tableDivider h-full min-w-[250px] table-cell gap-4 py-6'>
                                    <div className='w-full h-full flex justify-evenly items-center'>
                                        <button className={`h-7 min-w-[64px] px-2 font-bold rounded-xl flex items-center border ${conversionBorderColor} ${conversionTextColor} bg-white`}>
                                            {conversionText}
                                        </button>
                                        {conversionText === 'UNUSED' ? <img onClick={() => openModal(false, ele)} src={createLogo} alt="create-conversion" /> : <img src={editIcon} onClick={() => openModal(true, ele, orderAmount)} alt='edit-conversion' />}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default GoalsTable