import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import { TailSpin } from 'react-loader-spinner'
HighchartsExporting(Highcharts);

function Chart({ active = "Users", datapoints = [], activeTPeriod = null}) {
    const [options, setOptions] = useState({});
    const { analyticsLoading, analyticsError } = useSelector(state => state.analytics);

    useEffect(() => {
        let xAxisLabels = [];
        let yAxisPoints = [];
        const today = new Date();
        const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const oneMonthAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
        datapoints.forEach((ele) => {
            try {
                const { countUsr, countGoal, activeMerchantProd, goalSavings, createdAt } = ele;
                if(!activeTPeriod) {
                    xAxisLabels.push(`${new Date(createdAt).getDate()}/${new Date(createdAt).getMonth() + 1}/${new Date(createdAt).getFullYear().toString().slice(2)}`);
                    switch (active) {
                        case 'Users':
                            yAxisPoints.push(countUsr);
                            break;
                        case "Goals Created":
                            yAxisPoints.push(countGoal);
                            break;
                        case "Active Merchant Products":
                            yAxisPoints.push(activeMerchantProd);
                            break;
                        case "Goal Savings":
                            yAxisPoints.push(goalSavings);
                            break;
                        default:
                            break;
                    }
                } else if (activeTPeriod === '7d') {
                    if(new Date(createdAt) >= sevenDaysAgo) {
                        xAxisLabels.push(`${new Date(createdAt).getDate()}/${new Date(createdAt).getMonth() + 1}/${new Date(createdAt).getFullYear().toString().slice(2)}`);
                        switch (active) {
                            case 'Users':
                                yAxisPoints.push(countUsr);
                                break;
                            case "Goals Created":
                                yAxisPoints.push(countGoal);
                                break;
                            case "Active Merchant Products":
                                yAxisPoints.push(activeMerchantProd);
                                break;
                            case "Goal Savings":
                                yAxisPoints.push(goalSavings);
                                break;
                            default:
                                break;
                        }
                    }
                } else if(activeTPeriod === '30d') {
                    if(new Date(createdAt) >= oneMonthAgo) {
                        xAxisLabels.push(`${new Date(createdAt).getDate()}/${new Date(createdAt).getMonth() + 1}/${new Date(createdAt).getFullYear().toString().slice(2)}`);
                        switch (active) {
                            case 'Users':
                                yAxisPoints.push(countUsr);
                                break;
                            case "Goals Created":
                                yAxisPoints.push(countGoal);
                                break;
                            case "Active Merchant Products":
                                yAxisPoints.push(activeMerchantProd);
                                break;
                            case "Goal Savings":
                                yAxisPoints.push(goalSavings);
                                break;
                            default:
                                break;
                        }
                    }
                } else {
                    if(new Date(createdAt) >= sixMonthsAgo) {
                        xAxisLabels.push(`${new Date(createdAt).getDate()}/${new Date(createdAt).getMonth() + 1}/${new Date(createdAt).getFullYear().toString().slice(2)}`);
                        switch (active) {
                            case 'Users':
                                yAxisPoints.push(countUsr);
                                break;
                            case "Goals Created":
                                yAxisPoints.push(countGoal);
                                break;
                            case "Active Merchant Products":
                                yAxisPoints.push(activeMerchantProd);
                                break;
                            case "Goal Savings":
                                yAxisPoints.push(goalSavings);
                                break;
                            default:
                                break;
                        }
                    }
                }
                
            } catch (error) {
                
            }
        });
        const obj = {
            chart: {
                height: 360,
                zoomType: 'x',
            },
            credits: {
                enabled: false
            },
            title: {
                text: `${active} Variation`,
            },
            xAxis: {
                categories: xAxisLabels
            },
            yAxis: {
                title: {
                    text: 'Count'
                }
            },
            tooltip: {
                crosshairs: true,
                shared: true
            },
            plotOptions: {
                series: {
                    marker: {
                        radius: 4,
                        lineColor: '#666666',
                        lineWidth: 1
                    }
                }
            },
            series: [{
                name: `${active}`,
                data: yAxisPoints
            }]
        }

        setOptions({...obj});
    }, [datapoints, active, activeTPeriod])
    

  return (
      <>
        {analyticsLoading ? 
        <>
            <TailSpin
                height="60"
                width="60"
                color="#62A6FF"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
            />
        </> : (
              <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </>
  )
}

export default Chart