import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import InputField from '../../../sharedComponents/InputField/InputField'
import ProductParameterInput from './ProductParameterInput';
import SelectMenu from '../../../sharedComponents/SelectMenu/SelectMenu';

import { listMerchantData } from '../../../api/merchantSlice';
import { createProductAPI, updateProductAPI, setProductError } from '../../../api/productSlice';

function CreateProductModal({ setOpenCreateModal, editFlag, setEditFlag, openCreateModal }) {

    const [merchantsID, setMerchantsID] = useState([]);
    const [merchantName, setMerchantName] = useState([]);
    const [tags, setTags] = useState(["", "", ""]);
    const [articles, setArticles] = useState({});
    const [params, setParams] = useState({});
    const dispatch = useDispatch();
    const { currentProduct, productListError, productListLoading } = useSelector(state => state.product);
    const { merchantList } = useSelector(state => state.merchant);

    useEffect(() => {
        if (merchantList && merchantList.length > 0) {
            let opts = [];
            let reverseOpts = [];
            merchantList.map((ele, id) => {
                opts.push({
                    label: ele.merchant_name,
                    value: ele.id
                })
                reverseOpts.push({
                    label: ele.merchant_name,
                    value: ele.merchant_name
                })
            })
            setMerchantsID([...opts]);
            setMerchantName([...reverseOpts]);
        } else {
            dispatch(listMerchantData());
        }
    }, [])

    useEffect(() => {
        if (currentProduct) {
            let t = currentProduct.productTags;
            let r = currentProduct.relatedArticles;
            let p = currentProduct.productParameters;
            // console.log(currentProduct);
            if (t && typeof t === 'string') {
                try {
                    let newT = JSON.parse(t);
                    setTags(newT);
                } catch (error) {

                }
            } else if (t && typeof t === 'object') {
                setTags(t);
            }
            if (r) {
                if (typeof r === 'object') {
                    setArticles(r);
                } else if (typeof r === 'string') {
                    try {
                        setArticles(JSON.parse(r));
                    } catch (error) {
                        setArticles({});
                    }
                } else {
                    setArticles({});
                }
            }

            if (p) {
                if (typeof p === 'object') {
                    setParams(p);
                } else if (typeof p === 'string') {
                    try {
                        let newP = JSON.parse(p);
                        setParams({ ...newP });
                    } catch (error) {
                        setParams({});
                    }
                }
            }
        }
    }, [currentProduct])
    

    const callback = (data, flag = false) => {
        setEditFlag(false);
        if (data.error && Object.keys(data.error).length > 0) {
            try {
                toast.error(`${data.error.error.response.data.error.message}`);
            } catch (error) {
                toast.error(`Error while ${flag ? "Editing" : "Creating"} Product`);
            }
            return;
        }
        if (data.success.code) {
            toast.success(`${data.success.message}`);
        }
    }

    const findMerchant = (id) => {
        let m = "";
        let flag = false;
        merchantList.map((ele) => {
            if (flag) {
                return;
            }
            if (ele.id === id) {
                m = ele.merchant_name;
                flag = true;
                return;
            }
        })

        return m;
    }

    const onSubmitForm = (e) => {
        if (!openCreateModal) {
            e.preventDefault();
            return;
        }
        e.preventDefault();
        try {
            const formElements = e.target.elements;
            let formData = {};
            let productParams = {};
            let relatedArticles = {};
            let tags = [];
            for (let i = 0; i < formElements.length; i++) {
                const ele = formElements[i];
                const name = ele.name;
                if (name.length > 0) {
                    if (name.includes("productTags")) {
                        tags.push(ele.value);
                        continue;
                    }
                    if (name === 'productID' && editFlag) {
                        formData["oldProductID"] = ele.value;
                        continue;
                    }
                    if (name.includes("productParameters")) {
                        const temp = name.split("productParameters_");
                        const r = temp[1].split("_")[0];
                        if (!productParams[r]) {
                            productParams[r] = [];
                        }
                        productParams[r].push(ele.value);
                        continue;
                    }
                    if (name.includes("relatedArticles")) {
                        const temp = name.split("relatedArticles_");
                        const r = temp[1].split("_")[0];
                        if (!relatedArticles[r]) {
                            relatedArticles[r] = [];
                        }
                        relatedArticles[r].push(ele.value);
                        continue;
                    }
                    if (name === 'isExpired' || name === 'featured') {
                        formData[name] = ele.checked;
                        continue;
                    }
                    if (name === 'packingFee' || name === 'productOffer' || name === 'deliveryPackingChange' || name === 'deliveryFee' || name === 'price' || name === 'packingFee') {
                        formData[name] = parseFloat(ele.value);
                        continue;
                    }
                    if (name === 'hasVoucher') {
                        formData[name] = Number(ele.checked);
                        continue;
                    }

                    formData[name] = ele.value;
                }
            }
            formData["productTags"] = tags;
            let productParamsTemp = {};
            let relatedArticlesTemp = {}
            for (const key in productParams) {
                if (Object.hasOwnProperty.call(productParams, key)) {
                    const element = productParams[key];
                    if (element[0].length > 0) {
                        productParamsTemp[element[0]] = element[1] || '';
                    }
                }
            }
            for (const key in relatedArticles) {
                if (Object.hasOwnProperty.call(relatedArticles, key)) {
                    const element = relatedArticles[key]
                    if (element[0].length > 0) {
                        relatedArticlesTemp[element[0]] = element[1] || '';
                    }
                }
            }
            formData["tenant"] = "d2c";
            formData["productParameters"] = productParamsTemp;
            formData["relatedArticles"] = relatedArticlesTemp;
            formData["merchantProductBackgroundURL"] = "none";
            if (editFlag) {
                formData["merchantName"] = findMerchant(formData["merchantID"])
                dispatch(updateProductAPI({ body: formData, callback }));
            } else {
                dispatch(createProductAPI({ body: formData, callback }))
            }
            setOpenCreateModal(false);
        } catch (error) {
            dispatch(setProductError(error.message));
        }
    }

    const handleCloseModal = () => {
        dispatch(setProductError(""));
        setOpenCreateModal(false);
        setEditFlag(false);
    }

    const offerTypeItem = [
        {
            label: "Amount",
            value: "cashAmount"
        },
        {
            label: "Percentage",
            value: "percent"
        }
    ]

    const merchantProductTypeItem = [
        {
            label: "Category",
            value: "category"
        },
        {
            label: "Product",
            value: "product"
        }
    ]

    const statusType = [
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Inactive",
            value: "inactive"
        }
    ]

    const rewardTypeItem = [
        {
            label: "Reward",
            value: "reward"
        },
        {
            label: "Cashback",
            value: "cashback"
        }
    ]

    return (
        <div id='create-merchant-modal' className='absolute top-1/2 left-1/2 w-[80vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'>
            <div className="container p-3 py-6 flex flex-col items-center md:p-10">
                <h1 className="text-[24px] mb-4">
                    {editFlag ? "Edit Product" : "Create Product"}
                </h1>
                <form onSubmit={onSubmitForm} className="w-full flex flex-col items-center">
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <SelectMenu req value={editFlag && currentProduct?.merchantID} name='merchantID' label="Merchant ID" options={merchantsID} />
                            {/* <InputField req name='merchantID' value={!editFlag ? "" : currentProduct?.merchantID} label="Merchant ID" /> */}
                        </div>
                        <div className='w-full md:w-full'>
                            {editFlag ? (
                                <div className='flex flex-col'>
                                    <p>Merchant Name:</p>
                                    <p>{findMerchant(currentProduct?.merchantID)}</p>
                                </div>
                            ) : (
                                <SelectMenu req name='merchantName' label="Merchant Name" options={merchantName} />

                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <SelectMenu req label='Merchant Product Type' name="merchantProductType" value={editFlag && currentProduct?.merchantProductType} options={merchantProductTypeItem} />
                        </div>
                        <div className='w-full md:w-full'>
                            <InputField req name='productID' value={!editFlag ? "" : currentProduct?.productID} label="Product ID" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <InputField req name='productName' value={!editFlag ? "" : currentProduct?.productName} label="Product Name" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className="w-full md:w-full">
                            <InputField req value={!editFlag ? "" : (currentProduct?.deliveryPackingChange || 0)} type="number" name="deliveryPackingChange" label="Delivery Packing Charge" />
                        </div>
                        <div className="w-full md:w-full">
                            <InputField req value={!editFlag ? "" : (currentProduct?.deliveryFee || 0)} name="deliveryFee" type='number' label={`Delivery Fee`} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <InputField req value={!editFlag ? "" : currentProduct?.price} type='number' name='price' label="Price" />
                        </div>
                        <div className='w-full md:w-full'>
                            <InputField req value={!editFlag ? "" : currentProduct?.packingFee} type='number' name='packingFee' label="Packing Fee" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <SelectMenu req value={editFlag && currentProduct?.productOfferType} name='productOfferType' label="Product Offer Type" options={offerTypeItem} />
                        </div>
                        <div className="w-full md:w-full">
                            <InputField req value={!editFlag ? "" : (currentProduct?.productOffer || 0)} type='number' name="offer" label="Product's Offer" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 mt-4 w-full md:w-4/5">
                        <div className="w-full md:w-full">
                            <InputField req value={!editFlag ? "" : currentProduct?.offerDescription} name="offerDescription" label="Offer Description" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 mt-4 w-full md:w-4/5">
                        <div className="w-full md:w-full">
                            <InputField req value={!editFlag ? "" : currentProduct?.tenant} name="tenant" label="Tenant" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <label className="block mb-1 text-sm font-medium text-gray-900">
                                Product Description
                                <span className="text-red-700">
                                    *
                                </span>
                            </label>
                            <textarea
                                placeholder='Add Something nice...'
                                name="productDescription"
                                id="productDesc"
                                cols="auto"
                                rows="10"
                                className='bg-white w-full border border-inputBorder shadow-sidebarActive rounded-md outline-none p-3 text-sm'
                            >
                                {!editFlag ? "" : currentProduct?.productDescription}
                            </textarea>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full'>
                            <InputField req name='productTags_1' value={!editFlag ? "" : tags[0]} placeholder="Tag 1: Merchant Category" label="Product Tag 1" />
                        </div>
                        <div className='w-full'>
                            <InputField req name='productTags_2' value={!editFlag ? "" : (tags.length>1 ? tags[1] : "")} placeholder="Tag 2: Merchant Name" label="Product Tag 2" />
                        </div>
                        <div className='w-full'>
                            <InputField req name='productTags_3' value={!editFlag ? "" : (tags.length>2 ? tags[2] : "")} placeholder="Tag 3: Item" label="Product Tag 3" />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className="w-full md:w-full">
                            <InputField req value={!editFlag ? "" : currentProduct?.platform} name="platform" placeholder='Ex: Saathi' label="Platform" />
                        </div>
                        <div className="w-full md:w-full">
                            <SelectMenu req value={editFlag && currentProduct?.rewardType} name='rewardType' label="Reward Type" options={rewardTypeItem} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <ProductParameterInput label='Product Parameters' params={editFlag ? params : undefined} name="productParameters" />
                        </div>

                        <div className="w-full md:w-full">
                            <ProductParameterInput label='Related Articles' params={editFlag ? articles : undefined} name="relatedArticles" />
                        </div>

                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <InputField req name='productLinkURL' value={!editFlag ? "" : currentProduct?.productLinkURL} label="Product Link URL" placeholder='https://.....' />
                        </div>
                        <div className="w-full md:w-full">
                            <SelectMenu req value={editFlag && currentProduct?.status} name='status' label="Status" options={statusType} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <InputField req name='productImageURL' value={!editFlag ? "" : currentProduct?.productImageURL} placeholder="https://...." label="Product Image URL" />
                        </div>
                        <div className="w-full md:w-full">
                            <InputField req value={!editFlag ? "" : currentProduct?.trackingLink} placeholder="https://...." name="trackingLink" label="Tracking URL" />
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-6 mt-4 w-full md:w-4/5">
                        <div className='flex w-4/5 items-center h-10 mt-4'>
                            <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox" name="hasVoucher" defaultChecked={editFlag && currentProduct?.hasVoucher} id="hasVoucher" value="true" />
                            <div className='text-sm ml-3'>
                                Has Voucher?
                            </div>
                        </div>
                        <div className='flex w-4/5 items-center h-10 mt-4'>
                            <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox" name="featured" defaultChecked={editFlag && currentProduct?.featured} id="featured" value="true" />
                            <div className='text-sm ml-3'>
                                Featured
                            </div>
                        </div>
                        <div className='flex w-4/5 items-center h-10 mt-4'>
                            <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox" name="isExpired" defaultChecked={editFlag && currentProduct?.isExpired} id="isExpired" value="true" />
                            <div className='text-sm ml-3'>
                                Product is Expired?
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-4 w-full md:w-4/5">
                        <div className='w-full md:w-full'>
                            <button onClick={handleCloseModal} className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center' >
                                Cancel
                            </button>
                        </div>
                        <div className='w-full md:w-full'>
                            <button type='submit' className='bg-btnPink shadow-sidebarActive rounded-lg h-10 w-full flex justify-center items-center text-white' >
                                {editFlag ? "Save" : "Add"}
                            </button>
                        </div>
                    </div>
                </form>
                {(productListError && productListError.length > 0) && (
                    <div className='block text-sm text-red-600'>{productListError}</div>
                )}
            </div>
        </div>
    )
}

export default CreateProductModal