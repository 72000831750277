import React, { useState } from 'react'
import Eye from '../../assets/images/eye.svg';

function PasswordInput({ label, min = 0, value = "", name = "", placeholder = "", onChangeHandler = (e) => { } }) {
    const [type, setType] = useState("password");
    const toggleShowPassword = () => {
        if (type === 'password') {
            setType("text");
        } else {
            setType("password");
        }
    }
    return (
        <div className='w-[329px] my-3'>
            <label htmlFor={label} className="block text-[20px] font-medium">
                {label}
            </label>
            <div className="relative flex items-center mt-1 rounded-md shadow-sidebarActive w-full">
                <input
                    type={type}
                    onChange={onChangeHandler}
                    defaultValue={value}
                    name={name}
                    min={min}
                    className="block w-full h-10 rounded-md border border-inputBorder pl-7 pr-7 outline-none sm:text-sm bg-btnGray"
                    placeholder={placeholder}
                />
                <img onClick={toggleShowPassword} className='w-[30px] h-[25px] mx-2' src={Eye} alt="" />
            </div>
        </div>
    )
}

export default PasswordInput