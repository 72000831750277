import { useEffect, useState } from 'react';
import BucketListCategories from './Categories/BucketListCategories';
import BucketListIdeas from './Ideas/BucketListIdeas';
import { classify } from '../../../common/Utility';
import {
  deleteIdeaFromDB,
  listAllCategories,
  listAllIdeas,
  sortCategoriesAlphabetically,
  sortIdeasAlphabetically,
} from './utils';

const BucketList = () => {
  const [pageSelected, setPageSelected] = useState<'Categories' | 'Ideas'>(
    'Categories'
  );

  const [allCategories, setAllCategories] = useState<IdeaCategory[]>([]);
  const [allIdeas, setAllIdeas] = useState<Idea[]>([]);

  // Fetch initial data:
  useEffect(() => {
    listAllCategories().then((categoriesFetch) => {
      setAllCategories(sortCategoriesAlphabetically(categoriesFetch));
    });
    listAllIdeas().then((ideas) => {
      setAllIdeas(sortIdeasAlphabetically(ideas));
    });
  }, []);

  return (
    <div>
      <div className={classify(['flex gap-12 justify-start ml-10 mt-6'])}>
        <span
          className={classify([
            'cursor-pointer',
            pageSelected == 'Categories' && 'underline',
          ])}
          onClick={() => {
            setPageSelected('Categories');
          }}
        >
          CATEGORIES
        </span>
        <span
          className={classify([
            'cursor-pointer',
            pageSelected === 'Ideas' && 'underline',
          ])}
          onClick={() => {
            setPageSelected('Ideas');
          }}
        >
          IDEAS
        </span>
      </div>

      {pageSelected === 'Categories' ? (
        <BucketListCategories
          allCategories={allCategories}
          setAllCategories={setAllCategories}
        />
      ) : (
        <BucketListIdeas
          allIdeas={allIdeas}
          allCategories={allCategories}
          setAllIdeas={setAllIdeas}
        />
      )}
    </div>
  );
};
export default BucketList;
