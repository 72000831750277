import React from 'react'

function FAQ() {
    return (
        <div className="container">
            <h1 className="text-3xl">Frequently asked questions</h1>

            <div className="flex flex-col">
                <h3 className='text-2xl'>
                    My customers haven't received their reward or cashback, what can I advise them?
                </h3>
                <p className="text-2xl">
                    Simply ask your customer to contact support@saathi.money or use the help section in the app and we'll find out what is going on - if you want to be updated on their case, let us know at merchants@saathi.money or through your customer success agent.
                </p>
            </div>

            <div className="flex flex-col">
                <h3 className="text-2xl">
                    Need a Saathi Banner for your website?
                </h3>
                <p className="text-2xl">
                    Copy
                </p>
                <p className="text-2xl underline">
                    Download Here
                </p>
            </div>

            <div className="flex flex-col">
                <h3 className='text-2xl'>
                    Have more questions?
                </h3>
                <p className="text-2xl">
                    We’re happy to answer any more questions you might have.  You can email us at support@besaathi.com or Whatsapp/Call us at +91 982 0502 971.
                </p>
            </div>

        </div>
    )
}

export default FAQ