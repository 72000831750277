import { handleAPICall } from '../../../api/APIUtils';
import { CategoryInput } from './Categories/AddCategoriesModal/UploadCategoriesFile';
import * as XLSX from 'xlsx';

// --- FOR CATEGORIES: ---

// Fetch ALL categories (both merchant-specific and non):
export async function listAllCategories(): Promise<IdeaCategory[]> {
  let encouragements: [] = [];
  try {
    await handleAPICall(
      'encouragementAPI',
      '/encouragement/categories?limit=9999999999', // Ask Bhakti to allow me to send a string saying 'all' or something?
      {},
      'GET'
    ).then((res) => {
      console.log('RES FROM LISTING ALL CATEGORIES', res);
      if (res.error.error) {
        throw 'ERR';
      }
      encouragements = res.success.data.items;
    });
  } catch (err) {
    console.log('ERROR LISTING ALL CATEGORIES', err);
  }
  console.log('ENCOURAGEMENTS', encouragements);
  return encouragements;
}

export function sortCategoriesAlphabetically(categories: IdeaCategory[]) {
  return categories.sort((a, b) =>
    a.category_name.localeCompare(b.category_name)
  );
}

type NewCategoryAPIInput = {
  category_name?: string;
  category_description?: string;
  image_vertical_url?: string;
  image_horizontal_url?: string;
  merchant_id?: string;
  is_active: boolean;
};
type UpdatedCategoryAPIInput = NewCategoryAPIInput & {
  category_id: string;
};

export async function handleCategoryFileUpload(
  file: File,
  allExistingCategories: IdeaCategory[],
  setAllCategories: (newList: IdeaCategory[]) => void,
  setUploadErrorMessage: (err: string) => void,
  handleSuccess: () => void
) {
  let reader = new FileReader();
  reader.onload = async (e) => {
    let uploadErrorMessage;
    try {
      //1) Extract Categories data from Excel/CSV upload:
      var data = e.target.result;
      let dataRead = XLSX.read(data, { type: 'binary' });
      const wsname = dataRead.SheetNames[0];
      const ws = dataRead.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const sheetHeaders = dataParse[0] as string[];
      const correctHeaders = [
        {
          sheetColumnHeader: 'Name',
          dbHeader: 'category_name',
        },
        {
          sheetColumnHeader: 'Description',
          dbHeader: 'category_description',
        },
        {
          sheetColumnHeader: 'Image URL(Vertical)',
          dbHeader: 'image_vertical_url',
        },
        {
          sheetColumnHeader: 'Image URL(Horizontal)',
          dbHeader: 'image_horizontal_url',
        },
        {
          sheetColumnHeader: 'Merchant ID',
          dbHeader: 'merchant_id',
        },
      ];
      sheetHeaders.forEach((header, i) => {
        if (header !== correctHeaders[i].sheetColumnHeader) {
          console.log('INCORRECT HEADER', header);

          throw 'COLUMN HEADERS INCORRECT';
        }
      });

      // Prepare data for upload:
      const dataArray: NewCategoryAPIInput[] = dataParse
        .slice(1, dataParse.length)
        // Dismiss any lines that don't have a name:
        .filter((lineValues: string[]) => {
          return lineValues[0] != null;
        })
        .map((lineValues: string[]) => {
          const lineObject: NewCategoryAPIInput = {
            is_active: true,
          };
          console.log('LINE VALUES', lineValues);
          lineValues.forEach((val, i) => {
            const key = correctHeaders[i].dbHeader;
            lineObject[key] = val;
          });
          // Ensure critical fields present:
          if (
            !lineObject.category_description ||
            !lineObject.image_horizontal_url ||
            !lineObject.image_vertical_url
          ) {
            throw 'EMPTY FIELD NOT ACCEPTABLE';
          }
          return lineObject as NewCategoryAPIInput;
        });
      console.log('FINAL DATA VALUES', dataArray);

      // Separate data into updates vs creations:
      let categoriesToUpdate: UpdatedCategoryAPIInput[] = [];
      let categoriesToCreate: NewCategoryAPIInput[] = [];
      dataArray.forEach((categoryUpload) => {
        let existingCategory: IdeaCategory = allExistingCategories.find(
          (category) => {
            return category.category_name === categoryUpload.category_name;
          }
        );
        if (existingCategory) {
          if (
            categoryUpload.category_description !==
              existingCategory.category_description ||
            categoryUpload.image_horizontal_url !==
              existingCategory.image_horizontal_url ||
            categoryUpload.image_vertical_url !==
              existingCategory.image_vertical_url ||
            categoryUpload.merchant_id !== existingCategory.merchant_id
          ) {
            categoriesToUpdate.push({
              ...categoryUpload,
              category_id: existingCategory.category_id,
            });
          }
        } else {
          categoriesToCreate.push(categoryUpload);
        }
      });
      console.log('CATEGORIES TO UPDATE', categoriesToUpdate);
      console.log('CATEGORIES TO CREATE', categoriesToCreate);

      // 2) Hit API to populate data in DB:
      const successfulUpdations: IdeaCategory[] = [];
      const sucessfulCreations: IdeaCategory[] = [];
      const errorUploads = [];

      // UPDATIONS:
      await Promise.allSettled(
        categoriesToUpdate.map(async (updateCategory) => {
          await updateCategoryInDB(updateCategory)
            .then((uploadResult) => {
              console.log('RESULT FROM UPDATING CATEGORY', uploadResult);
              successfulUpdations.push(uploadResult);
            })
            .catch((err) => {
              errorUploads.push();
            });
        })
      );

      // CREATIONS:
      await Promise.allSettled(
        categoriesToCreate.map(async (newCategory) => {
          await uploadCategoryToDB(newCategory)
            .then((uploadResult) => {
              console.log('RESULT FROM CREATING CATEGORY', uploadResult);
              sucessfulCreations.push(uploadResult);
            })
            .catch((err) => {
              errorUploads.push();
            });
        })
      ).then((res) => {
        console.log('RESULT FROM ALL SETTLED', res);
      });

      // Update local state (replace existing updations/ add new ones):
      successfulUpdations.forEach((categoryUpdated) => {
        const existingCategoryIndex = allExistingCategories.findIndex(
          (existingCat) => {
            return existingCat.category_name === categoryUpdated.category_name;
          }
        );
        allExistingCategories[existingCategoryIndex] = categoryUpdated;
      });

      setAllCategories(
        sortCategoriesAlphabetically([
          ...allExistingCategories,
          ...sucessfulCreations,
        ])
      );
      handleSuccess(); //
    } catch (err) {
      console.log('ERROR HANDLING CAT FILE UPLOAD', err);
      setUploadErrorMessage(err);
    }
  };
  reader.readAsBinaryString(file);
}

async function updateCategoryInDB(
  updatedCategory: UpdatedCategoryAPIInput
): Promise<IdeaCategory> {
  const categoryId = updatedCategory.category_id;
  delete updatedCategory.category_id;

  let updateResult: IdeaCategory;

  await handleAPICall(
    'encouragementAPI',
    `/encouragement/categories/${categoryId}`,
    { body: updatedCategory },
    'UPDATE'
  )
    .then((res) => {
      console.log('RES FROM UPDATION API', res);
      if (res.errors) {
        throw res.errors[0];
      }
      updateResult = res.success.data;
    })
    .catch((err) => {
      console.log('ERRO CATCHED HERE', err);
    });

  return { ...updateResult, category_id: categoryId };
}

async function uploadCategoryToDB(
  newCategory: NewCategoryAPIInput
): Promise<IdeaCategory> {
  let uploadResult: IdeaCategory;

  await handleAPICall(
    'encouragementAPI',
    '/encouragement/categories',
    { body: newCategory },
    'POST'
  )
    .then((res) => {
      console.log('RES FROM CREATION API', res);
      if (res.errors) {
        throw res.errors[0];
      }
      uploadResult = res.success.data;
      console.log('UPLOAD RESULT', uploadResult);
    })
    .catch((err) => {
      console.log('ERRO CATCHED HERE', err);
    });

  return uploadResult;
}

export async function deleteCategoryFromDB(categoryId: string) {
  console.log('RUNNING DELET CATEGORY', categoryId);

  await handleAPICall(
    'encouragementAPI',
    `/encouragement/categories/${categoryId}`,
    {
      //
    },
    'DELETE'
  )
    .then((res) => {
      console.log('CATEGORY DELETED', res);
    })
    .catch((err) => {
      console.log('ERRO CATCHED HERE', err);
    });
}

// --- FOR IDEAS: ---

// Fetch ALL ideas (both merchant-specific and non):
export async function listAllIdeas(): Promise<Idea[]> {
  let ideas: [] = [];

  try {
    await handleAPICall(
      'encouragementAPI',
      '/encouragement/ideas/?limit=9999999999',
      {},
      'GET'
    ).then((res) => {
      console.log('RES FROM LISTING ALL IDEAS', res);
      if (res.error.error) {
        throw 'ERR';
      }
      ideas = res.success.data.items;
    });
  } catch (err) {
    console.log('ERROR LISTING ALL IDEAS', err);
  }
  console.log('IDEAS', ideas);
  return ideas;
}

type NewIdeaAPIInput = {
  idea_title?: string;
  idea_content?: string;
  idea_image_url: string;
  voucher_code?: string;
  merchant_id?: string;
  offer_id?: string;
  is_active: boolean;
  categories: string[]; // string of category ids
};
type UpdatedIdeaAPIInput = NewIdeaAPIInput & {
  idea_id: string;
};

export async function handleIdeaFileUpload(
  file: File,
  allExistingIdeas: Idea[],
  allExistingCategories: IdeaCategory[],
  setAllIdeas: (newList: Idea[]) => void,
  setUploadErrorMessage: (string) => void,
  handleSuccess: () => void
) {
  console.log('ALL EXISTING IDEAS', allExistingIdeas);

  let reader = new FileReader();
  reader.onload = async (e) => {
    try {
      //1) Extract Categories data from Excel/CSV upload:
      var data = e.target.result;
      let dataRead = XLSX.read(data, { type: 'binary' });
      const wsname = dataRead.SheetNames[0];
      const ws = dataRead.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const sheetHeaders = dataParse[0] as string[];
      const correctHeaders = [
        {
          sheetColumnHeader: 'Name',
          dbHeader: 'idea_title',
        },
        {
          sheetColumnHeader: 'Categories',
          dbHeader: 'categories',
        },
        {
          sheetColumnHeader: 'Description',
          dbHeader: 'idea_content',
        },
        {
          sheetColumnHeader: 'Image URL',
          dbHeader: 'idea_image_url',
        },
        {
          sheetColumnHeader: 'Voucher Code',
          dbHeader: 'voucher_code',
        },
        {
          sheetColumnHeader: 'Merchant ID',
          dbHeader: 'merchant_id',
        },
        {
          sheetColumnHeader: 'Offer ID',
          dbHeader: 'offer_id',
        },
      ];
      sheetHeaders.forEach((header, i) => {
        if (header !== correctHeaders[i].sheetColumnHeader) {
          console.log('INCORRECT HEADER', header);
          throw `COLUMN HEADERS INCORRECT: ${header}`;
        }
      });

      // Prepare data for upload:
      const dataArray: NewIdeaAPIInput[] = dataParse
        .slice(1, dataParse.length)
        // Dismiss any lines that don't have a name:
        .filter((lineValues: string[]) => {
          return lineValues[0] != null;
        })
        .map((lineValues: string[]) => {
          let lineObject: any = {
            is_active: true,
          };
          console.log('LINE VALUES', lineValues);
          lineValues.forEach((val, i) => {
            const key = correctHeaders[i].dbHeader;
            console.log('KEY', key);
            // Try and match up category names provided with existing category ids:
            if (key === 'categories') {
              console.log('XXXXX', val.split(/\s*,\s/));
              lineObject[key] = val.split(/\s*,\s*/).map((categoryName) => {
                console.log('CAT NAME', categoryName);
                const existingCategoryMatch = allExistingCategories.find(
                  (existingCat) =>
                    existingCat.category_name.toLocaleLowerCase() ===
                    categoryName.toLocaleLowerCase()
                )?.category_id;
                // If category doesnt already exist then throw error (until BHAKTI backend changes are done, relevant category must be added first)
                if (!existingCategoryMatch) {
                  throw `A Category named: '${categoryName}' for Idea: '${lineValues[0]}' does not already exist. Please add this category before adding this idea.`;
                } else {
                  return existingCategoryMatch;
                }
              });
            } else {
              // lineObject[key] = val;
              console.log(key, val, typeof val);
              lineObject[key] = val ?? null;
            }
            console.log(
              'LINE CATEGORY IDs',
              lineObject.categories,
              typeof lineObject.categories
            );
          });
          console.log('DONE MAPPING');

          // Ensure critical fields present:
          if (
            !lineObject.categories ||
            !lineObject.idea_content ||
            !lineObject.idea_image_url
          ) {
            throw 'EMPTY FIELD NOT ACCEPTABLE';
          }
          return lineObject as NewIdeaAPIInput;
        });
      console.log('FINAL IDEA UPLOAD DATA VALUES', dataArray);

      // Separate data into updates vs creations:
      let ideasToUpdate: UpdatedIdeaAPIInput[] = [];
      let ideasToCreate: NewIdeaAPIInput[] = [];
      dataArray.forEach((ideaUpload) => {
        let existingIdea: Idea = allExistingIdeas.find((idea) => {
          return (
            idea.merchant_id === ideaUpload.merchant_id &&
            idea.idea_title === ideaUpload.idea_title
          );
        });
        if (existingIdea) {
          if (
            // DYLAN TRY COMMENTING OUT THIS CHECK TO ALLOW CHANGES IN CATEGORY LISTS FOR AN IDEA IN THE FILE UPLOAD:
            !checkForMatchInCategoryLists(
              existingIdea.categories.map((cat) => cat.category_id),
              ideaUpload.categories
            ) ||
            ideaUpload.idea_content != existingIdea.idea_content ||
            ideaUpload.idea_image_url != existingIdea.idea_image_url ||
            ideaUpload.merchant_id != existingIdea.merchant_id ||
            ideaUpload.offer_id != existingIdea.offer_id
          ) {
            console.log('PUSHING IDEA FOR UPDATE', ideaUpload);
            ideasToUpdate.push({
              ...ideaUpload,
              idea_id: existingIdea.idea_id,
            });
          }
        } else {
          ideasToCreate.push(ideaUpload);
        }
      });
      console.log('IDEAS TO UPDATE', ideasToUpdate);
      console.log('IDEAS TO CREATE', ideasToCreate);

      // 2) Hit API to populate data in DB:
      const successfulUpdations: Idea[] = [];
      const sucessfulCreations: Idea[] = [];
      const errorUploads = [];

      // UPDATIONS:
      await Promise.allSettled(
        ideasToUpdate.map(async (updateIdea) => {
          console.log('UPDATING IDEA', updateIdea);

          await updateIdeaInDB(updateIdea)
            .then((uploadResult) => {
              console.log('RESULT FROM UPDATING IDEA', uploadResult);
              successfulUpdations.push(uploadResult);
            })
            .catch((err) => {
              errorUploads.push();
            });
        })
      );

      // CREATIONS:
      await Promise.allSettled(
        ideasToCreate.map(async (newIdea) => {
          await addIdeaToDB(newIdea)
            .then((uploadResult) => {
              console.log('RESULT FROM CREATING IDEA', uploadResult);
              sucessfulCreations.push(uploadResult);
            })
            .catch((err) => {
              errorUploads.push();
            });
        })
      ).then((res) => {
        console.log('RESULT FROM ALL SETTLED', res);
      });

      // Update local state (replace existing updations/ add new ones):
      console.log('SUCCESSFUL UPDATIONS', successfulUpdations);
      console.log('SUCCESSFUL CREATIONS', sucessfulCreations);

      successfulUpdations.forEach((ideaUpdated) => {
        const existingIdeaIndex = allExistingIdeas.findIndex((existingIdea) => {
          return existingIdea.idea_title === ideaUpdated.idea_title;
        });
        allExistingIdeas[existingIdeaIndex] = ideaUpdated;
      });
      setAllIdeas(
        sortIdeasAlphabetically([...allExistingIdeas, ...sucessfulCreations])
      );
      handleSuccess();
    } catch (err) {
      console.log('ERROR UPLOADING IDEAS FILE', err);
      setUploadErrorMessage(err);
    }
  };

  reader.readAsBinaryString(file);
}

async function updateIdeaInDB(updatedIdea: UpdatedIdeaAPIInput): Promise<Idea> {
  const ideaId = updatedIdea.idea_id;
  delete updatedIdea.idea_id;
  delete updatedIdea.categories; // TEMPORARY UNTIL BHAKTI UPDATES API TO ALLOW CATEGORY MODICIATION

  let updateResult: Idea;

  await handleAPICall(
    'encouragementAPI',
    `/encouragement/ideas/${ideaId}`,
    { body: updatedIdea },
    'UPDATE'
  )
    .then((res) => {
      console.log('RES FROM UPDATION API', res);
      if (res.errors) {
        throw res.errors[0];
      }
      updateResult = res.success.data;
    })
    .catch((err) => {
      console.log('ERRO CATCHED HERE', err);
    });

  return updateResult;
  // return {
  //   ...updateResult,
  // categories:categoryIds};
}

async function addIdeaToDB(newIdea: NewIdeaAPIInput): Promise<Idea> {
  let uploadResult: Idea;

  await handleAPICall(
    'encouragementAPI',
    '/encouragement/ideas',
    { body: newIdea },
    'POST'
  )
    .then((res) => {
      console.log('RES FROM CREATION API', res);
      if (res.errors) {
        throw res.errors[0];
      }
      uploadResult = res.success.data;
      console.log('UPLOAD RESULT', uploadResult);
    })
    .catch((err) => {
      console.log('ERRO CATCHED HERE', err);
    });

  return uploadResult;
}

export async function deleteIdeaFromDB(ideaId: string) {
  console.log('DELETING IDEA FROM DB', ideaId);
  await handleAPICall(
    'encouragementAPI',
    `/encouragement/ideas/${ideaId}`,
    {},
    'DELETE'
  )
    .then((res) => {
      console.log('RES FROM DELETING ID', res);
    })
    .catch((err) => {
      console.log('ERRO CATCHED DELETING IDEA HERE', err);
    });
}

function checkForMatchInCategoryLists(
  existingCategoryNames: string[],
  newCategoryNames: string[]
) {
  let checkResult: boolean = true;

  console.log('A', existingCategoryNames);
  console.log('B', newCategoryNames);

  // Check if arrays have the same length
  if (existingCategoryNames.length !== newCategoryNames.length) {
    checkResult = false;
  }
  // Convert arrays to sets to remove duplicates and for efficient membership checking
  const set1 = new Set(existingCategoryNames);
  const set2 = new Set(newCategoryNames);
  // Check if both sets have the same size
  if (set1.size !== set2.size) {
    checkResult = false;
  }
  // Check if all elements of set1 are present in set2
  for (const item of set1) {
    if (!set2.has(item)) {
      checkResult = false;
    }
  }
  // If all checks passed, arrays contain the same strings
  console.log('RESULT OF CHECKING CATEGORY LISTS', checkResult);
  return checkResult;
}

export function sortIdeasAlphabetically(ideas: Idea[]) {
  return ideas.sort((a, b) => a.idea_title.localeCompare(b.idea_title));
}
