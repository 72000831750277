import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { TailSpin } from 'react-loader-spinner'
import { utils, writeFileXLSX } from 'xlsx';

import FilterDropdown from '../../../sharedComponents/FilterDropdown/FilterDropdown';
import Pagination from '../../../sharedComponents/Pagination/Pagination';
import CreateCategoryModal from './CreateCategoryModal';

import {
    listMerchantProductCategoryAPI,
    saveCurrentProductCat,
    setMerchantProductCatError,
    updateMerchantProductCat
}
    from '../../../api/productCategorySlice';

import filterLogo from '../../../assets/images/filterLogo.svg'
import downloadLogo from '../../../assets/images/downloadLogo.svg'
import createLogo from '../../../assets/images/createLogo.svg'
import upIcon from '../../../assets/images/upIcon.svg';

function Categories() {
    const dispatch = useDispatch();
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [ascSort, setAscSort] = useState([true, true, true, true]);
    const [filterCategories, setFilterCategories] = useState([]);
    const [currentRecords, setCurrentRecords] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const { merchantProductCategories, productCategoryLoading, productCategoryError } = useSelector(state => state.merchantProductCat);

    useEffect(() => {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        let oldCategories = [...merchantProductCategories];

        if (filterCategories.length > 0) {
            const newCategories = oldCategories.filter(handleFilter);
            if (newCategories.length <= recordsPerPage) {
                setCurrentRecords(oldCategories);
            } else {
                setCurrentRecords(newCategories.slice(indexOfFirstRecord, indexOfLastRecord))
            }
        } else {
            setCurrentRecords([...oldCategories.slice(indexOfFirstRecord, indexOfLastRecord)])
        }
    }, [currentPage, recordsPerPage, productCategoryLoading, merchantProductCategories, filterCategories]);

    useEffect(() => {
        if (merchantProductCategories.length === 0) {
            fetchAllCategories();
        }
    }, [])

    const sortCategories = () => {
        let oldSort = [true, true, true, true];
        if (ascSort[0]) {
            let oldCategories = [...merchantProductCategories];
            oldCategories.sort(function (a, b) {
                return a.category_id.localeCompare(b.category_id)
            });
            dispatch(updateMerchantProductCat([...oldCategories]))
            oldSort[0] = false;
            setAscSort([...oldSort]);
        } else {
            let oldCategories = [...merchantProductCategories];
            oldCategories.sort(function (a, b) {
                return -1 * a.category_id.localeCompare(b.category_id)
            });
            dispatch(updateMerchantProductCat([...oldCategories]))
            oldSort[0] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortByCashkaroId = () => {
        let oldSort = [true, true, true, true];
        if (ascSort[1]) {
            let oldCategories = [...merchantProductCategories];
            oldCategories.sort(function (a, b) {
                return (a.cashkaroCategoryId || "").localeCompare((b.cashkaroCategoryId || ""))
            });
            dispatch(updateMerchantProductCat([...oldCategories]))
            oldSort[1] = false;
            setAscSort([...oldSort]);
        } else {
            let oldCategories = [...merchantProductCategories];
            oldCategories.sort(function (a, b) {
                return -1 * (a.cashkaroCategoryId || "").localeCompare((b.cashkaroCategoryId || ""))
            });
            dispatch(updateMerchantProductCat([...oldCategories]))
            oldSort[1] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortByDate = (flag = false) => {
        let oldSort = [true, true, true, true];
        if (flag ? ascSort[2] : ascSort[3]) {
            let oldCategories = [...merchantProductCategories];
            if (flag) {
                oldCategories.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldCategories.sort(function (a, b) {
                    return new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateMerchantProductCat([...oldCategories]))
            if (flag) {
                oldSort[2] = false;
            } else {
                oldSort[3] = false;
            }
            setAscSort([...oldSort]);
        } else {
            let oldCategories = [...merchantProductCategories];
            if (flag) {
                oldCategories.sort(function (a, b) {
                    return -1 * new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldCategories.sort(function (a, b) {
                    return -1 * new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateMerchantProductCat([...oldCategories]))
            if (flag) {
                oldSort[2] = true;
            } else {
                oldSort[3] = true;
            }
            setAscSort([...oldSort]);
        }
    }

    const fetchAllCategories = () => {
        dispatch(listMerchantProductCategoryAPI());
    }

    const handleFilter = (cat) => {
        if (filterCategories.length === 0) {
            return true;
        }
        const id = filterCategories.findIndex(e => e === cat?.category_name);
        return id !== -1;
    }

    const handleOpenFilterMenu = () => {
        setShowFilterMenu(prev => !prev);
    }

    const handleCurrentArticle = (data) => {
        dispatch(saveCurrentProductCat(data));
        setEditFlag(true);
        setOpenCreateModal(true);
    }

    const closeModal = () => {
        dispatch(setMerchantProductCatError(""));
        setEditFlag(false);
        setOpenCreateModal(false);
    }

    const convertToCSV = async (data) => {
        try {
            const ws = utils.json_to_sheet(data);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Categories");
            writeFileXLSX(wb, "category_data.xlsx");

        } catch (error) {
            console.log(error);
        }
    }

    const tableHeadingClasses = "text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading"

    const tableCellClasses = "min-h-[40px] flex items-center justify-start"

    return (
        <>
            {(productCategoryLoading || currentRecords.length === 0) ? (
                <>
                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                        <TailSpin
                            height="120"
                            width="120"
                            color="#62A6FF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            visible={true}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="container max-w-full">
                        <div className='w-full flex justify-between items-center min-w-max'>
                            <h1 className='text-[32px] font-bold m-[40px]'>Categories</h1>
                            <div className='w-[200px] mr-10 flex justify-around'>
                                <div className='cursor-pointer'>
                                    <img onClick={handleOpenFilterMenu} src={filterLogo} alt="" />
                                    <FilterDropdown
                                        filterItems={filterCategories}
                                        setFilterItems={setFilterCategories}
                                        showFilterMenu={showFilterMenu}
                                        type="Categories"
                                    />
                                </div>
                                <div className='cursor-pointer'>
                                    <img onClick={() => convertToCSV(currentRecords.filter(handleFilter))} src={downloadLogo} alt="" />
                                </div>
                                <div className='cursor-pointer' onClick={() => { setOpenCreateModal(prev => !prev) }}>
                                    <img src={createLogo} alt="" />
                                </div>
                            </div>
                        </div>

                        <div id='categories-table' className='flex flex-col justify-center text-center overflow-x-auto flex-1 overflow-y-hidden pb-12 min-w-max'>
                            <>
                                <div className='w-full flex'>
                                    <div className={`w-[60vw] lg:w-[20vw] ${tableHeadingClasses} ml-10 pl-4 rounded-l-lg`}>
                                        ID
                                        <img onClick={sortCategories} className={`ml-2 ${ascSort[0] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[40vw] lg:w-[10vw] text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading`}>
                                        Category Image
                                    </div>
                                    <div className={`w-[50vw] lg:w-[15vw] ${tableHeadingClasses}`}>
                                        Category Name
                                    </div>
                                    <div className={`w-[60vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                        Status
                                    </div>
                                    <div className={`w-[50vw] lg:w-[15vw] ${tableHeadingClasses}`}>
                                        Created At
                                        <img onClick={() => sortByDate(true)} className={`ml-2 ${ascSort[2] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                    <div className={`w-[50vw] lg:w-[15vw] ${tableHeadingClasses}`}>
                                        Updated At
                                        <img onClick={() => sortByDate()} className={`ml-2 ${ascSort[3] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                    </div>
                                </div>

                                {merchantProductCategories && currentRecords.filter(handleFilter).map((ele, i) => {
                                    if (ele === null || ele === undefined) {
                                        return;
                                    }
                                    const {
                                        category_id,
                                        category_name,
                                        category_icon_url,
                                        category_active,
                                        updatedAt,
                                        createdAt
                                    } = ele

                                    return (
                                        <div className='w-full flex my-3'>
                                            <div onClick={() => handleCurrentArticle(ele)} className={`w-[60vw] lg:w-[20vw] cursor-pointer underline pl-4 text-blue-500 text-left truncate ${tableCellClasses} ml-10`}>
                                                <div className='w-[95%] overflow-x-auto'>
                                                    {category_id}
                                                </div>
                                            </div>
                                            <div className={`w-[40vw] lg:w-[10vw] cursor-pointer ml-2 min-h-[40px] flex items-center justify-start`}>
                                                <img height={50} width={50} src={category_icon_url} alt="category-image" />
                                            </div>
                                            <div className={`w-[50vw] lg:w-[15vw] ${tableCellClasses} text-left`}>
                                                {category_name}
                                            </div>
                                            <div className={`w-[60vw] lg:w-[10vw] ${tableCellClasses} text-left`}>
                                                {category_active ? "Active" : "Inactive"}
                                            </div>
                                            <div className={`w-[50vw] lg:w-[15vw] ${tableCellClasses}`}>
                                                {new Date(createdAt).toLocaleDateString()}
                                            </div>
                                            <div className={`w-[50vw] lg:w-[15vw] ${tableCellClasses}`}>
                                                {new Date(updatedAt).toLocaleDateString()}
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        </div>

                        <Pagination
                            size={merchantProductCategories.filter(handleFilter).length || 0}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setRecordsPerPage={setRecordsPerPage}
                            type='Categories'
                            recordsPerPage={recordsPerPage}
                        />

                        {/* Create Article Modal */}
                        <Transition appear show={openCreateModal} as={Fragment}>
                            <Dialog as="div" className="relative z-10" onClose={() => closeModal()}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                </Transition.Child>

                                <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full justify-center p-4">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Dialog.Panel className="transition-all">
                                                <CreateCategoryModal editFlag={editFlag} setEditFlag={setEditFlag} setOpenCreateModal={setOpenCreateModal} openCreateModal={openCreateModal} />
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition>
                    </div>
                </>
            )}
        </>
    )
}

export default Categories