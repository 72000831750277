/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      OrgName
      OrgLogo
      OrgEmail
      HREmailAddresses
      RequestLargeAdvanceEmailAddresses
      OrgStatus
      OrgSystemFreezeStatus
      OrgWithdrawalCycle
      OrgCurrency
      OrgApprovalFlow
      OrgPayCheckDay
      OrgKYCFlag
      OrgTNCFlag
      Onboard
      EmpBasicInfo
      EmpBenefitSettings
      EmpInvited
      LendingApproach
      ChargeType
      BankName
      BankAccountNumber
      BankIFSC
      PaymentMode
      RegisteredAddress
      CompanyPhoneNumber
      CreatedAt
      ModifiedAt
      ApproveEmpUpdates
      AreWithdrawalsDownForMaintenance
      EmailWithdraw
      AutoApproveWithdrawals
      MaxLimitPercentAutoApproveWithdrawals
      TNCRequiredByEmployees
      KYCRequiredByEmployees
      TermsSource
      TermsURL
      EmployeeProbationPeriodMonths
      Product
      SingleWithdrawalDayOrSetPeriod
      SingleWithdrawalDayOfMonth
      OrgWithdrawalStartDay
      OrgWithdrawalEndDay
      FeeFreeBoolean
      PaymentFileOut
      PaymentFileDay
      PaymentFileHour
      ApprovalFlow
      MetricFlow
      tenant
      autoMarkWithdrawalsAsRepaidMonthsEnd
      employer {
        nextToken
      }
      blackoutPeriod {
        nextToken
      }
      growQuestionnaireResults {
        nextToken
      }
      monthlyReportMetrics {
        nextToken
      }
      orgCurrentCycleCalcs {
        orgId
        discount
        withdrawalPeriodStart
        withdrawalPeriodEnd
        payDate
        potentialBlackoutPercentage
        isWithdrawalPeriodOpen
        tenant
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OrgName
        OrgLogo
        OrgEmail
        HREmailAddresses
        RequestLargeAdvanceEmailAddresses
        OrgStatus
        OrgSystemFreezeStatus
        OrgWithdrawalCycle
        OrgCurrency
        OrgApprovalFlow
        OrgPayCheckDay
        OrgKYCFlag
        OrgTNCFlag
        Onboard
        EmpBasicInfo
        EmpBenefitSettings
        EmpInvited
        LendingApproach
        ChargeType
        BankName
        BankAccountNumber
        BankIFSC
        PaymentMode
        RegisteredAddress
        CompanyPhoneNumber
        CreatedAt
        ModifiedAt
        ApproveEmpUpdates
        AreWithdrawalsDownForMaintenance
        EmailWithdraw
        AutoApproveWithdrawals
        MaxLimitPercentAutoApproveWithdrawals
        TNCRequiredByEmployees
        KYCRequiredByEmployees
        TermsSource
        TermsURL
        EmployeeProbationPeriodMonths
        Product
        SingleWithdrawalDayOrSetPeriod
        SingleWithdrawalDayOfMonth
        OrgWithdrawalStartDay
        OrgWithdrawalEndDay
        FeeFreeBoolean
        PaymentFileOut
        PaymentFileDay
        PaymentFileHour
        ApprovalFlow
        MetricFlow
        tenant
        autoMarkWithdrawalsAsRepaidMonthsEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlackoutPeriod = /* GraphQL */ `
  query GetBlackoutPeriod($id: ID!) {
    getBlackoutPeriod(id: $id) {
      id
      OrgId
      startDate
      endDate
      chargeLimitPercent
      benefitDisabled
      createdBy
      description
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listBlackoutPeriods = /* GraphQL */ `
  query ListBlackoutPeriods(
    $filter: ModelBlackoutPeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlackoutPeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OrgId
        startDate
        endDate
        chargeLimitPercent
        benefitDisabled
        createdBy
        description
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrgNotifications = /* GraphQL */ `
  query GetOrgNotifications($id: ID!) {
    getOrgNotifications(id: $id) {
      id
      OrgId
      createdAt
      Description
      DisplayTo
      ActionType
      Title
      MarkedReadBy
      TaskId
      TaskCompleted
      tenant
      updatedAt
    }
  }
`;
export const listOrgNotificationss = /* GraphQL */ `
  query ListOrgNotificationss(
    $filter: ModelOrgNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgNotificationss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        createdAt
        Description
        DisplayTo
        ActionType
        Title
        MarkedReadBy
        TaskId
        TaskCompleted
        tenant
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployer = /* GraphQL */ `
  query GetEmployer($id: ID!) {
    getEmployer(id: $id) {
      id
      OrgId
      EmpId
      EmpDesignation
      EmpJoiningDate
      EmpEmail
      EmpCountryCode
      EmpMobileNo
      EmpPassword
      EmpGivenName
      EmpFamilyName
      EmpRole
      EmpDashBoardStatus
      EmpOnBordingStatus
      EmpFirstTimeLoginFlag
      EmpStatus
      LastLoggedIn
      ProfileLock
      NoOfAttempt
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      InAppRepayment
      InAppWithdraw
      InAppDeduction
      InAppPayroll
      EmailRepayment
      EmailWithdraw
      EmailDeduction
      EmailPayroll
      owner
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listEmployers = /* GraphQL */ `
  query ListEmployers(
    $filter: ModelEmployerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OrgId
        EmpId
        EmpDesignation
        EmpJoiningDate
        EmpEmail
        EmpCountryCode
        EmpMobileNo
        EmpPassword
        EmpGivenName
        EmpFamilyName
        EmpRole
        EmpDashBoardStatus
        EmpOnBordingStatus
        EmpFirstTimeLoginFlag
        EmpStatus
        LastLoggedIn
        ProfileLock
        NoOfAttempt
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        InAppRepayment
        InAppWithdraw
        InAppDeduction
        InAppPayroll
        EmailRepayment
        EmailWithdraw
        EmailDeduction
        EmailPayroll
        owner
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      OrgId
      UsrEmpId
      merchantUserId
      UsrDesignation
      UsrGrade
      UsrJoiningDate
      UsrEmail
      UsrMobileNo
      UsrWithdrawalLimit
      UsrGivenName
      UsrMiddleName
      UsrFamilyName
      fathersFirstName
      fathersMiddleName
      fathersLastName
      spouseFirstName
      spouseMiddleName
      spouseLastName
      mothersFirstName
      mothersMiddleName
      mothersLastName
      UsrBankAccount
      UsrBankName
      UsrBankIFSCCode
      UsrBenefitStatus
      UsrInviteEmail
      UsrConsentAcceptance
      UsrConsentTS
      UsrTCAcceptance
      UsrTCAcceptanceTS
      TCAcceptanceIP
      ConsentAgreed
      ConsentTimeStamp
      ConsentIP
      UsrKYCStatus
      UsrRegJourneyStatus
      UsrFirstTimeLoginFlag
      UsrStatus
      UsrWithdrawalCycle
      ProfileLock
      NoOfAttempt
      CreatedBy
      ModifiedBy
      LastLoggedIn
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      Invited
      Currency
      LimitAsPercent
      Salary
      JoiningDate
      DeactivationReason
      DeactivationDate
      lbUserId
      lbRegistered
      lbSign
      lbLoanId
      lbLoanDate
      lbContractDate
      lbAgreementIP
      limitUpdateFlag
      hasUserCompletedGrowQuestionnaire
      Title
      Gender
      FatherName
      PAN
      AADHAR
      State
      City
      Street1
      Street2
      PostCode
      addressCountry
      addressBuildingNumber
      addressHouse
      addressLandmark
      DateOfBirth
      owner
      language
      lendingHistory
      newWithdrawalLimit
      isOnProbation
      MatchRateDoc_1
      MatchRateDoc_2
      WorkedDaysInMonth
      WorkedDaysUpdatedAt
      WorkedHoursInMonth
      WorkedHoursUpdatedAt
      Department
      CountryCode
      GrossSalary
      DeductionAmount
      DeductionAmountPercent
      EMI
      enrolledBoolean
      managerId
      managerEmail
      userImage
      externalSource
      useBiometrics
      aadhaarFrontReadByOCR
      aadhaarBackReadByOCR
      panReadByOCR
      aadhaarPassedAuthCheck
      aadhaarPassedOTPCheck
      faceIDPasssed
      panCheckPassed
      threeWayCheckPassed
      pushNotifications {
        nextToken
      }
      communicationPreferences {
        id
        OrgId
        allowSaathiMarketing
        allowPartnerMarketing
        allowPushNotifications
        allowSMSNotification
        allowEmailNotifications
        allowVerificationBySMS
        allowVerificationByEmail
        createdAt
        updatedAt
        tenant
        owner
      }
      empCurrentCycleCalcs {
        empId
        owner
        totalEarnedSalary
        spentEarnedSalary
        quantityOfWithdrawalsTaken
        totalValueOfAutoApprovals
        tenant
        createdAt
        updatedAt
      }
      tenant
      snplBankCustomerId
      snplBankSavingsAccountNumber
      snplBankSavingsIFSCCode
      snplBankSavingsBranch
      snplBankKYCProgress
      SavingsTermsAndConditionsTS
      SavingsTermsAndConditionsAcceptance
      SavingsTermsAndConditionsIP
      cashfreeBeneficiaryId
      cashfreeVendorId
      cashfreeVendorStatus
      cashfreeSettlementCycleId
      cashfreeVendorTrackData
      MaritalStatus
      ProfilePictureURL
      registeredInApp
      registeredInAppDTS
      nationality
      PEPBool
      goals {
        nextToken
      }
      FCMRegToken
      snplBankAccountTypeCode
      snplBankAccountTypeDisplayName
      snplDebitCardName
      snplBankServices
      snplDeclarations
      snplNomineeRequiredBool
      snplBankName
      snplSavingsLimit
      snplBankAccountDateOpenedDTS
      snplAverageBalance
      economicCategory
      educationalQualification
      occupation
      annualIncome
      mothersMaidenName
      communicationAddress
      isFoundersClubMember
      confirmationCode
      isEmailVerified
      dataConsent
      CustomerSavingsAccount {
        userId
        TotalDailyAccruedBalance
        TotalAvailableBalance
        TotalGoalBalance
        FlexPayBalance
        AvailableToSpendBalance
        AvailableToSpendAccruedBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        linkedGoalsArray
        type
        savingsAccountStartDate
        createdAt
        updatedAt
      }
      usedReferralCode
      publicUserId
      referralCode
      saathiTokensBalance
      saathiTokensPendingBalance
      referralCount
      Referral {
        userId
        referralCode
        maxReferrals
        referralCount
        userReferralOffer
        userReferralOfferType
        refereeOffer
        refereeOfferType
        isEnabled
        referred
        createdAt
        updatedAt
      }
      isSignzyCallback
      signzyToken
      signzyCallbackData
      userType
      vkycStatus
      vkycTrackingId
      intercomContactId
      createdAt
      updatedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserRewardDetails = /* GraphQL */ `
  query GetUserRewardDetails($userId: ID!) {
    getUserRewardDetails(userId: $userId) {
      userId
      userType
      referralDipositsCount
      firstGoalReward
      firstGoalWith5kReward
      firstGoalWith5kAnd3MReward
      firstDepositTo5kAnd3MGrpGoalReward
      needsFirstDepositTo5kGoalReward
      needsFirstDepositTo5kAnd3MGoalReward
      needsFirstDepositTo5kAnd3MGrpGoalReward
      firstGoal25PerCompletionReward
      firstGoal50PerCompletionReward
      firstGoal75PerCompletionReward
      firstGoal100PerCompletionReward
      firstDepositTo5kGoalReward
      firstDeposit
      firstDepositReward
      firstGoalDoneBuyReward
      firstGoalCompletion
      goalCompletionRewardCount
      goalCompletionAndPurchaseRewardCount
      referral5kGoalDepositCount
      createdAt
      updatedAt
    }
  }
`;
export const listUserRewardDetailss = /* GraphQL */ `
  query ListUserRewardDetailss(
    $userId: ID
    $filter: ModelUserRewardDetailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserRewardDetailss(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        userType
        referralDipositsCount
        firstGoalReward
        firstGoalWith5kReward
        firstGoalWith5kAnd3MReward
        firstDepositTo5kAnd3MGrpGoalReward
        needsFirstDepositTo5kGoalReward
        needsFirstDepositTo5kAnd3MGoalReward
        needsFirstDepositTo5kAnd3MGrpGoalReward
        firstGoal25PerCompletionReward
        firstGoal50PerCompletionReward
        firstGoal75PerCompletionReward
        firstGoal100PerCompletionReward
        firstDepositTo5kGoalReward
        firstDeposit
        firstDepositReward
        firstGoalDoneBuyReward
        firstGoalCompletion
        goalCompletionRewardCount
        goalCompletionAndPurchaseRewardCount
        referral5kGoalDepositCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSftpUpload = /* GraphQL */ `
  query GetUserSftpUpload($id: ID!) {
    getUserSftpUpload(id: $id) {
      id
      userId
      snplBankCustomerId
      signzyToken
      signzyVideoUrl
      needsUpload
      nextScheduledOn
      failedUploads
      retryAttempts
      createdAt
      updatedAt
    }
  }
`;
export const listUserSftpUploads = /* GraphQL */ `
  query ListUserSftpUploads(
    $filter: ModelUserSftpUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSftpUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        snplBankCustomerId
        signzyToken
        signzyVideoUrl
        needsUpload
        nextScheduledOn
        failedUploads
        retryAttempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBankAccount = /* GraphQL */ `
  query GetUserBankAccount($id: ID!) {
    getUserBankAccount(id: $id) {
      id
      userId
      accountNumber
      ifsc
      accountHolderName
      bankName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserBankAccounts = /* GraphQL */ `
  query ListUserBankAccounts(
    $filter: ModelUserBankAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBankAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        accountNumber
        ifsc
        accountHolderName
        bankName
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTransationService = /* GraphQL */ `
  query GetTransationService($id: ID!) {
    getTransationService(id: $id) {
      id
      createdAt
      UserId
      OrgId
      TxnRefNo
      AvailableLimit
      AdvanceAmount
      ConvineneceFee
      DeductionAmount
      FundTransferStatus
      RepaymentSatus
      ReasonDesc
      ApprovalStatus
      RejReason
      DeductionDate
      AmountReceivedUpdatedBy
      ApprovedBy
      ApprovedDateTime
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      lbLoanId
      lbUserId
      leaveTaken
      employeeConsent
      receivedByLendbox
      History
      amountChange
      ApprovalFlow
      amountRequested
      exception
      tenant
      owner
      updatedAt
    }
  }
`;
export const listTransationServices = /* GraphQL */ `
  query ListTransationServices(
    $filter: ModelTransationServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransationServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        UserId
        OrgId
        TxnRefNo
        AvailableLimit
        AdvanceAmount
        ConvineneceFee
        DeductionAmount
        FundTransferStatus
        RepaymentSatus
        ReasonDesc
        ApprovalStatus
        RejReason
        DeductionDate
        AmountReceivedUpdatedBy
        ApprovedBy
        ApprovedDateTime
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        lbLoanId
        lbUserId
        leaveTaken
        employeeConsent
        receivedByLendbox
        History
        amountChange
        ApprovalFlow
        amountRequested
        exception
        tenant
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPushNotification = /* GraphQL */ `
  query GetPushNotification($id: ID!) {
    getPushNotification(id: $id) {
      id
      UsrId
      createdAt
      UsrMobileNo
      UsrEmail
      NotDeviceID
      NotStatus
      NotType
      NotSubType
      NotSentDT
      NotSentDTS
      Priority
      Title
      Subtitle
      MainText
      TxtField1
      TxtField2
      TxtField3
      AppAction
      CreatedAt
      ModifiedAt
      Checked
      tenant
      updatedAt
    }
  }
`;
export const listPushNotifications = /* GraphQL */ `
  query ListPushNotifications(
    $filter: ModelPushNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPushNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UsrId
        createdAt
        UsrMobileNo
        UsrEmail
        NotDeviceID
        NotStatus
        NotType
        NotSubType
        NotSentDT
        NotSentDTS
        Priority
        Title
        Subtitle
        MainText
        TxtField1
        TxtField2
        TxtField3
        AppAction
        CreatedAt
        ModifiedAt
        Checked
        tenant
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRepaymentDetails = /* GraphQL */ `
  query GetRepaymentDetails($id: ID!) {
    getRepaymentDetails(id: $id) {
      id
      OrgId
      TxnRefNo
      TotAdvanceAmount
      TotConvenienceFee
      TotDeductionAmount
      RepaymentSatus
      DeductionDate
      PayTransferDate
      MarkedPaidBy
      TxtField1
      TxtField2
      TxtField3
      CreatedAt
      ModifiedAt
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listRepaymentDetailss = /* GraphQL */ `
  query ListRepaymentDetailss(
    $filter: ModelRepaymentDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepaymentDetailss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        TxnRefNo
        TotAdvanceAmount
        TotConvenienceFee
        TotDeductionAmount
        RepaymentSatus
        DeductionDate
        PayTransferDate
        MarkedPaidBy
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployeeProfileAudit = /* GraphQL */ `
  query GetEmployeeProfileAudit($id: ID!) {
    getEmployeeProfileAudit(id: $id) {
      id
      OrgId
      EmpId
      DetailsUpdatedAt
      DetailsUpdatedBy
      ListOfDetailsChanged
      ApprovalStatus
      ApprovedAt
      ApprovedBy
      RejectedAt
      RejectedBy
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listEmployeeProfileAudits = /* GraphQL */ `
  query ListEmployeeProfileAudits(
    $filter: ModelEmployeeProfileAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeProfileAudits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        EmpId
        DetailsUpdatedAt
        DetailsUpdatedBy
        ListOfDetailsChanged
        ApprovalStatus
        ApprovedAt
        ApprovedBy
        RejectedAt
        RejectedBy
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployeeDetailsUpdateTask = /* GraphQL */ `
  query GetEmployeeDetailsUpdateTask($id: ID!) {
    getEmployeeDetailsUpdateTask(id: $id) {
      id
      GUID
      OrgId
      EmpId
      RequestedAt
      RequestedBy
      ListOfDetailsToBeChanged
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listEmployeeDetailsUpdateTasks = /* GraphQL */ `
  query ListEmployeeDetailsUpdateTasks(
    $filter: ModelEmployeeDetailsUpdateTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeDetailsUpdateTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        GUID
        OrgId
        EmpId
        RequestedAt
        RequestedBy
        ListOfDetailsToBeChanged
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUploadFileAudit = /* GraphQL */ `
  query GetUploadFileAudit($id: ID!) {
    getUploadFileAudit(id: $id) {
      id
      OrgId
      StoredFileName
      StoredFileSuffix
      DisplayFileName
      UploadedAt
      UploadedBy
      UploadDescription
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listUploadFileAudits = /* GraphQL */ `
  query ListUploadFileAudits(
    $filter: ModelUploadFileAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploadFileAudits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        StoredFileName
        StoredFileSuffix
        DisplayFileName
        UploadedAt
        UploadedBy
        UploadDescription
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrgCycleDates = /* GraphQL */ `
  query GetOrgCycleDates($id: ID!) {
    getOrgCycleDates(id: $id) {
      id
      OrgId
      MonthIndex
      CycleStartDate
      CycleEndDate
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listOrgCycleDatess = /* GraphQL */ `
  query ListOrgCycleDatess(
    $filter: ModelOrgCycleDatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgCycleDatess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OrgId
        MonthIndex
        CycleStartDate
        CycleEndDate
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGrowQuestionnaireResults = /* GraphQL */ `
  query GetGrowQuestionnaireResults($EmpId: String!, $createdAt: AWSDateTime!) {
    getGrowQuestionnaireResults(EmpId: $EmpId, createdAt: $createdAt) {
      id
      OrgId
      createdAt
      EmpId
      monthlyFamilyIncome
      monthlyFamilyExpenses
      cashShortfallAmount
      emergencyFundValue
      monthlyFamilyEMI
      monthlyExpensesAsPercentageOfIncome
      cashOutflowRatio
      monthlyCashShortfall
      emergencyFundNumberMonths
      monthlySavingsAsPercentageOfIncome
      debtToIncomeRatio
      cibilScore
      totalHealthInsurance
      totalLifeInsurance
      healthInsuranceScore
      lifeInsuranceScore
      spendScore
      saveScore
      borrowScore
      protectScore
      financialFitnessScore
      financialStressLevel
      tenant
      updatedAt
    }
  }
`;
export const listGrowQuestionnaireResultss = /* GraphQL */ `
  query ListGrowQuestionnaireResultss(
    $EmpId: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelgrowQuestionnaireResultsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGrowQuestionnaireResultss(
      EmpId: $EmpId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        OrgId
        createdAt
        EmpId
        monthlyFamilyIncome
        monthlyFamilyExpenses
        cashShortfallAmount
        emergencyFundValue
        monthlyFamilyEMI
        monthlyExpensesAsPercentageOfIncome
        cashOutflowRatio
        monthlyCashShortfall
        emergencyFundNumberMonths
        monthlySavingsAsPercentageOfIncome
        debtToIncomeRatio
        cibilScore
        totalHealthInsurance
        totalLifeInsurance
        healthInsuranceScore
        lifeInsuranceScore
        spendScore
        saveScore
        borrowScore
        protectScore
        financialFitnessScore
        financialStressLevel
        tenant
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommunicationPreferences = /* GraphQL */ `
  query GetCommunicationPreferences($id: ID!) {
    getCommunicationPreferences(id: $id) {
      id
      OrgId
      allowSaathiMarketing
      allowPartnerMarketing
      allowPushNotifications
      allowSMSNotification
      allowEmailNotifications
      allowVerificationBySMS
      allowVerificationByEmail
      createdAt
      updatedAt
      tenant
      owner
    }
  }
`;
export const listCommunicationPreferencess = /* GraphQL */ `
  query ListCommunicationPreferencess(
    $filter: ModelCommunicationPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunicationPreferencess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        allowSaathiMarketing
        allowPartnerMarketing
        allowPushNotifications
        allowSMSNotification
        allowEmailNotifications
        allowVerificationBySMS
        allowVerificationByEmail
        createdAt
        updatedAt
        tenant
        owner
      }
      nextToken
    }
  }
`;
export const getMonthlyReportMetrics = /* GraphQL */ `
  query GetMonthlyReportMetrics($id: ID!) {
    getMonthlyReportMetrics(id: $id) {
      id
      OrgId
      MonthStart
      MonthEnd
      employeesQuantity
      earnActiveUsersQuantity
      earnTotalAdvancesTaken
      earnAverageAdvanceSize
      earnTypicalUseCasesQuantities
      benefitActiveUsersQuantity
      usersTakingGrowQuestionnaireQuantity
      growAverageScores
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listMonthlyReportMetricss = /* GraphQL */ `
  query ListMonthlyReportMetricss(
    $filter: ModelmonthlyReportMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonthlyReportMetricss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        MonthStart
        MonthEnd
        employeesQuantity
        earnActiveUsersQuantity
        earnTotalAdvancesTaken
        earnAverageAdvanceSize
        earnTypicalUseCasesQuantities
        benefitActiveUsersQuantity
        usersTakingGrowQuestionnaireQuantity
        growAverageScores
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmpCurrentCycleCalcs = /* GraphQL */ `
  query GetEmpCurrentCycleCalcs($empId: ID!) {
    getEmpCurrentCycleCalcs(empId: $empId) {
      empId
      owner
      totalEarnedSalary
      spentEarnedSalary
      quantityOfWithdrawalsTaken
      totalValueOfAutoApprovals
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listEmpCurrentCycleCalcss = /* GraphQL */ `
  query ListEmpCurrentCycleCalcss(
    $empId: ID
    $filter: ModelempCurrentCycleCalcsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmpCurrentCycleCalcss(
      empId: $empId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        empId
        owner
        totalEarnedSalary
        spentEarnedSalary
        quantityOfWithdrawalsTaken
        totalValueOfAutoApprovals
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrgCurrentCycleCalcs = /* GraphQL */ `
  query GetOrgCurrentCycleCalcs($orgId: ID!) {
    getOrgCurrentCycleCalcs(orgId: $orgId) {
      orgId
      discount
      withdrawalPeriodStart
      withdrawalPeriodEnd
      payDate
      potentialBlackoutPercentage
      isWithdrawalPeriodOpen
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listOrgCurrentCycleCalcss = /* GraphQL */ `
  query ListOrgCurrentCycleCalcss(
    $orgId: ID
    $filter: ModelorgCurrentCycleCalcsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrgCurrentCycleCalcss(
      orgId: $orgId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orgId
        discount
        withdrawalPeriodStart
        withdrawalPeriodEnd
        payDate
        potentialBlackoutPercentage
        isWithdrawalPeriodOpen
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOTP = /* GraphQL */ `
  query GetOTP($id: ID!) {
    getOTP(id: $id) {
      id
      token
      createdAt
      updatedAt
    }
  }
`;
export const listOTPs = /* GraphQL */ `
  query ListOTPs(
    $filter: ModelOTPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOTPs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStagingTransactions = /* GraphQL */ `
  query GetStagingTransactions($id: ID!) {
    getStagingTransactions(id: $id) {
      id
      token
      managerId
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const listStagingTransactionss = /* GraphQL */ `
  query ListStagingTransactionss(
    $filter: ModelstagingTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStagingTransactionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        managerId
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticles = /* GraphQL */ `
  query GetArticles($id: ID!) {
    getArticles(id: $id) {
      id
      articleUrl
      apiUrl
      articleTitle
      shortTitle
      imageUrl
      first80Characters
      articleContent
      articleType
      slug
      createdAt
      updatedAt
    }
  }
`;
export const listArticless = /* GraphQL */ `
  query ListArticless(
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        articleUrl
        apiUrl
        articleTitle
        shortTitle
        imageUrl
        first80Characters
        articleContent
        articleType
        slug
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      userId
      shareCode
      voucherId
      name
      image
      goal_type
      basket_id
      availableBalanceToUse
      totalGoalAmount
      netGoalAmount
      goalInterestAmount
      goalRewardType
      goalRewardAmount
      instalmentAmount
      currency
      savedAmount
      length
      lengthDurationType
      maxInstalmentAmount
      paymentDate
      bankInterestRatePercent
      bankInterestRateAmount
      savingsProductType
      userSavingMotivation
      isShared
      archivedSharedUsersId
      sharedUsersId
      sharedGoalDetails
      tenant
      cashfreeSubReferenceId
      cashfreeSubscriptionStatus
      goalStatus
      firstChargeDate
      goalEndDate
      maxGoalEndDate
      DailyAccruedBalance
      AvailableDisplayBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      goalChangeAuditArray
      linkedProductType
      updatedAt
      isArchived
      firstChargeAmount
      Currency
      Progress
      isPublic
      publicSharedData
      goalChatId
      publicGoalId
      publicUserId
      goalSource
      sharingIncludeAmount
      fundTransferredToOwner
      noOfMembers
      categoryId
      categoryName
      products {
        nextToken
      }
      subscriptions {
        nextToken
      }
      Voucher {
        id
        merchantID
        affiliateID
        userID
        productCategory
        productID
        voucherCode
        voucherType
        voucherDescription
        voucherImageURL
        voucherOfferAmount
        voucherOfferType
        emailRequiredBool
        mobileRequiredBool
        voucherUsedBool
        voucherUsageCount
        tenant
        createdAt
        updatedAt
      }
      createdAt
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        shareCode
        voucherId
        name
        image
        goal_type
        basket_id
        availableBalanceToUse
        totalGoalAmount
        netGoalAmount
        goalInterestAmount
        goalRewardType
        goalRewardAmount
        instalmentAmount
        currency
        savedAmount
        length
        lengthDurationType
        maxInstalmentAmount
        paymentDate
        bankInterestRatePercent
        bankInterestRateAmount
        savingsProductType
        userSavingMotivation
        isShared
        archivedSharedUsersId
        sharedUsersId
        sharedGoalDetails
        tenant
        cashfreeSubReferenceId
        cashfreeSubscriptionStatus
        goalStatus
        firstChargeDate
        goalEndDate
        maxGoalEndDate
        DailyAccruedBalance
        AvailableDisplayBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        goalChangeAuditArray
        linkedProductType
        updatedAt
        isArchived
        firstChargeAmount
        Currency
        Progress
        isPublic
        publicSharedData
        goalChatId
        publicGoalId
        publicUserId
        goalSource
        sharingIncludeAmount
        fundTransferredToOwner
        noOfMembers
        categoryId
        categoryName
        createdAt
      }
      nextToken
    }
  }
`;
export const getSavingProduct = /* GraphQL */ `
  query GetSavingProduct($id: ID!) {
    getSavingProduct(id: $id) {
      id
      goalId
      merchantId
      merchantProductId
      rewardType
      offer
      offerType
      logoUrl
      productUrl
      productImageURL
      productName
      productDescription
      productPrice
      deliveryFee
      packingFee
      totalPrice
      itemId
      tenant
      trackingLink
      isMerchantReportCal
      hasVoucher
      voucherCode
      voucherAmount
      voucherAmountType
      Merchant {
        id
        platformMerchantId
        platform
        cashfreeVendorId
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      userId
    }
  }
`;
export const listSavingProducts = /* GraphQL */ `
  query ListSavingProducts(
    $filter: ModelSavingProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavingProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        goalId
        merchantId
        merchantProductId
        rewardType
        offer
        offerType
        logoUrl
        productUrl
        productImageURL
        productName
        productDescription
        productPrice
        deliveryFee
        packingFee
        totalPrice
        itemId
        tenant
        trackingLink
        isMerchantReportCal
        hasVoucher
        voucherCode
        voucherAmount
        voucherAmountType
        createdAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const getMerchantProduct = /* GraphQL */ `
  query GetMerchantProduct($merchantID: ID!, $productID: String!) {
    getMerchantProduct(merchantID: $merchantID, productID: $productID) {
      merchantID
      productID
      productName
      merchantName
      rewardType
      productDescription
      productParameters
      price
      deliveryPackingChange
      deliveryFee
      packingFee
      productOffer
      productOfferType
      cashkaroOfferList
      productTags
      productLinkURL
      productImageURL
      relatedArticles
      featured
      offerDescription
      tenant
      platform
      isExpired
      status
      trackingLink
      merchantProductType
      merchantProductBackgroundURL
      hasVoucher
      voucherCode
      voucherAmount
      voucherAmountType
      Merchant {
        id
        platformMerchantId
        platform
        cashfreeVendorId
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantProducts = /* GraphQL */ `
  query ListMerchantProducts(
    $merchantID: ID
    $productID: ModelStringKeyConditionInput
    $filter: ModelMerchantProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMerchantProducts(
      merchantID: $merchantID
      productID: $productID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        merchantID
        productID
        productName
        merchantName
        rewardType
        productDescription
        productParameters
        price
        deliveryPackingChange
        deliveryFee
        packingFee
        productOffer
        productOfferType
        cashkaroOfferList
        productTags
        productLinkURL
        productImageURL
        relatedArticles
        featured
        offerDescription
        tenant
        platform
        isExpired
        status
        trackingLink
        merchantProductType
        merchantProductBackgroundURL
        hasVoucher
        voucherCode
        voucherAmount
        voucherAmountType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantProductCategory = /* GraphQL */ `
  query GetMerchantProductCategory($id: ID!) {
    getMerchantProductCategory(id: $id) {
      id
      cashkaroCategoryId
      categoryName
      categoryImage
      merchantProductCount
      backgroundColourHex
      status
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantProductCategorys = /* GraphQL */ `
  query ListMerchantProductCategorys(
    $filter: ModelMerchantProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchantProductCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cashkaroCategoryId
        categoryName
        categoryImage
        merchantProductCount
        backgroundColourHex
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucher = /* GraphQL */ `
  query GetVoucher($id: ID!) {
    getVoucher(id: $id) {
      id
      merchantID
      affiliateID
      userID
      productCategory
      productID
      voucherCode
      voucherType
      voucherDescription
      voucherImageURL
      voucherOfferAmount
      voucherOfferType
      emailRequiredBool
      mobileRequiredBool
      voucherUsedBool
      voucherUsageCount
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listVouchers = /* GraphQL */ `
  query ListVouchers(
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchantID
        affiliateID
        userID
        productCategory
        productID
        voucherCode
        voucherType
        voucherDescription
        voucherImageURL
        voucherOfferAmount
        voucherOfferType
        emailRequiredBool
        mobileRequiredBool
        voucherUsedBool
        voucherUsageCount
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentSubscription = /* GraphQL */ `
  query GetPaymentSubscription($id: ID!) {
    getPaymentSubscription(id: $id) {
      id
      goalId
      userId
      type
      cfsubRefId
      planInfo
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      amount
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      nextChargeRaisedDate
      nextChargeScheduledDate
      createdAt
      vendorId
      retryAttempts
      updatedAt
    }
  }
`;
export const listPaymentSubscriptions = /* GraphQL */ `
  query ListPaymentSubscriptions(
    $filter: ModelPaymentSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        type
        cfsubRefId
        planInfo
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        amount
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        nextChargeRaisedDate
        nextChargeScheduledDate
        createdAt
        vendorId
        retryAttempts
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCashfreeSubscriptions = /* GraphQL */ `
  query GetCashfreeSubscriptions($id: ID!) {
    getCashfreeSubscriptions(id: $id) {
      id
      goalId
      userId
      subReferenceId
      planId
      authLink
      authStatus
      status
      firstChargeDate
      expiresOn
      tenant
      payments
      lastOrderId
      needsPayout
      accountHolder
      accountNumber
      bankId
      authMode
      accountType
      ifsc
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCashfreeSubscriptionss = /* GraphQL */ `
  query ListCashfreeSubscriptionss(
    $filter: ModelCashfreeSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashfreeSubscriptionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        subReferenceId
        planId
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCashfreeOrder = /* GraphQL */ `
  query GetCashfreeOrder($id: ID!) {
    getCashfreeOrder(id: $id) {
      id
      userId
      goalId
      cfOrderId
      amount
      status
      token
      note
      expireTime
      paymentLink
      payment
      tenant
      needsPayout
      vendorId
      encouragementUser
      createdAt
      transfers {
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCashfreeOrders = /* GraphQL */ `
  query ListCashfreeOrders(
    $filter: ModelCashfreeOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashfreeOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        goalId
        cfOrderId
        amount
        status
        token
        note
        expireTime
        paymentLink
        payment
        tenant
        needsPayout
        vendorId
        encouragementUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCashfreeTransfer = /* GraphQL */ `
  query GetCashfreeTransfer($id: ID!) {
    getCashfreeTransfer(id: $id) {
      id
      goalId
      userId
      transferId
      beneId
      subReferenceId
      orderId
      referenceId
      utr
      status
      amount
      tenant
      needsRetry
      retryAttempts
      nextScheduledOn
      transferType
      failedTransfers
      createdAt
      updatedAt
    }
  }
`;
export const listCashfreeTransfers = /* GraphQL */ `
  query ListCashfreeTransfers(
    $filter: ModelCashfreeTransferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashfreeTransfers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        transferId
        beneId
        subReferenceId
        orderId
        referenceId
        utr
        status
        amount
        tenant
        needsRetry
        retryAttempts
        nextScheduledOn
        transferType
        failedTransfers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCashfreeSplitOrder = /* GraphQL */ `
  query GetCashfreeSplitOrder($id: ID!) {
    getCashfreeSplitOrder(id: $id) {
      id
      userId
      goalId
      subReferenceId
      subscriptionId
      vendorId
      vendorSettlementId
      status
      amount
      utr
      createdAt
      updatedAt
    }
  }
`;
export const listCashfreeSplitOrders = /* GraphQL */ `
  query ListCashfreeSplitOrders(
    $filter: ModelCashfreeSplitOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashfreeSplitOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        goalId
        subReferenceId
        subscriptionId
        vendorId
        vendorSettlementId
        status
        amount
        utr
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCashfreeSettlement = /* GraphQL */ `
  query GetCashfreeSettlement($id: ID!) {
    getCashfreeSettlement(id: $id) {
      id
      type
      processOn
      utr
      createdAt
      updatedAt
    }
  }
`;
export const listCashfreeSettlements = /* GraphQL */ `
  query ListCashfreeSettlements(
    $filter: ModelCashfreeSettlementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashfreeSettlements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        processOn
        utr
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchant = /* GraphQL */ `
  query GetMerchant($id: ID!) {
    getMerchant(id: $id) {
      id
      platformMerchantId
      platform
      cashfreeVendorId
      merchant_name
      merchant_logo_url
      merchant_logo_Base64
      merchant_checkout_url
      merchant_add_id_to_checkout_url
      merchantDescription
      merchantOfferTitle
      merchantOfferType
      merchantOfferAmount
      afiliateUrl
      merchantCategory
      merchantWebsiteURL
      tenant
      shopifyStoreID
      shopifyStoreSaathiSNPLTCsAgreed
      SNPLAllowedBySaathi
      merchantType
      backgroundCircleHexColour
      merchantBackgroundImageURL
      isReviewed
      isMaxOffer
      createdAt
      updatedAt
    }
  }
`;
export const listMerchants = /* GraphQL */ `
  query ListMerchants(
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        platformMerchantId
        platform
        cashfreeVendorId
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantAudit = /* GraphQL */ `
  query GetMerchantAudit($id: ID!) {
    getMerchantAudit(id: $id) {
      id
      merchantId
      approver
      originalDetails
      newDetails
      objectType
      approvalStatus
      approvalStatusMessage
      approverName
      approvedDate
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantAudits = /* GraphQL */ `
  query ListMerchantAudits(
    $filter: ModelMerchantAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchantAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchantId
        approver
        originalDetails
        newDetails
        objectType
        approvalStatus
        approvalStatusMessage
        approverName
        approvedDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantNotification = /* GraphQL */ `
  query GetMerchantNotification($id: ID!) {
    getMerchantNotification(id: $id) {
      id
      merchantId
      MerMobileNo
      MerEmail
      NotDeviceID
      NotStatus
      NotType
      NotSubType
      NotSentDT
      NotSentDTS
      Priority
      Title
      Subtitle
      MainText
      TxtField1
      TxtField2
      TxtField3
      AppAction
      CreatedAt
      UpdatedAt
      Checked
      tenant
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantNotifications = /* GraphQL */ `
  query ListMerchantNotifications(
    $filter: ModelMerchantNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchantNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        MerMobileNo
        MerEmail
        NotDeviceID
        NotStatus
        NotType
        NotSubType
        NotSentDT
        NotSentDTS
        Priority
        Title
        Subtitle
        MainText
        TxtField1
        TxtField2
        TxtField3
        AppAction
        CreatedAt
        UpdatedAt
        Checked
        tenant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      invoiceId
      createdAt
      dueBy
      invoiceValue
      invoiceStatus
      issuedAt
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelinvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invoiceId
        createdAt
        dueBy
        invoiceValue
        invoiceStatus
        issuedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantCardTransaction = /* GraphQL */ `
  query GetMerchantCardTransaction($id: ID!) {
    getMerchantCardTransaction(id: $id) {
      id
      merchantUserId
      merchantId
      transactionDate
      transactionAmount
      transactionReference
      transactionNarrative
      cardIdentifier
      transactionStatus
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantCardTransactions = /* GraphQL */ `
  query ListMerchantCardTransactions(
    $filter: ModelmerchantCardTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchantCardTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantUserId
        merchantId
        transactionDate
        transactionAmount
        transactionReference
        transactionNarrative
        cardIdentifier
        transactionStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantGoal = /* GraphQL */ `
  query GetMerchantGoal($id: ID!) {
    getMerchantGoal(id: $id) {
      id
      merchantId
      merchantUserId
      goalName
      goalCreatedDate
      goalDuration
      goalEndDate
      goalAmount
      goalStatus
      goalComplete
      subscriptionStarted
      subscriptionValue
      userGender
      userAge
      userCity
      merchantReward
      merchantRewardType
      potentialGoalRewardAmount
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantGoals = /* GraphQL */ `
  query ListMerchantGoals(
    $filter: ModelmerchantGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchantGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        merchantId
        merchantUserId
        goalName
        goalCreatedDate
        goalDuration
        goalEndDate
        goalAmount
        goalStatus
        goalComplete
        subscriptionStarted
        subscriptionValue
        userGender
        userAge
        userCity
        merchantReward
        merchantRewardType
        potentialGoalRewardAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSuryoday = /* GraphQL */ `
  query GetSuryoday($id: ID!) {
    getSuryoday(id: $id) {
      id
      currentStan
      minStan
      maxStan
      auaLicenseKey
      kuaLicenseKey
      savingsAccountProductCode
      savingsAccountProductName
      escrowAccountNumber
      escrowAccountIFSCCode
      escrowAccountBranchCode
      escrowAccountName
      escrowAccountMobileNo
      escrowAccountEmail
      escrowAccountAddress
      cashfreeBeneficiaryId
      aadhaarPanConstant
      aadhaarProcCodeOtp
      aadhaarProcCodeAuth
      aadhaarMcc
      aadhaarPosEnteryMode
      aadhaarPosCode
      aadhaarAcqId
      aadhaarCaTidOtp
      aadhaarCaTidAuth
      aadhaarCaId
      aadhaarCaTa
      aadhaarAc
      aadhaarSa
      aadhaarAPIVerion
      aadhaarEncryptionKey
      currentAccountCustomerId
      currentAccountNumber
      currentAccountIFSCCode
      currentAccountBranchCode
      currentAccountName
      currentAccountBalance
      createdAt
      updatedAt
    }
  }
`;
export const listSuryodays = /* GraphQL */ `
  query ListSuryodays(
    $filter: ModelSuryodayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuryodays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currentStan
        minStan
        maxStan
        auaLicenseKey
        kuaLicenseKey
        savingsAccountProductCode
        savingsAccountProductName
        escrowAccountNumber
        escrowAccountIFSCCode
        escrowAccountBranchCode
        escrowAccountName
        escrowAccountMobileNo
        escrowAccountEmail
        escrowAccountAddress
        cashfreeBeneficiaryId
        aadhaarPanConstant
        aadhaarProcCodeOtp
        aadhaarProcCodeAuth
        aadhaarMcc
        aadhaarPosEnteryMode
        aadhaarPosCode
        aadhaarAcqId
        aadhaarCaTidOtp
        aadhaarCaTidAuth
        aadhaarCaId
        aadhaarCaTa
        aadhaarAc
        aadhaarSa
        aadhaarAPIVerion
        aadhaarEncryptionKey
        currentAccountCustomerId
        currentAccountNumber
        currentAccountIFSCCode
        currentAccountBranchCode
        currentAccountName
        currentAccountBalance
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMasterData = /* GraphQL */ `
  query GetMasterData($id: ID!) {
    getMasterData(id: $id) {
      id
      masterKeyword
      masterValue
      createdAt
      updatedAt
    }
  }
`;
export const listMasterDatas = /* GraphQL */ `
  query ListMasterDatas(
    $filter: ModelmasterDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMasterDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        masterKeyword
        masterValue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWaitList = /* GraphQL */ `
  query GetWaitList($id: ID!) {
    getWaitList(id: $id) {
      id
      fullName
      eMail
      mobileCountryCode
      mobileNumber
      privacyTermsAccepted
      countryOfResidence
      isExported
      createdAt
      updatedAt
    }
  }
`;
export const listWaitLists = /* GraphQL */ `
  query ListWaitLists(
    $filter: ModelWaitListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaitLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        eMail
        mobileCountryCode
        mobileNumber
        privacyTermsAccepted
        countryOfResidence
        isExported
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerSavingsAccount = /* GraphQL */ `
  query GetCustomerSavingsAccount($userId: ID!) {
    getCustomerSavingsAccount(userId: $userId) {
      userId
      TotalDailyAccruedBalance
      TotalAvailableBalance
      TotalGoalBalance
      FlexPayBalance
      AvailableToSpendBalance
      AvailableToSpendAccruedBalance
      TotalInterestEarnedOnDailyAccruedBalance
      TotalInterestEarnedOnAvailableToSpendBalance
      linkedGoalsArray
      type
      savingsAccountStartDate
      Employee {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerSavingsAccounts = /* GraphQL */ `
  query ListCustomerSavingsAccounts(
    $userId: ID
    $filter: ModelCustomerSavingsAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerSavingsAccounts(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        TotalDailyAccruedBalance
        TotalAvailableBalance
        TotalGoalBalance
        FlexPayBalance
        AvailableToSpendBalance
        AvailableToSpendAccruedBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        linkedGoalsArray
        type
        savingsAccountStartDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppConfiguration = /* GraphQL */ `
  query GetAppConfiguration($id: ID!) {
    getAppConfiguration(id: $id) {
      id
      bankInterestRate
      androidVersion
      iosVersion
      minAndroidVersion
      minIosVersion
      playStoreUrl
      appStoreUrl
      googlePlayHashKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAppConfigurations = /* GraphQL */ `
  query ListAppConfigurations(
    $filter: ModelAppConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bankInterestRate
        androidVersion
        iosVersion
        minAndroidVersion
        minIosVersion
        playStoreUrl
        appStoreUrl
        googlePlayHashKey
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCashkaroConfiguration = /* GraphQL */ `
  query GetCashkaroConfiguration($id: ID!) {
    getCashkaroConfiguration(id: $id) {
      id
      categoryList
      allowedMerchantList
      partnerId
      apiKey
      createdAt
      updatedAt
    }
  }
`;
export const listCashkaroConfigurations = /* GraphQL */ `
  query ListCashkaroConfigurations(
    $filter: ModelCashkaroConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashkaroConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryList
        allowedMerchantList
        partnerId
        apiKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCashkaroConversion = /* GraphQL */ `
  query GetCashkaroConversion($id: ID!) {
    getCashkaroConversion(id: $id) {
      id
      userId
      offerId
      goalId
      actionId
      userBankSavingsAccountNumber
      userBankSavingsAccountBranch
      status
      paymentStatus
      utr
      amount
      orderAmount
      needsPayout
      retryAttempts
      nextScheduledOn
      failedPayments
      createdAt
      updatedAt
    }
  }
`;
export const listCashkaroConversions = /* GraphQL */ `
  query ListCashkaroConversions(
    $filter: ModelCashkaroConversionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCashkaroConversions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        offerId
        goalId
        actionId
        userBankSavingsAccountNumber
        userBankSavingsAccountBranch
        status
        paymentStatus
        utr
        amount
        orderAmount
        needsPayout
        retryAttempts
        nextScheduledOn
        failedPayments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaathiConversion = /* GraphQL */ `
  query GetSaathiConversion($id: ID!) {
    getSaathiConversion(id: $id) {
      id
      type
      userId
      offerId
      goalId
      actionId
      status
      amount
      orderAmount
      createdAt
      updatedAt
    }
  }
`;
export const listSaathiConversions = /* GraphQL */ `
  query ListSaathiConversions(
    $filter: ModelSaathiConversionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaathiConversions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        userId
        offerId
        goalId
        actionId
        status
        amount
        orderAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaathiConfiguration = /* GraphQL */ `
  query GetSaathiConfiguration($id: ID!) {
    getSaathiConfiguration(id: $id) {
      id
      supportEmail
      createdAt
      updatedAt
    }
  }
`;
export const listSaathiConfigurations = /* GraphQL */ `
  query ListSaathiConfigurations(
    $filter: ModelSaathiConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaathiConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        supportEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferral = /* GraphQL */ `
  query GetReferral($userId: ID!) {
    getReferral(userId: $userId) {
      userId
      referralCode
      maxReferrals
      referralCount
      userReferralOffer
      userReferralOfferType
      refereeOffer
      refereeOfferType
      isEnabled
      referred
      createdAt
      updatedAt
    }
  }
`;
export const listReferrals = /* GraphQL */ `
  query ListReferrals(
    $userId: ID
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReferrals(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        referralCode
        maxReferrals
        referralCount
        userReferralOffer
        userReferralOfferType
        refereeOffer
        refereeOfferType
        isEnabled
        referred
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKycStatus = /* GraphQL */ `
  query GetKycStatus($userId: ID!) {
    getKycStatus(userId: $userId) {
      userId
      okycStatus
      okycFailureCount
      ckycStatus
      ckycFailureCount
      numberOfAttempts
      updatedAt
      createdAt
    }
  }
`;
export const listKycStatuss = /* GraphQL */ `
  query ListKycStatuss(
    $userId: ID
    $filter: ModelKycStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKycStatuss(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        okycStatus
        okycFailureCount
        ckycStatus
        ckycFailureCount
        numberOfAttempts
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getAdminAnalyticalCount = /* GraphQL */ `
  query GetAdminAnalyticalCount($id: ID!) {
    getAdminAnalyticalCount(id: $id) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      totalAccountValue
      totalSpendValue
      activeMerchants
      inactiveMerchants
      activeMerchantProd
      inactiveMerchantProd
      date
      createdAt
      updatedAt
    }
  }
`;
export const listAdminAnalyticalCounts = /* GraphQL */ `
  query ListAdminAnalyticalCounts(
    $filter: ModelAdminAnalyticalCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminAnalyticalCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        countUsr
        countGoal
        activeGoals
        cancelledGoals
        archievedGoals
        completedGoals
        goalSavings
        countVoucher
        countUniqueVoucher
        countSharedVoucher
        countUsedVoucher
        totalCashbackValue
        totalVoucherValue
        totalOfferValue
        totalAccountValue
        totalSpendValue
        activeMerchants
        inactiveMerchants
        activeMerchantProd
        inactiveMerchantProd
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantAnalyticalCount = /* GraphQL */ `
  query GetMerchantAnalyticalCount($id: ID!) {
    getMerchantAnalyticalCount(id: $id) {
      id
      countUsr
      countGoal
      activeGoals
      cancelledGoals
      archievedGoals
      completedGoals
      goalSavings
      countVoucher
      countUniqueVoucher
      countSharedVoucher
      countUsedVoucher
      totalCashbackValue
      totalVoucherValue
      totalOfferValue
      activeMerchants
      activeMerchantProd
      state
      date
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantAnalyticalCounts = /* GraphQL */ `
  query ListMerchantAnalyticalCounts(
    $filter: ModelMerchantAnalyticalCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchantAnalyticalCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        countUsr
        countGoal
        activeGoals
        cancelledGoals
        archievedGoals
        completedGoals
        goalSavings
        countVoucher
        countUniqueVoucher
        countSharedVoucher
        countUsedVoucher
        totalCashbackValue
        totalVoucherValue
        totalOfferValue
        activeMerchants
        activeMerchantProd
        state
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMoneyTracking = /* GraphQL */ `
  query GetMoneyTracking($id: ID!) {
    getMoneyTracking(id: $id) {
      id
      userId
      goalId
      trackingData
      completed
      createdAt
      updatedAt
    }
  }
`;
export const listMoneyTrackings = /* GraphQL */ `
  query ListMoneyTrackings(
    $filter: ModelMoneyTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoneyTrackings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        goalId
        trackingData
        completed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaathiTokenActivity = /* GraphQL */ `
  query GetSaathiTokenActivity($id: ID!) {
    getSaathiTokenActivity(id: $id) {
      id
      customerId
      goalId
      tokenEventId
      tokenEventText
      tokenEventTimestamp
      tokenType
      tokenValue
      tokenEventStatus
      linkedCustomerId
      createdAt
      updatedAt
      linkedCustomer {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSaathiTokenActivitys = /* GraphQL */ `
  query ListSaathiTokenActivitys(
    $filter: ModelSaathiTokenActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaathiTokenActivitys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        goalId
        tokenEventId
        tokenEventText
        tokenEventTimestamp
        tokenType
        tokenValue
        tokenEventStatus
        linkedCustomerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTokenTypes = /* GraphQL */ `
  query GetTokenTypes($tokenTypeId: ID!) {
    getTokenTypes(tokenTypeId: $tokenTypeId) {
      tokenTypeId
      tokenType
      tokenText
      tokenValue
      tokenIsLimited
      tokenUseLimitBeforeStopEarning
      createdAt
      updatedAt
    }
  }
`;
export const listTokenTypess = /* GraphQL */ `
  query ListTokenTypess(
    $tokenTypeId: ID
    $filter: ModelTokenTypesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTokenTypess(
      tokenTypeId: $tokenTypeId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tokenTypeId
        tokenType
        tokenText
        tokenValue
        tokenIsLimited
        tokenUseLimitBeforeStopEarning
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerTokenUse = /* GraphQL */ `
  query GetCustomerTokenUse($id: ID!) {
    getCustomerTokenUse(id: $id) {
      id
      customerId
      tokenTypeId
      tokenUseCount
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerTokenUses = /* GraphQL */ `
  query ListCustomerTokenUses(
    $filter: ModelCustomerTokenUseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerTokenUses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        tokenTypeId
        tokenUseCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orgNotificationsByOrgIdByCreatedAt = /* GraphQL */ `
  query OrgNotificationsByOrgIdByCreatedAt(
    $OrgId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrgNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgNotificationsByOrgIdByCreatedAt(
      OrgId: $OrgId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        createdAt
        Description
        DisplayTo
        ActionType
        Title
        MarkedReadBy
        TaskId
        TaskCompleted
        tenant
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeesByOrgId = /* GraphQL */ `
  query EmployeesByOrgId(
    $OrgId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeesByOrgId(
      OrgId: $OrgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeeByUsrId = /* GraphQL */ `
  query EmployeeByUsrId(
    $UsrEmpId: String
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeByUsrId(
      UsrEmpId: $UsrEmpId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeeByUsrEmail = /* GraphQL */ `
  query EmployeeByUsrEmail(
    $UsrEmail: String
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeByUsrEmail(
      UsrEmail: $UsrEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeeByUsrMobileNo = /* GraphQL */ `
  query EmployeeByUsrMobileNo(
    $UsrMobileNo: String
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeByUsrMobileNo(
      UsrMobileNo: $UsrMobileNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeeBySignzyToken = /* GraphQL */ `
  query EmployeeBySignzyToken(
    $signzyToken: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeBySignzyToken(
      signzyToken: $signzyToken
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const employeeByVkycTrackingId = /* GraphQL */ `
  query EmployeeByVkycTrackingId(
    $vkycTrackingId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeByVkycTrackingId(
      vkycTrackingId: $vkycTrackingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgId
        UsrEmpId
        merchantUserId
        UsrDesignation
        UsrGrade
        UsrJoiningDate
        UsrEmail
        UsrMobileNo
        UsrWithdrawalLimit
        UsrGivenName
        UsrMiddleName
        UsrFamilyName
        fathersFirstName
        fathersMiddleName
        fathersLastName
        spouseFirstName
        spouseMiddleName
        spouseLastName
        mothersFirstName
        mothersMiddleName
        mothersLastName
        UsrBankAccount
        UsrBankName
        UsrBankIFSCCode
        UsrBenefitStatus
        UsrInviteEmail
        UsrConsentAcceptance
        UsrConsentTS
        UsrTCAcceptance
        UsrTCAcceptanceTS
        TCAcceptanceIP
        ConsentAgreed
        ConsentTimeStamp
        ConsentIP
        UsrKYCStatus
        UsrRegJourneyStatus
        UsrFirstTimeLoginFlag
        UsrStatus
        UsrWithdrawalCycle
        ProfileLock
        NoOfAttempt
        CreatedBy
        ModifiedBy
        LastLoggedIn
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        Invited
        Currency
        LimitAsPercent
        Salary
        JoiningDate
        DeactivationReason
        DeactivationDate
        lbUserId
        lbRegistered
        lbSign
        lbLoanId
        lbLoanDate
        lbContractDate
        lbAgreementIP
        limitUpdateFlag
        hasUserCompletedGrowQuestionnaire
        Title
        Gender
        FatherName
        PAN
        AADHAR
        State
        City
        Street1
        Street2
        PostCode
        addressCountry
        addressBuildingNumber
        addressHouse
        addressLandmark
        DateOfBirth
        owner
        language
        lendingHistory
        newWithdrawalLimit
        isOnProbation
        MatchRateDoc_1
        MatchRateDoc_2
        WorkedDaysInMonth
        WorkedDaysUpdatedAt
        WorkedHoursInMonth
        WorkedHoursUpdatedAt
        Department
        CountryCode
        GrossSalary
        DeductionAmount
        DeductionAmountPercent
        EMI
        enrolledBoolean
        managerId
        managerEmail
        userImage
        externalSource
        useBiometrics
        aadhaarFrontReadByOCR
        aadhaarBackReadByOCR
        panReadByOCR
        aadhaarPassedAuthCheck
        aadhaarPassedOTPCheck
        faceIDPasssed
        panCheckPassed
        threeWayCheckPassed
        tenant
        snplBankCustomerId
        snplBankSavingsAccountNumber
        snplBankSavingsIFSCCode
        snplBankSavingsBranch
        snplBankKYCProgress
        SavingsTermsAndConditionsTS
        SavingsTermsAndConditionsAcceptance
        SavingsTermsAndConditionsIP
        cashfreeBeneficiaryId
        cashfreeVendorId
        cashfreeVendorStatus
        cashfreeSettlementCycleId
        cashfreeVendorTrackData
        MaritalStatus
        ProfilePictureURL
        registeredInApp
        registeredInAppDTS
        nationality
        PEPBool
        FCMRegToken
        snplBankAccountTypeCode
        snplBankAccountTypeDisplayName
        snplDebitCardName
        snplBankServices
        snplDeclarations
        snplNomineeRequiredBool
        snplBankName
        snplSavingsLimit
        snplBankAccountDateOpenedDTS
        snplAverageBalance
        economicCategory
        educationalQualification
        occupation
        annualIncome
        mothersMaidenName
        communicationAddress
        isFoundersClubMember
        confirmationCode
        isEmailVerified
        dataConsent
        usedReferralCode
        publicUserId
        referralCode
        saathiTokensBalance
        saathiTokensPendingBalance
        referralCount
        isSignzyCallback
        signzyToken
        signzyCallbackData
        userType
        vkycStatus
        vkycTrackingId
        intercomContactId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userBankAccountByUserId = /* GraphQL */ `
  query UserBankAccountByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserBankAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userBankAccountByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        accountNumber
        ifsc
        accountHolderName
        bankName
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const txnsByOrgIdByCreatedAt = /* GraphQL */ `
  query TxnsByOrgIdByCreatedAt(
    $OrgId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransationServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    txnsByOrgIdByCreatedAt(
      OrgId: $OrgId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        UserId
        OrgId
        TxnRefNo
        AvailableLimit
        AdvanceAmount
        ConvineneceFee
        DeductionAmount
        FundTransferStatus
        RepaymentSatus
        ReasonDesc
        ApprovalStatus
        RejReason
        DeductionDate
        AmountReceivedUpdatedBy
        ApprovedBy
        ApprovedDateTime
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        lbLoanId
        lbUserId
        leaveTaken
        employeeConsent
        receivedByLendbox
        History
        amountChange
        ApprovalFlow
        amountRequested
        exception
        tenant
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const txnsByUserIdByCreatedAt = /* GraphQL */ `
  query TxnsByUserIdByCreatedAt(
    $UserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransationServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    txnsByUserIdByCreatedAt(
      UserId: $UserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        UserId
        OrgId
        TxnRefNo
        AvailableLimit
        AdvanceAmount
        ConvineneceFee
        DeductionAmount
        FundTransferStatus
        RepaymentSatus
        ReasonDesc
        ApprovalStatus
        RejReason
        DeductionDate
        AmountReceivedUpdatedBy
        ApprovedBy
        ApprovedDateTime
        TxtField1
        TxtField2
        TxtField3
        CreatedAt
        ModifiedAt
        lbLoanId
        lbUserId
        leaveTaken
        employeeConsent
        receivedByLendbox
        History
        amountChange
        ApprovalFlow
        amountRequested
        exception
        tenant
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const pushNotsByUsrIdByCreatedAt = /* GraphQL */ `
  query PushNotsByUsrIdByCreatedAt(
    $UsrId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPushNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pushNotsByUsrIdByCreatedAt(
      UsrId: $UsrId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UsrId
        createdAt
        UsrMobileNo
        UsrEmail
        NotDeviceID
        NotStatus
        NotType
        NotSubType
        NotSentDT
        NotSentDTS
        Priority
        Title
        Subtitle
        MainText
        TxtField1
        TxtField2
        TxtField3
        AppAction
        CreatedAt
        ModifiedAt
        Checked
        tenant
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticleByTitle = /* GraphQL */ `
  query GetArticleByTitle(
    $articleTitle: String
    $sortDirection: ModelSortDirection
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getArticleByTitle(
      articleTitle: $articleTitle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleUrl
        apiUrl
        articleTitle
        shortTitle
        imageUrl
        first80Characters
        articleContent
        articleType
        slug
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const goalsByUserId = /* GraphQL */ `
  query GoalsByUserId(
    $userId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByUserId(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        shareCode
        voucherId
        name
        image
        goal_type
        basket_id
        availableBalanceToUse
        totalGoalAmount
        netGoalAmount
        goalInterestAmount
        goalRewardType
        goalRewardAmount
        instalmentAmount
        currency
        savedAmount
        length
        lengthDurationType
        maxInstalmentAmount
        paymentDate
        bankInterestRatePercent
        bankInterestRateAmount
        savingsProductType
        userSavingMotivation
        isShared
        archivedSharedUsersId
        sharedUsersId
        sharedGoalDetails
        tenant
        cashfreeSubReferenceId
        cashfreeSubscriptionStatus
        goalStatus
        firstChargeDate
        goalEndDate
        maxGoalEndDate
        DailyAccruedBalance
        AvailableDisplayBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        goalChangeAuditArray
        linkedProductType
        updatedAt
        isArchived
        firstChargeAmount
        Currency
        Progress
        isPublic
        publicSharedData
        goalChatId
        publicGoalId
        publicUserId
        goalSource
        sharingIncludeAmount
        fundTransferredToOwner
        noOfMembers
        categoryId
        categoryName
        createdAt
      }
      nextToken
    }
  }
`;
export const byPublicGoalId = /* GraphQL */ `
  query ByPublicGoalId(
    $publicGoalId: String
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPublicGoalId(
      publicGoalId: $publicGoalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        shareCode
        voucherId
        name
        image
        goal_type
        basket_id
        availableBalanceToUse
        totalGoalAmount
        netGoalAmount
        goalInterestAmount
        goalRewardType
        goalRewardAmount
        instalmentAmount
        currency
        savedAmount
        length
        lengthDurationType
        maxInstalmentAmount
        paymentDate
        bankInterestRatePercent
        bankInterestRateAmount
        savingsProductType
        userSavingMotivation
        isShared
        archivedSharedUsersId
        sharedUsersId
        sharedGoalDetails
        tenant
        cashfreeSubReferenceId
        cashfreeSubscriptionStatus
        goalStatus
        firstChargeDate
        goalEndDate
        maxGoalEndDate
        DailyAccruedBalance
        AvailableDisplayBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        goalChangeAuditArray
        linkedProductType
        updatedAt
        isArchived
        firstChargeAmount
        Currency
        Progress
        isPublic
        publicSharedData
        goalChatId
        publicGoalId
        publicUserId
        goalSource
        sharingIncludeAmount
        fundTransferredToOwner
        noOfMembers
        categoryId
        categoryName
        createdAt
      }
      nextToken
    }
  }
`;
export const byGoalEndDate = /* GraphQL */ `
  query ByGoalEndDate(
    $goalEndDate: AWSDate
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGoalEndDate(
      goalEndDate: $goalEndDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        shareCode
        voucherId
        name
        image
        goal_type
        basket_id
        availableBalanceToUse
        totalGoalAmount
        netGoalAmount
        goalInterestAmount
        goalRewardType
        goalRewardAmount
        instalmentAmount
        currency
        savedAmount
        length
        lengthDurationType
        maxInstalmentAmount
        paymentDate
        bankInterestRatePercent
        bankInterestRateAmount
        savingsProductType
        userSavingMotivation
        isShared
        archivedSharedUsersId
        sharedUsersId
        sharedGoalDetails
        tenant
        cashfreeSubReferenceId
        cashfreeSubscriptionStatus
        goalStatus
        firstChargeDate
        goalEndDate
        maxGoalEndDate
        DailyAccruedBalance
        AvailableDisplayBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        goalChangeAuditArray
        linkedProductType
        updatedAt
        isArchived
        firstChargeAmount
        Currency
        Progress
        isPublic
        publicSharedData
        goalChatId
        publicGoalId
        publicUserId
        goalSource
        sharingIncludeAmount
        fundTransferredToOwner
        noOfMembers
        categoryId
        categoryName
        createdAt
      }
      nextToken
    }
  }
`;
export const byShareCode = /* GraphQL */ `
  query ByShareCode(
    $shareCode: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byShareCode(
      shareCode: $shareCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        shareCode
        voucherId
        name
        image
        goal_type
        basket_id
        availableBalanceToUse
        totalGoalAmount
        netGoalAmount
        goalInterestAmount
        goalRewardType
        goalRewardAmount
        instalmentAmount
        currency
        savedAmount
        length
        lengthDurationType
        maxInstalmentAmount
        paymentDate
        bankInterestRatePercent
        bankInterestRateAmount
        savingsProductType
        userSavingMotivation
        isShared
        archivedSharedUsersId
        sharedUsersId
        sharedGoalDetails
        tenant
        cashfreeSubReferenceId
        cashfreeSubscriptionStatus
        goalStatus
        firstChargeDate
        goalEndDate
        maxGoalEndDate
        DailyAccruedBalance
        AvailableDisplayBalance
        TotalInterestEarnedOnDailyAccruedBalance
        TotalInterestEarnedOnAvailableToSpendBalance
        goalChangeAuditArray
        linkedProductType
        updatedAt
        isArchived
        firstChargeAmount
        Currency
        Progress
        isPublic
        publicSharedData
        goalChatId
        publicGoalId
        publicUserId
        goalSource
        sharingIncludeAmount
        fundTransferredToOwner
        noOfMembers
        categoryId
        categoryName
        createdAt
      }
      nextToken
    }
  }
`;
export const productsByGoalId = /* GraphQL */ `
  query ProductsByGoalId(
    $goalId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSavingProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByGoalId(
      goalId: $goalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        merchantId
        merchantProductId
        rewardType
        offer
        offerType
        logoUrl
        productUrl
        productImageURL
        productName
        productDescription
        productPrice
        deliveryFee
        packingFee
        totalPrice
        itemId
        tenant
        trackingLink
        isMerchantReportCal
        hasVoucher
        voucherCode
        voucherAmount
        voucherAmountType
        createdAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const productsByMerchantProductId = /* GraphQL */ `
  query ProductsByMerchantProductId(
    $merchantProductId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSavingProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByMerchantProductId(
      merchantProductId: $merchantProductId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        merchantId
        merchantProductId
        rewardType
        offer
        offerType
        logoUrl
        productUrl
        productImageURL
        productName
        productDescription
        productPrice
        deliveryFee
        packingFee
        totalPrice
        itemId
        tenant
        trackingLink
        isMerchantReportCal
        hasVoucher
        voucherCode
        voucherAmount
        voucherAmountType
        createdAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const byPlatform = /* GraphQL */ `
  query ByPlatform(
    $platform: String
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPlatform(
      platform: $platform
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        merchantID
        productID
        productName
        merchantName
        rewardType
        productDescription
        productParameters
        price
        deliveryPackingChange
        deliveryFee
        packingFee
        productOffer
        productOfferType
        cashkaroOfferList
        productTags
        productLinkURL
        productImageURL
        relatedArticles
        featured
        offerDescription
        tenant
        platform
        isExpired
        status
        trackingLink
        merchantProductType
        merchantProductBackgroundURL
        hasVoucher
        voucherCode
        voucherAmount
        voucherAmountType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCashkaroCategoryId = /* GraphQL */ `
  query ByCashkaroCategoryId(
    $cashkaroCategoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCashkaroCategoryId(
      cashkaroCategoryId: $cashkaroCategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cashkaroCategoryId
        categoryName
        categoryImage
        merchantProductCount
        backgroundColourHex
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCategoryName = /* GraphQL */ `
  query ByCategoryName(
    $categoryName: String
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCategoryName(
      categoryName: $categoryName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cashkaroCategoryId
        categoryName
        categoryImage
        merchantProductCount
        backgroundColourHex
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cfSubscriptionsBySubRefId = /* GraphQL */ `
  query CfSubscriptionsBySubRefId(
    $cfsubRefId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cfSubscriptionsBySubRefId(
      cfsubRefId: $cfsubRefId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        type
        cfsubRefId
        planInfo
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        amount
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        nextChargeRaisedDate
        nextChargeScheduledDate
        createdAt
        vendorId
        retryAttempts
        updatedAt
      }
      nextToken
    }
  }
`;
export const cfSubscriptionsByGoalIDByCreatedAt = /* GraphQL */ `
  query CfSubscriptionsByGoalIDByCreatedAt(
    $goalId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cfSubscriptionsByGoalIDByCreatedAt(
      goalId: $goalId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        type
        cfsubRefId
        planInfo
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        amount
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        nextChargeRaisedDate
        nextChargeScheduledDate
        createdAt
        vendorId
        retryAttempts
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySubReferenceId = /* GraphQL */ `
  query BySubReferenceId(
    $subReferenceId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySubReferenceId(
      subReferenceId: $subReferenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        subReferenceId
        planId
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cashfreeSubscriptionsByGoalIDByCreatedAt = /* GraphQL */ `
  query CashfreeSubscriptionsByGoalIDByCreatedAt(
    $goalId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashfreeSubscriptionsByGoalIDByCreatedAt(
      goalId: $goalId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        subReferenceId
        planId
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cashfreeSubscriptionsByNeedsPayout = /* GraphQL */ `
  query CashfreeSubscriptionsByNeedsPayout(
    $needsPayout: Int
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashfreeSubscriptionsByNeedsPayout(
      needsPayout: $needsPayout
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        subReferenceId
        planId
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cashfreeSubscriptionByLastOrderId = /* GraphQL */ `
  query CashfreeSubscriptionByLastOrderId(
    $lastOrderId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashfreeSubscriptionByLastOrderId(
      lastOrderId: $lastOrderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        subReferenceId
        planId
        authLink
        authStatus
        status
        firstChargeDate
        expiresOn
        tenant
        payments
        lastOrderId
        needsPayout
        accountHolder
        accountNumber
        bankId
        authMode
        accountType
        ifsc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cashfreeOrderByGoalId = /* GraphQL */ `
  query CashfreeOrderByGoalId(
    $goalId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashfreeOrderByGoalId(
      goalId: $goalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        goalId
        cfOrderId
        amount
        status
        token
        note
        expireTime
        paymentLink
        payment
        tenant
        needsPayout
        vendorId
        encouragementUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cashfreeOrderByGoalIdByCreatedAt = /* GraphQL */ `
  query CashfreeOrderByGoalIdByCreatedAt(
    $goalId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashfreeOrderByGoalIdByCreatedAt(
      goalId: $goalId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        goalId
        cfOrderId
        amount
        status
        token
        note
        expireTime
        paymentLink
        payment
        tenant
        needsPayout
        vendorId
        encouragementUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cashfreeOrdersByNeedsPayout = /* GraphQL */ `
  query CashfreeOrdersByNeedsPayout(
    $needsPayout: Int
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashfreeOrdersByNeedsPayout(
      needsPayout: $needsPayout
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        goalId
        cfOrderId
        amount
        status
        token
        note
        expireTime
        paymentLink
        payment
        tenant
        needsPayout
        vendorId
        encouragementUser
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byTransferId = /* GraphQL */ `
  query ByTransferId(
    $transferId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeTransferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byTransferId(
      transferId: $transferId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        transferId
        beneId
        subReferenceId
        orderId
        referenceId
        utr
        status
        amount
        tenant
        needsRetry
        retryAttempts
        nextScheduledOn
        transferType
        failedTransfers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byNeedsRetry = /* GraphQL */ `
  query ByNeedsRetry(
    $needsRetry: Int
    $sortDirection: ModelSortDirection
    $filter: ModelCashfreeTransferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byNeedsRetry(
      needsRetry: $needsRetry
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        userId
        transferId
        beneId
        subReferenceId
        orderId
        referenceId
        utr
        status
        amount
        tenant
        needsRetry
        retryAttempts
        nextScheduledOn
        transferType
        failedTransfers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byShopifyStoreID = /* GraphQL */ `
  query ByShopifyStoreID(
    $shopifyStoreID: String
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byShopifyStoreID(
      shopifyStoreID: $shopifyStoreID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        platformMerchantId
        platform
        cashfreeVendorId
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const merchantsByPlatform = /* GraphQL */ `
  query MerchantsByPlatform(
    $platform: String
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    merchantsByPlatform(
      platform: $platform
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        platformMerchantId
        platform
        cashfreeVendorId
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const merchantsByIsReviewedAndIsMaxOffer = /* GraphQL */ `
  query MerchantsByIsReviewedAndIsMaxOffer(
    $isReviewed: Int
    $isMaxOffer: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    merchantsByIsReviewedAndIsMaxOffer(
      isReviewed: $isReviewed
      isMaxOffer: $isMaxOffer
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        platformMerchantId
        platform
        cashfreeVendorId
        merchant_name
        merchant_logo_url
        merchant_logo_Base64
        merchant_checkout_url
        merchant_add_id_to_checkout_url
        merchantDescription
        merchantOfferTitle
        merchantOfferType
        merchantOfferAmount
        afiliateUrl
        merchantCategory
        merchantWebsiteURL
        tenant
        shopifyStoreID
        shopifyStoreSaathiSNPLTCsAgreed
        SNPLAllowedBySaathi
        merchantType
        backgroundCircleHexColour
        merchantBackgroundImageURL
        isReviewed
        isMaxOffer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byIsExported = /* GraphQL */ `
  query ByIsExported(
    $isExported: Int
    $sortDirection: ModelSortDirection
    $filter: ModelWaitListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byIsExported(
      isExported: $isExported
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        eMail
        mobileCountryCode
        mobileNumber
        privacyTermsAccepted
        countryOfResidence
        isExported
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const conversionsByNeedsPayout = /* GraphQL */ `
  query ConversionsByNeedsPayout(
    $needsPayout: Int
    $sortDirection: ModelSortDirection
    $filter: ModelCashkaroConversionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversionsByNeedsPayout(
      needsPayout: $needsPayout
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        offerId
        goalId
        actionId
        userBankSavingsAccountNumber
        userBankSavingsAccountBranch
        status
        paymentStatus
        utr
        amount
        orderAmount
        needsPayout
        retryAttempts
        nextScheduledOn
        failedPayments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const conversionsByGoalId = /* GraphQL */ `
  query ConversionsByGoalId(
    $goalId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSaathiConversionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversionsByGoalId(
      goalId: $goalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        userId
        offerId
        goalId
        actionId
        status
        amount
        orderAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byReferralCode = /* GraphQL */ `
  query ByReferralCode(
    $referralCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byReferralCode(
      referralCode: $referralCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        referralCode
        maxReferrals
        referralCount
        userReferralOffer
        userReferralOfferType
        refereeOffer
        refereeOfferType
        isEnabled
        referred
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const trackingDatabyUserId = /* GraphQL */ `
  query TrackingDatabyUserId(
    $userId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMoneyTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trackingDatabyUserId(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        goalId
        trackingData
        completed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saathiTokenActivityByCustomerId = /* GraphQL */ `
  query SaathiTokenActivityByCustomerId(
    $customerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaathiTokenActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saathiTokenActivityByCustomerId(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        goalId
        tokenEventId
        tokenEventText
        tokenEventTimestamp
        tokenType
        tokenValue
        tokenEventStatus
        linkedCustomerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saathiTokenActivitiesByGoalId = /* GraphQL */ `
  query SaathiTokenActivitiesByGoalId(
    $goalId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSaathiTokenActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saathiTokenActivitiesByGoalId(
      goalId: $goalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        goalId
        tokenEventId
        tokenEventText
        tokenEventTimestamp
        tokenType
        tokenValue
        tokenEventStatus
        linkedCustomerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saathiTokenActivitiesByLinkedCustomerId = /* GraphQL */ `
  query SaathiTokenActivitiesByLinkedCustomerId(
    $linkedCustomerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaathiTokenActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saathiTokenActivitiesByLinkedCustomerId(
      linkedCustomerId: $linkedCustomerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        goalId
        tokenEventId
        tokenEventText
        tokenEventTimestamp
        tokenType
        tokenValue
        tokenEventStatus
        linkedCustomerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
