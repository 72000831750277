import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { getUserData } from '../../../api/userSlice';
import './Login.scss';

function Login({ setForgotPass }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErr, setFormErr] = useState('');

  useEffect(() => {
    const saathiAccessToken = sessionStorage.getItem('saathi_accessToken');
    const saathiIdToken = sessionStorage.getItem('saathi_idToken');

    // If user is already logged in then take them back to the dashboard:
    if (saathiAccessToken && saathiIdToken) {
      navigate('/Dashboard');
    }
  }, []);

  const validateData = async () => {
    const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    let flag = false;
    if (isEmail.test(email) || email === 'saathiadmin') {
      flag = true;
    }
    try {
      setIsLoading(true);
      if (!flag) {
        setFormErr('Please enter valid Email');
        setIsLoading(false);
        return;
      }
      const res = await Auth.signIn(email, password);
      const { attributes } = res;

      const userId = attributes['custom:employerId'];
      dispatch(getUserData(userId));

      const { signInUserSession } = res;
      const { accessToken, idToken } = signInUserSession;

      sessionStorage.setItem('saathi_accessToken', accessToken.jwtToken);
      sessionStorage.setItem('saathi_idToken', idToken.jwtToken);

      setIsLoading(false);
      navigate('/Dashboard');
    } catch (error) {
      setIsLoading(false);
      setFormErr(error?.message || "User doesn't exist");
      console.log(error);
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleForgetPass = (e) => {
    e.preventDefault();
    setForgotPass(true);
  };
  return (
    <div id="login-container">
      <h1 className="title">Login</h1>
      <div className="form-group">
        <label htmlFor="email">Email/Phone</label>
        <input
          onChange={handleEmail}
          type="text"
          name="email"
          id="email-input"
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Password</label>
        <input
          className="text-xl tracking-widest"
          maxLength={6}
          onChange={handlePassword}
          type="password"
          name="password"
          id="password-input"
        />
      </div>
      {formErr.length > 0 && <div className="text-red-600">{formErr}</div>}
      <div className="form-group">
        <button disabled={loading} onClick={validateData} className="btn-login">
          {loading ? (
            <div className="text-gray-200">Logging in...</div>
          ) : (
            <>Login</>
          )}
        </button>
        <a href="#" onClick={handleForgetPass} className="forget-password">
          Forget Password?
        </a>
      </div>
    </div>
  );
}

export default Login;
