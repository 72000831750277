import React, { useState } from 'react'
import './AuthenticationPage.scss'
import Login from './LoginSection/Login'
import ForgetPassword from './ForgetPassword/ForgetPassword'
import SaathiLogo from '../../assets/images/saathiLogo.svg'

function AuthenticationPage() {
  const [forgotPass, setForgotPass] = useState(false);

  return (
    <div id='AuthenticationPage'>
      <div className="topGradientContainer">
        <div className="radialPinkBlueGradient" />
        <div className="whiteningLinearGradient" />
        <div className="login-section">
          <div className="logo-container">
            <img className='logo' src={SaathiLogo} width={110} height={27} alt="logo" />
          </div>
          {forgotPass?
          (<ForgetPassword setForgotPass={setForgotPass} />):(
            <Login setForgotPass={setForgotPass} />
          )}
        </div>
      </div>
    </div>
  )
}

export default AuthenticationPage