import React, { useState } from 'react'
import { Auth } from 'aws-amplify';
import './ForgetPassword.scss'
function ForgetPassword({ setForgotPass }) {
    const [email, setEmail] = useState("");
    const goBack = (e) => {
        e.preventDefault();
        setForgotPass(false);
    }
    const sendResetPasswordLink = async () => {
        try {
            const res = await Auth.forgotPassword(email);
            if (res?.CodeDeliveryDetails?.DeliveryMedium === 'SMS'){
                alert("SMS has been sent to your registered phone number with the details")
            }

        } catch (error) {
            console.log(error);
        }
    }
  return (
      <div id='forget-passw-container'>
          <h1 className="title">Forgotten Password</h1>
          <div className="form-group">
              <label htmlFor="email">Email/Phone</label>
              <input onChange={(e)=>setEmail(e.target.value)} type="text" name="email" id="email-input" />
          </div>
          <div className="form-group">
              <button onClick={sendResetPasswordLink} className="btn-login">Send Reset</button>
              <a href="#" onClick={goBack} className="forget-password">I've remembered</a>
          </div>
      </div>
  )
}

export default ForgetPassword