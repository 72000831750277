import React from 'react'
import SearchIcon from '../../assets/images/searchIcon.svg';

function SearchInput() {
  return (
      <div id="searchInput" className='w-1/2 hidden md:flex h-full items-center'>
          <img src={SearchIcon} width={16} height={16} className="ml-8" alt="" />
          <input type="text" className='w-full h-full outline-none ml-[16px] text-[20px]' placeholder='Search...' />
      </div>
  )
}

export default SearchInput