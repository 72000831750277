import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUserListAPI, saveCurrentUser, updateUserList, getUserGoalsAPI } from '../../../api/userSlice';
import { Dialog, Transition } from '@headlessui/react';
import { utils, writeFileXLSX } from 'xlsx';
import { TailSpin } from 'react-loader-spinner'

import FilterDropdown from '../../../sharedComponents/FilterDropdown/FilterDropdown';
import Pagination from '../../../sharedComponents/Pagination/Pagination';
import BroadcastModal from './BroadcastModal';
import GoalsModal from './GoalsModal';
import InfoMenu from '../../../sharedComponents/InfoMenu/InfoMenu';

import filterLogo from '../../../assets/images/filterLogo.svg'
import downloadLogo from '../../../assets/images/downloadLogo.svg'
import upIcon from '../../../assets/images/upIcon.svg';
import chatIcon from '../../../assets/images/chatIcon.svg';
import createLogo from '../../../assets/images/createLogo.svg'
import CreateUserModal from './CreateUserModal';

function UsersNew() {
    const dispatch = useDispatch();

    const { userList, loading, error, userListLoading } = useSelector(state => state.users);

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [filterUsers, setFilterUsers] = useState([]);
    const [currentRecords, setCurrentRecords] = useState([]);
    const [ascSort, setAscSort] = useState([true, true, true, true, true, true]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const tableHeadingClasses = "text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading"

    const tableCellClasses = "min-h-[40px] flex items-center justify-start truncate"

    useEffect(() => {
        fetchUserList();
    }, [])

    useEffect(() => {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        let oldUsers = [...userList];

        if (filterUsers.length > 0) {
            const newUsers = oldUsers.filter(handleFilter);
            if (newUsers.length <= recordsPerPage) {
                setCurrentRecords(oldUsers);
            } else {
                setCurrentRecords(newUsers.slice(indexOfFirstRecord, indexOfLastRecord))
            }
        } else {
            setCurrentRecords([...oldUsers.slice(indexOfFirstRecord, indexOfLastRecord)])
        }

    }, [currentPage, recordsPerPage, userListLoading, filterUsers, userList])


    const fetchUserList = () => {
        if (userList.length === 0) {
            dispatch(getUserListAPI());
        }
    }

    const fetchUserGoals = (userId) => {
        dispatch(getUserGoalsAPI({ id: userId }));
    }

    const convertToXLSX = async () => {
        try {
            let cleanData = [];
            const header = [
                'ID',
                'First Name',
                'Middle Name',
                'Last Name',
                'Email',
                'Mobile No',
                'User First Time Login Flag',
                'Profile Lock',
                'Completed Grow Questionnaire?',
                'Title',
                'Gender',
                'State',
                'City',
                'Street 1',
                'Street2',
                'Post Code',
                'Building Number',
                'Address House',
                'Landmark',
                'Date of Birth',
                'Country Code',
                'Bank Customer ID',
                'Bank Savings A/C Num',
                'Bank Savings Branch',
                'IFSC Code',
                'Savings T&C TS',
                'Savings T&C Acceptance',
                'Savings T&C IP',
                'KYC Status',
                'Debit Card Name',
                'Created At',
                'Updated At'
            ];
            userList.map((user) => {
                let temp = {};

                temp[header[0]] = user.id
                temp[header[1]] = user.UsrGivenName || ""
                temp[header[2]] = user.UsrMiddleName || ""
                temp[header[3]] = user.UsrFamilyName || ""
                temp[header[4]] = user.UsrEmail || ""
                temp[header[5]] = user.UsrMobileNo || ""
                temp[header[6]] =
                    user.UsrFirstTimeLoginFlag || false

                temp[header[7]] = user.ProfileLock || "-"
                temp[header[8]] = user.hasUserCompletedGrowQuestionnaire || false
                temp[header[9]] = user.Title || ""
                temp[header[10]] = user.Gender || ""
                temp[header[11]] = user.State || ""
                temp[header[12]] = user.City || ""
                temp[header[13]] = user.Street1 || ""
                temp[header[14]] = user.Street2 || ""
                temp[header[15]] = user.PostCode || ""
                temp[header[16]] = user.addressBuildingNumber || ""
                temp[header[17]] = user.addressHouse || ""
                temp[header[18]] = user.addressLandmark || ""
                temp[header[19]] = user.DateOfBirth || ""
                temp[header[20]] = user.CountryCode || ""
                temp[header[21]] = user.snplBankCustomerId || ""
                temp[header[22]] = user.snplBankSavingsAccountNumber || ""
                temp[header[23]] = user.snplBankSavingsBranch || ""
                temp[header[24]] = user.snplBankSavingsIFSCCode || ""
                temp[header[25]] = user.SavingsTermsAndConditionsTS ? new Date(user.SavingsTermsAndConditionsTS).toLocaleString() : ""
                temp[header[26]] = user.SavingsTermsAndConditionsAcceptance || false
                temp[header[27]] = user.SavingsTermsAndConditionsIP || ""
                temp[header[28]] = user.UsrKYCStatus || ""
                temp[header[29]] = user.snplDebitCardName || ""
                temp[header[30]] = user.createdAt ? new Date(user.createdAt).toLocaleString() : new Date().toLocaleString()
                temp[header[31]] = user.updatedAt ? new Date(user.updatedAt).toLocaleString() : new Date().toLocaleString()

                cleanData.push(temp);
            })
            const ws = utils.json_to_sheet(cleanData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "users");
            writeFileXLSX(wb, "user_data.xlsx");

        } catch (error) {
            console.log(error);
        }
    }

    const sortUsers = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[0]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                return a.id.localeCompare(b.id)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[0] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                return -1 * a.id.localeCompare(b.id)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[0] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortUsersByFirstName = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[1]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrGivenName || "";
                let b1 = b?.UsrGivenName || "";
                return a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[1] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrGivenName || "";
                let b1 = b?.UsrGivenName || "";
                return -1 * a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[1] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortUsersByLastName = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[2]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrFamilyName || "";
                let b1 = b?.UsrFamilyName || "";
                return a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[2] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrFamilyName || "";
                let b1 = b?.UsrFamilyName || "";
                return -1 * a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[2] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortUsersByEmail = () => {
        let oldSort = [true, true, true, true, true, true];
        if (ascSort[3]) {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrEmail || "";
                let b1 = b?.UsrEmail || "";
                return a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[3] = false;
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            oldUsers.sort(function (a, b) {
                let a1 = a?.UsrEmail || "";
                let b1 = b?.UsrEmail || "";
                return -1 * a1.localeCompare(b1)
            });
            dispatch(updateUserList([...oldUsers]))
            oldSort[3] = true;
            setAscSort([...oldSort]);
        }
    }

    const sortByDate = (flag = false) => {
        let oldSort = [true, true, true, true, true, true];
        if (flag ? ascSort[4] : ascSort[5]) {
            let oldUsers = [...userList];
            if (flag) {
                oldUsers.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldUsers.sort(function (a, b) {
                    return new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateUserList([...oldUsers]))
            if (flag) {
                oldSort[4] = false;
            } else {
                oldSort[5] = false;
            }
            setAscSort([...oldSort]);
        } else {
            let oldUsers = [...userList];
            if (flag) {
                oldUsers.sort(function (a, b) {
                    return -1 * new Date(a.createdAt) - new Date(b.createdAt);
                });
            } else {
                oldUsers.sort(function (a, b) {
                    return -1 * new Date(a.updatedAt) - new Date(b.updatedAt);
                });
            }
            dispatch(updateUserList([...oldUsers]))
            if (flag) {
                oldSort[4] = true;
            } else {
                oldSort[5] = true;
            }
            setAscSort([...oldSort]);
        }
    }

    const handleCurrentUsers = (data) => {
        dispatch(saveCurrentUser(data));
        // fetchUserGoals(data?.id);
        setEditFlag(true);
        setOpenCreateModal(true);
    }

    const handleFilter = (user) => {
        if (filterUsers.length === 0) {
            return true;
        }
        const id = filterUsers.findIndex(e => e === user?.UsrFamilyName);
        return id !== -1;
    }

    const handleOpenFilterMenu = () => {
        setShowFilterMenu(prev => !prev);
    }
    return (
        <>
            {(userListLoading || currentRecords.length === 0) ? (
                <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <TailSpin
                        height="120"
                        width="120"
                        color="#62A6FF"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <div id='main-container' className="container max-w-full">
                        <div className='w-full flex justify-between items-center min-w-max'>
                            <h1 className='text-[32px] font-bold m-[40px]'>Users</h1>
                            <div className='w-[200px] mr-10 flex justify-center gap-3'>
                                <div className='cursor-pointer'>
                                    <img onClick={handleOpenFilterMenu} src={filterLogo} alt="" />
                                    <FilterDropdown
                                        filterItems={filterUsers}
                                        setFilterItems={setFilterUsers}
                                        showFilterMenu={showFilterMenu}
                                        type="Users"
                                    />
                                </div>
                                <div onClick={() => convertToXLSX()} className='cursor-pointer'>
                                    <img src={downloadLogo} alt="" />
                                </div>
                                <div className='cursor-pointer' onClick={() => { setOpenCreateModal(prev => !prev) }}>
                                    <img src={createLogo} alt="" />
                                </div>
                            </div>
                        </div>

                        <div id="user_table" className='flex flex-col justify-center text-center overflow-x-auto flex-1 overflow-y-hidden pb-12 min-w-max'>
                            <div className='w-full flex'>
                                <div className={`w-[35vw] lg:w-[20vw] ${tableHeadingClasses} justify-center ml-10 pl-4 rounded-l-lg`}>
                                    ID
                                    <img onClick={sortUsers} className={`ml-2 ${ascSort[0] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                </div>
                                <div className={`w-[35vw] lg:w-[20vw] ${tableHeadingClasses}`}>
                                    Merchant ID
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    First Name
                                    <img onClick={sortUsersByFirstName} className={`ml-2 ${ascSort[1] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    Last Name
                                    <img onClick={sortUsersByLastName} className={`ml-2 ${ascSort[2] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                </div>
                                <div className={`w-[45vw] lg:w-[18vw] ${tableHeadingClasses}`}>
                                    Email
                                    <img onClick={sortUsersByEmail} className={`ml-2 ${ascSort[3] ? "rotate-180" : ""} cursor-pointer`} src={upIcon} alt="" />
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    Country Code
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    Mobile Number
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    User Type
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    Primary User
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    Status
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses}`}>
                                    Added
                                </div>
                                <div className={`w-[35vw] lg:w-[10vw] ${tableHeadingClasses} mr-10 rounded-r-lg`}>
                                    Updated
                                </div>
                            </div>
                            {userList && currentRecords.filter(handleFilter).map((ele, i) => {
                                if (ele === null || ele === undefined) {
                                    return <></>;
                                }
                                try {
                                    const {
                                        merchant_user_id,
                                        first_name,
                                        last_name,
                                        user_type,
                                        is_primary_user,
                                        mobile_number,
                                        country_code,
                                        email_address,
                                        merchant_id,
                                        is_active,
                                        createdAt,
                                        updatedAt
                                    } = ele;
                                    return (
                                        <div className='w-full flex my-3'>
                                            <div onClick={() => handleCurrentUsers(ele)} className={`w-[35vw] lg:w-[20vw] cursor-pointer underline pl-4 text-blue-500 ${tableCellClasses} ml-10`}>
                                                <div className='w-[90%] overflow-x-auto'>
                                                    {merchant_user_id}
                                                </div>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[20vw] ${tableCellClasses}`}>
                                                <div className='w-[90%] overflow-x-auto'>
                                                    {merchant_id}
                                                </div>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {first_name}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {last_name}
                                            </div>
                                            <div className={`w-[45vw] lg:w-[18vw] ${tableCellClasses} text-left`}>
                                                <div className='w-[90%] overflow-x-auto'>
                                                    {email_address}
                                                </div>
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {country_code}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {mobile_number}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {user_type}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {is_primary_user ? "True" : "False"}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {is_active ? "Active" : "Inactive"}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses}`}>
                                                {new Date(createdAt).toLocaleDateString()}
                                            </div>
                                            <div className={`w-[35vw] lg:w-[10vw] ${tableCellClasses} mr-10`}>
                                                {updatedAt ? new Date(updatedAt).toLocaleDateString() : new Date().toLocaleDateString()}
                                            </div>
                                        </div>
                                    )
                                } catch (error) {
                                    return (<></>)
                                }
                            })}
                        </div>

                        <Pagination
                            size={userList.filter(handleFilter).length || 0}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            type='Users'
                            setRecordsPerPage={setRecordsPerPage}
                            recordsPerPage={recordsPerPage}
                        />

                        <Transition appear show={openCreateModal} as={Fragment}>
                            <Dialog as="div" className="relative z-10" onClose={() => {
                                setOpenCreateModal(false)
                                setEditFlag(false);
                            }}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                </Transition.Child>

                                <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full justify-center p-4">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Dialog.Panel className="transition-all">
                                                <CreateUserModal
                                                    setOpenCreateModal={setOpenCreateModal}
                                                    editFlag={editFlag}
                                                    openCreateModal={openCreateModal}
                                                    setEditFlag={setEditFlag}
                                                />
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition>
                    </div>
                </>
            )}
        </>
    )
}

export default UsersNew