import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid';
import PlusIcon from '../../../assets/images/plusIcon.svg';
import MinusIcon from '../../../assets/images/minusIcon.svg';

function ProductParameterInput({ min = 0, label = "", value = "", name = "", placeholder = "", onChangeHandler = (e) => { }, params }) {
  const [numInputs, setNumInputs] = useState([{ flag: false, val: uuid() }]);
  const [flag, setFlag] = useState(false);
  const [parameters, setParameters] = useState([]);
  useEffect(() => {
    try {
      if (params) {
        setFlag(true);
        const o = params;
        if (o["Image"]) {
          delete o["Image"]
        }
        const p = Object.entries(o);
        setParameters([...p]);
        let newArr = [];
        if(p.length>0){
          p.map((ele) => {
            newArr.push({
              flag: false,
              val: uuid()
            })
          })
          setNumInputs([...newArr]);
        }else {
          setNumInputs([{ flag: false, val: uuid() }]);
        }
      }
    } catch (error) {
      console.log(error);
      setNumInputs([{ flag: false, val: uuid() }]);
      setFlag(false);
    }
  }, [params])

  const addRow = () => {
    setNumInputs(prev => [...prev, { flag: false, val: uuid() }]);
  }

  const removeRow = (id) => {
    const idx = numInputs.findIndex(e => e.val === id);
    if (idx !== -1) {
      let oldInputs = [...numInputs];
      oldInputs[idx].flag = true;
      setNumInputs([...oldInputs]);
    }
  }

  return (
    <div>
      <label htmlFor={label} className="block text-sm font-medium text-gray-700">

        {label}
        <span className="text-red-700">
          *
        </span>
      </label>
      <div className="relative mt-1 rounded-md shadow-sidebarActive w-full">
        <div className='grid grid-cols-12 w-full'>
          <div className="col-span-11">
            {numInputs.map((ele, id) => {
              return (
                <>
                  {ele.flag ? <></> : (
                    <div className={`flex ${numInputs.length > 1 && "mb-3"}`}>
                      <div className={`grid grid-cols-2 gap-1`}>
                        <input
                          type="text"
                          onChange={onChangeHandler}
                          defaultValue={(flag && parameters[id]) ? parameters[id][0] : value}
                          name={`${name}_row${id}_1`}
                          min={min}
                          className="block w-full h-10 rounded-md border border-inputBorder pl-2 pr-7 outline-none sm:text-sm"
                          placeholder={placeholder}
                        />
                        <input
                          type="text"
                          onChange={onChangeHandler}
                          defaultValue={(flag && parameters[id]) ? parameters[id][1] : value}
                          name={`${name}_row${id}_2`}
                          min={min}
                          className="block w-full h-10 rounded-md border border-inputBorder pl-2 pr-7 outline-none sm:text-sm"
                          placeholder={placeholder}
                        />

                      </div>
                      <div onClick={() => removeRow(ele.val)} className={`flex ${(numInputs.length > 1 && id !== 0) ? "items-center" : "items-center hidden"} justify-center pl-3`}>
                        <img height={40} width={40} src={MinusIcon} alt="" />
                      </div>
                    </div>
                  )}
                </>
              )
            })}
          </div>
          <div onClick={() => addRow()} className={`col-span-1 flex ${numInputs.length > 1 ? "items-start mt-2" : "items-center"} justify-center`}>
            <img src={PlusIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductParameterInput