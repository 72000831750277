import { Transition, Dialog } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { classify } from '../common/Utility';

const Modal = ({
  show,
  onClose,
  className,
  children: modalContents,
}: {
  show: boolean;
  onClose: () => void;
  className?: string;
  children: ReactNode;
}) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" onClose={onClose} className="fixed inset-0 z-10">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="transition-all">
                <div
                  id="create-merchant-modal"
                  // className="absolute top-1/2 left-1/2 w-[80vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2"
                  className={classify([
                    'absolute top-1/2 left-1/2 w-[80vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2',
                    className,
                  ])}
                >
                  {modalContents}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;

// import { Transition, Dialog } from '@headlessui/react';
// import { Fragment, ReactNode } from 'react';

// const Modal = ({
//   show,
//   onClose,
//   children: modalContents,
// }: {
//   show: boolean;
//   onClose: () => void;
//   children: ReactNode;
// }) => {
//   return (
//     <Transition appear show={show} as={Fragment}>
//       <Dialog as="div" onClose={onClose} className="fixed inset-0 z-10">
//         <Transition.Child
//           as={Fragment}
//           enter="ease-out duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in duration-200"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div className="fixed inset-0 bg-black bg-opacity-25" />
//         </Transition.Child>

//         <div className="fixed inset-0 overflow-y-auto">
//           <div className="flex min-h-full justify-center p-4">
//             <Transition.Child
//               as={Fragment}
//               enter="ease-out duration-300"
//               enterFrom="opacity-0 scale-95"
//               enterTo="opacity-100 scale-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100 scale-100"
//               leaveTo="opacity-0 scale-95"
//             >
//               <Dialog.Panel className="transition-all">
//                 <div
//                   id="create-merchant-modal"
//                   className="absolute top-1/2 left-1/2 w-[80vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2"
//                 >
//                   {modalContents}
//                 </div>
//               </Dialog.Panel>
//             </Transition.Child>
//           </div>
//         </div>
//       </Dialog>
//     </Transition>
//   );
// };
// export default Modal;
