import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import ChangePassword from './ChangePassword';
import UserLogo from '../../../assets/images/sidebarLogo/userLogo.svg'
import ResetLogo from '../../../assets/images/resetLogo.svg';

function Settings() {
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    getUserDetails()
  }, [])

  const toggleResetPassword = () => {
    setFlag(prev => !prev);
  }

  const handleResetPassword = async (oldPass, newPass) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const res = await Auth.changePassword(user, oldPass, newPass);
      if(res === 'SUCCESS'){
        alert("Password Reset Successful!")
        toggleResetPassword();
      }
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }

  const getUserDetails = async () => {
    try {
      const userCreds = await Auth.currentAuthenticatedUser();
      const { attributes, username } = userCreds;
      setNumber(attributes["phone_number"]);
      setEmail(attributes["email"]);
      setName(username);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <div className="container w-fit absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
        {flag ? (
          <ChangePassword handleResetPassword={handleResetPassword} toggleResetPassword={toggleResetPassword} />
        ) : (
          <>
              <div style={{ background: "#F2E9FB", text: "#A877D9" }} className='w-[150px] h-[150px] flex items-center m-auto justify-center text-[55px] saathi-font rounded-full font-bold'>{name && name[0].toUpperCase()}</div>
            <h1 className='text-[35px] text-center'>Andrew Cranwell</h1>
            <div className='w-[329px] h-[45px] bg-btnGray rounded-lg flex items-center justify-center mt-7 tex'>
              {number}
            </div>
            <div className='w-[329px] h-[45px] bg-btnGray rounded-lg flex items-center justify-center my-4'>
              {email}
            </div>
            <div className='flex items-center'>
              <div className='w-[329px] h-[45px] bg-btnGray rounded-lg flex items-center justify-center'>
                **************
              </div>
              <img onClick={toggleResetPassword} className="ml-4" src={ResetLogo} alt="" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Settings