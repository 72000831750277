import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import UploadIdeasFile from './UploadIdeasFile';
import ManualAddIdeas from './ManualAddIdeas';
import Modal from '../../../../../sharedComponents/Modal';
import { classify } from '../../../../../common/Utility';
import { VoiceProfileDomainDescription } from 'aws-sdk/clients/chimesdkvoice';

const AddIdeasModal = ({
  show,
  allExistingIdeas,
  allExistingCategories,
  setAllIdeas,
  closeModal,
}: {
  show: boolean;
  allExistingIdeas: Idea[];
  allExistingCategories: IdeaCategory[];
  setAllIdeas: (newList: Idea[]) => void;
  closeModal: () => void;
}) => {
  const [tabSelected, setTabSelected] = useState<'manualAdd' | 'fileUpload'>(
    'fileUpload'
  );

  function processClose() {
    closeModal();
    setTabSelected('fileUpload');
  }

  return (
    <Modal show={show} onClose={processClose} className={classify(['p-8'])}>
      <div className="flex justify-end mb-6">
        <button onClick={processClose} className={classify(['text-red-600'])}>
          Close
        </button>
      </div>

      <div className={classify(['flex justify-center gap-16'])}>
        <button
          className={classify([
            'inline-flex items-center justify-center h-8 p-4',
            tabSelected === 'manualAdd' && 'bg-green-400',
          ])}
          // onClick={() => setTabSelected('manualAdd')}
        >
          Add manually
        </button>
        <button
          className={classify([
            'inline-flex items-center justify-center h-8 p-4',
            tabSelected === 'fileUpload' && 'bg-green-400',
          ])}
          onClick={() => setTabSelected('fileUpload')}
        >
          Upload file
        </button>
      </div>

      {tabSelected === 'manualAdd' ? (
        <ManualAddIdeas />
      ) : (
        <UploadIdeasFile
          allExistingIdeas={allExistingIdeas}
          allExistingCategories={allExistingCategories}
          setAllIdeas={setAllIdeas}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
export default AddIdeasModal;
