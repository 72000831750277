import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'
import { TailSpin } from 'react-loader-spinner'
import { Dialog, Transition } from '@headlessui/react';
import ConversionStatusModal from '../../../sharedComponents/ConversionStatusModal/ConversionStatusModal'
import LightCard from '../../../sharedComponents/LightCard/LightCard'
import home from '../../../assets/images/home.svg'
import searchIcon from '../../../assets/images/searchIcon.svg'
import GoalsTable from './GoalsTable'
import { listAllMerchantGoalsAPI } from '../../../api/goalSlice'
import Pagination from '../../../sharedComponents/Pagination/Pagination';
import FilterDropdown from '../../../sharedComponents/FilterDropdown/FilterDropdown';
import filterLogo from '../../../assets/images/filterLogo.svg';

function GoalsPage() {
    const dispatch = useDispatch();
    const { goals, goalsLoading, totalGoals, valueOfGoals, totalSavedAmount, totalCustomers } = useSelector(state => state.goals);
    const [createConversionStatusFlag, setCreateConversionStatusFlag] = useState(false);
    const [editConversionStatusFlag, setEditConversionStatusFlag] = useState(false);
    const [currentGoal, setCurrentGoal] = useState({});
    const [currentOrderAmount, setCurrentOrderAmount] = useState(0);
    // const [activeTab, setActiveTab] = useState(0)
    const [totalCount, setTotalCount] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [itemsCount, setItemsCount] = useState(5);
    const [searchVal, setSearchVal] = useState("");
    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const [currentRecords, setCurrentRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const [filterGoals, setFilterGoals] = useState([]);

    function formatIndianCurrency(number) {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: number % 1 === 0 ? 0 : 2,
            maximumFractionDigits: number % 1 === 0 ? 0 : 2
        }).format(number);
    }

    const cardContent = [
        {
            title: "Number of Goals",
            desc: `${totalGoals || 0}`
        },
        {
            title: "Number of Customers",
            desc: `${totalCustomers || 0}`
        },
        {
            title: "Value of Goals",
            desc: `${formatIndianCurrency(valueOfGoals || 0)}`
        },
        {
            title: "Amount paid into goals",
            desc: `${formatIndianCurrency(totalSavedAmount || 0)}`
        },
    ]

    useEffect(() => {
        if (goals && goals.length === 0) {
            fetchAllGoals()
        }
    }, [])

    useEffect(() => {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        let oldGoals = [...goals];
        
        if(filterGoals.length>0) {
            let newGoals = oldGoals.filter(handleFilter);
            if (searchVal && searchVal.length > 0) {
                newGoals = newGoals.filter((e) => (e?.id.toLowerCase().includes(searchVal.toLowerCase())))

            }
            if(newGoals.length <= recordsPerPage) {
                setCurrentRecords(newGoals);
            } else {
                setCurrentRecords(
                    newGoals.slice(indexOfFirstRecord, indexOfLastRecord)
                )
            }
        } else {
            if (searchVal && searchVal.length > 0) {
                oldGoals = oldGoals.filter((e) => (e?.id.toLowerCase().includes(searchVal.toLowerCase())))

            }
            setCurrentRecords([
                ...oldGoals.slice(indexOfFirstRecord, indexOfLastRecord),
            ]);
        }
    }, [
        currentPage,
        recordsPerPage,
        goals,
        searchVal,
        filterGoals
    ])

    const handleFilter = (goal) => {
        if (filterGoals.length === 0) {
            return true;
        }
        const id = filterGoals.findIndex(e => e === goal?.goalStatus);
        return id !== -1;
    }

    const handleOpenFilterMenu = () => {
        setShowFilterMenu((prev) => !prev);
    };


    const fetchMerchantId = async () => {
        try {
            const userInfo = await Auth.currentUserInfo();
            const { attributes } = userInfo;
            if (attributes && Object.keys(attributes).length > 0) {
                const id = attributes["custom:brand_name"];
                return id;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const openModal = (editFlag = false, goal = null, orderAmount = null) => {
        if (editFlag) {
            setCreateConversionStatusFlag(false)
            setEditConversionStatusFlag(true)
            setCurrentGoal(goal);
            setCurrentOrderAmount(orderAmount);
        } else {
            setCreateConversionStatusFlag(true)
            setEditConversionStatusFlag(false)
            console.log(goal);
            setCurrentGoal(goal);
            setCurrentOrderAmount(0);
        }
    }

    const closeModal = () => {
        setCreateConversionStatusFlag(false)
        setEditConversionStatusFlag(false)
        setCurrentGoal({})
        setCurrentOrderAmount(0)
    }

    const fetchAllGoals = async () => {
        const merchantId = await fetchMerchantId();
        dispatch(listAllMerchantGoalsAPI({ pageNum, itemsCount, merchantId: merchantId }));
    }

    return (
        <>
            {goalsLoading ? (
                <>
                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                        <TailSpin
                            height="120"
                            width="120"
                            color="#62A6FF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            visible={true}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className='container max-w-full mb-6 min-h-screen'>
                        <div className='flex justify-between items-center px-10 pt-3 bg-white'>
                            <div className='flex items-center'>
                                    <h1 className="text-[32px] font-bold">Goals</h1>
                            </div>
                        </div>
                        <div className='mb-6 mt-2 mx-6 grid grid-cols-12 gap-6'>
                            {cardContent.map((ele, id) => {
                                return (
                                    <div key={id} className='col-span-3'>
                                        <LightCard title={ele?.title} desc={ele?.desc} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className='mx-6 my-6 bg-white rounded-lg max-h-screen'>
                            <div className='w-full h-[80px] flex items-center justify-between'>
                                <form className='w-2/3'>
                                    <div className='login-input h-full w-full grid grid-cols-12 items-center rounded-lg'>
                                        <input onChange={(e) => setSearchVal(e.target.value)} className='h-full col-span-11 bg-btnGray outline-none' type="text" name='goal_search_value' placeholder='Search' />
                                        <img src={searchIcon} alt="search" width={24} height={24} />
                                    </div>
                                </form>
                                <div className="cursor-pointer mr-[50px]">
                                    <img onClick={handleOpenFilterMenu} src={filterLogo} alt="" />
                                    <FilterDropdown
                                        showFilterMenu={showFilterMenu}
                                        filterItems={filterGoals}
                                        setFilterItems={setFilterGoals}
                                        type="Goals"
                                    />
                                </div>
                                
                            </div>

                            <div className="w-full">
                                <GoalsTable
                                    openModal={openModal}
                                    displayContent={currentRecords.filter(handleFilter)}
                                />
                            </div>
                            <div className="w-full">
                                <Pagination
                                    type='Goals'
                                    size={goals?.filter(handleFilter).length || 0}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    setRecordsPerPage={setRecordsPerPage}
                                    recordsPerPage={recordsPerPage}
                                />
                            </div>
                        </div>

                        <Transition appear show={createConversionStatusFlag || editConversionStatusFlag} as={Fragment}>
                            <Dialog as="div" className="relative z-[100]" onClose={() => closeModal()}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                                </Transition.Child>

                                <div className="fixed inset-0 overflow-y-auto">
                                    <div className="flex min-h-full justify-center p-4">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Dialog.Panel className="transition-all">
                                                <ConversionStatusModal
                                                    openModal={openModal}
                                                    closeModal={closeModal}
                                                    goal={currentGoal}
                                                    editFlag={editConversionStatusFlag}
                                                    currentOrderAmount={currentOrderAmount}
                                                />
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition>

                        {/* Spacer */}
                        <div className='w-full h-[100px]' />
                    </div>
                </>
            )}
        </>
    )
}

export default GoalsPage