import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import Chart from './Chart';
import { getAdminAnalyticsAPI } from '../../../api/analyticsSlice';
import article1 from '../../../assets/images/articles/article1.svg';
import article2 from '../../../assets/images/articles/article2.svg';
import article3 from '../../../assets/images/articles/article3.svg';

function Dashboard() {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [sortedList, setSortedList] = useState([]);
  const [activeTPeriod, setActiveTPeriod] = useState(null);
  const [cardContent, setCardContent] = useState([
    {
      title: 'Users',
      count: 4500,
    },
    {
      title: 'Goals Created',
      count: 4500,
    },
    {
      title: 'Products Purchased',
      count: 1500,
    },
    {
      title: 'Reports',
      count: 6,
    },
  ]);

  const { user } = useSelector((state) => state.users);
  const { adminAnalytics } = useSelector((state) => state.analytics);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      const { EmpDesignation, id } = user;
      if (EmpDesignation === 'Admin') {
        dispatch(getAdminAnalyticsAPI({ id }));
      }
    } else {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (adminAnalytics && adminAnalytics.length > 0) {
      let oldAnalytics = [...adminAnalytics];
      oldAnalytics = oldAnalytics.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );

      setSortedList([...oldAnalytics]);
      const latestValue = oldAnalytics[oldAnalytics.length - 1];
      try {
        const { countUsr, countGoal, activeMerchantProd, goalSavings } =
          latestValue;
        setCardContent([
          {
            title: 'Users',
            count: countUsr,
          },
          {
            title: 'Goals Created',
            count: countGoal,
          },
          {
            title: 'Active Merchant Products',
            count: activeMerchantProd,
          },
          {
            title: 'Goal Savings',
            count: goalSavings,
          },
        ]);
      } catch (error) {}
    }
  }, [adminAnalytics]);

  const getUserDetails = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser();
      const { signInUserSession } = res;
      const { idToken } = signInUserSession;
      const payload = idToken?.payload;
      const roles = payload['cognito:groups'];
      const id = payload['custom:employeeId'];
      if (roles.includes('Admin')) {
        dispatch(getAdminAnalyticsAPI({ id }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const insightPoints = [
    'Users are up 20% from last week',
    'The Saathi Mobile App has been updated (Version 1.3)',
    'Info about Social Cause Saathi supporting- ?',
    '250 new goals added today!',
  ];
  return (
    <div className="container p-10">
      <div className="title-block">
        <h1 className="text-[25px] md:text-[32px]">
          Hey {user?.EmpGivenName},
        </h1>
        <p className="">Welcome back to your Saathi Dashboard</p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-6 mt-[27px]">
        {cardContent.map((ele, id) => {
          return (
            <div
              onClick={() => setActive(id)}
              key={`merchant_info_card_${id}`}
              className={`w-full h-[124px] rounded-[8px] cursor-pointer flex flex-col border justify-center pl-4 ${
                active === id
                  ? 'bg-lightGreen border-saathiGreen'
                  : 'bg-lightGray border-inputBorder'
              }`}
            >
              <div className="text-[18px]">{ele.title}</div>
              <div className="text-[36px] font-bold">{ele.count}</div>
            </div>
          );
        })}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 mt-7">
        <div className="h-[400px] col-span-1 lg:col-span-9">
          <div className="flex w-full justify-between">
            <h2 className="text-[28px] font-bold">
              {cardContent[active].title}
            </h2>
            <div className="text-[18px] text-darkGray">
              <span
                onClick={() => setActiveTPeriod(null)}
                className={`mx-1 cursor-pointer ${
                  !activeTPeriod && 'text-black underline'
                }`}
              >
                All
              </span>
              <span
                onClick={() => setActiveTPeriod('6m')}
                className={`mx-1 cursor-pointer ${
                  activeTPeriod === '6m' && 'text-black underline'
                }`}
              >
                6m
              </span>
              <span
                onClick={() => setActiveTPeriod('30d')}
                className={`mx-1 cursor-pointer ${
                  activeTPeriod === '30d' && 'text-black underline'
                }`}
              >
                30d
              </span>
              <span
                onClick={() => setActiveTPeriod('7d')}
                className={`mx-1 cursor-pointer ${
                  activeTPeriod === '7d' && 'text-black underline'
                }`}
              >
                7d
              </span>
            </div>
          </div>
          <div>
            <Chart
              activeTPeriod={activeTPeriod}
              active={cardContent[active].title}
              datapoints={sortedList}
            />
          </div>
        </div>
        <div className="bg-lightGreen border border-saathiGreen px-8 my-auto flex flex-col justify-evenly h-[280px] col-span-1 lg:col-span-3 rounded-lg">
          <h3 className="text-[18px] font-bold mx-auto lg:m-0">Insights</h3>
          <ul className="list-disc mx-auto lg:m-0">
            {insightPoints.map((ele, i) => {
              return (
                <li className="text-[13px] mb-[16px]" key={i}>
                  {ele}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="mt-5">
        <h2 className="text-[28px]">Top Picks for you</h2>
        <div className="flex">
          <div
            style={{ backgroundImage: `url(${article1})` }}
            className="w-[386px] h-[231px] rounded-lg flex flex-col justify-end pb-3 pl-5"
          >
            <h3 className="text-white text-[18px] font-bold w-[95%]">
              How to Demotivate Your Best Employees
            </h3>
          </div>
          <div
            style={{ backgroundImage: `url(${article2})` }}
            className="w-[386px] h-[231px] rounded-lg flex flex-col justify-end pb-3 pl-5 mx-4"
          >
            <h3 className="text-white text-[18px] font-bold w-[95%]">
              Why ‘The Customer Is Always Right’ Is Wrong
            </h3>
          </div>
          <div
            style={{ backgroundImage: `url(${article3})` }}
            className="w-[386px] h-[231px] rounded-lg flex flex-col justify-end pb-3 pl-5"
          >
            <h3 className="text-white text-[18px] font-bold w-[95%]">
              10 Ways to Evaluate a Market
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
