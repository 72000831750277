import { Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import UpDownIcon from '../../../assets/images/updownIcon.svg';

function MerchantCategoryDropdown({ label = '', value = '', options = [], req }) {
  const [selectedOpt, setSelectedOpt] = useState('Select an Option..');
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    if (value && value.length > 0) {
      setSelectedOpt(value);
    }
  }, []);

  const handleSelectOption = (val) => {
    setSelectedOpt(val);
    setShowDropDown(false);
  };

  return (
    <div onClick={(e) => e.preventDefault()}>
      <label className='mb-1 block text-sm font-medium text-gray-900'>
        {label}
        {req && <span className='text-red-700'>*</span>}
      </label>
      <input
        type='hidden'
        hidden=''
        readonly=''
        name='merchantCategory'
        value={selectedOpt}
        className='fixed left-[1px] top-[1px] -m-[1px] hidden h-0 w-[1px] overflow-hidden whitespace-nowrap p-0'
        style={{ clip: 'rect(0px, 0px, 0px, 0px)', borderWidth: '0px' }}
      />
      <div className='relative mt-1'>
        <button
          onClick={() => setShowDropDown((prev) => !prev)}
          className='relative flex w-full cursor-default justify-between rounded-lg border border-inputBorder bg-white py-2 pl-3 pr-3 text-left shadow-sidebarActive focus:outline-none sm:text-sm'
        >
          <span
            className={`block truncate ${
              selectedOpt === 'Select an Option..' && 'text-xs text-gray-400'
            }`}
          >
            {selectedOpt.toUpperCase()}
          </span>
          <span className='pointer-events-none flex items-center pr-2'>
            <img src={UpDownIcon} alt='' />
          </span>
        </button>
        <Transition
          show={showDropDown}
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='mt-1 flex h-60 w-full flex-col overflow-y-auto rounded-md border border-inputBorder text-base capitalize'>
            <div
              onMouseEnter={() => setActiveIndex(0)}
              onClick={() => handleSelectOption('Select an Option..')}
              className={`relative cursor-default select-none py-2 pl-10 pr-4 ${
                activeIndex === 0 ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
              }`}
            >
              <span className={`block truncate`}>Select an Option..</span>
            </div>
            {options.map((opt, id) => (
              <div
                onMouseEnter={() => setActiveIndex(id + 1)}
                onClick={() => handleSelectOption(opt.value)}
                className={`relative cursor-default select-none py-2 pl-10 pr-4 ${
                  activeIndex === id + 1 ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                }`}
              >
                <span className={`block truncate`}>{opt.label}</span>
              </div>
            ))}
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default MerchantCategoryDropdown;
