import React from 'react'

function InputField({ label, type = "text", min = 0, value="", name = "",placeholder = "", req, onChangeHandler = (e)=>{ } }) {
  return (
      <div>
          <label htmlFor={label} className="block text-sm font-medium text-gray-700">
              {label}

              {req && (
                  <span className="text-red-700">
                      *
                  </span>
              )}
          </label>
          <div className="relative mt-1 rounded-md shadow-sidebarActive w-full">
              <input
                  type={type}
                  required={req}
                  onChange={onChangeHandler}
                  defaultValue={value}
                  name={name}
                  min={min}
                  step="any"
                  className="block w-full h-10 rounded-md border border-inputBorder pl-7 pr-7 outline-none sm:text-sm"
                  placeholder={placeholder}
              />
          </div>
      </div>
  )
}

export default InputField