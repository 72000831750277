import React from 'react'
import { Auth } from 'aws-amplify'
import settings from '../../assets/images/settings.svg'
import logout from '../../assets/images/logout.svg'
function UserDropdown({ setActive }) {
    const handleLogout = async () => {
        try {
            sessionStorage.removeItem("saathi_accessToken")
            sessionStorage.removeItem("saathi_idToken")
            window.location.href = "/"
        } catch (error) {
            console.log(error);
        }
    }
    const dropdownClass = "h-[89px] w-[140px] flex flex-col justify-around items-center rounded-lg bg-white shadow-sidebarActive absolute bottom-[50px] left-[20px]"

    return (
        <div className={dropdownClass}>
            <div onClick={() => { setActive("Settings") }} className="flex w-[84px] cursor-pointer">
                <img width={17} height={17} src={settings} alt="" />
                <p className='ml-[5px]'>Settings</p>
            </div>
            <div onClick={handleLogout} className="flex w-[84px] cursor-pointer">
                <img width={17} height={17} src={logout} alt="" />
                <p className='ml-[5px]'>Logout</p>
            </div>
        </div>
    )
}

export default UserDropdown