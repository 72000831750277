import React, { useEffect, useState } from 'react'

function InfoMenu({ content, left, top, handleClose }) {
  const [leftTranslate, setLeftTranslate] = useState(left);
  
  useEffect(() => {
    if (window.innerWidth > 700) {
      setLeftTranslate(left * 0.5);
    } else if (window.innerWidth < 700) {
      setLeftTranslate(left*0.1);
    }
    const table = document.getElementById("main-container");
    window.addEventListener("resize",() => {
      if(window.innerWidth >700 && window.innerWidth < 1400){
        setLeftTranslate(left*0.5);
      }else if (window.innerWidth < 700){
        setLeftTranslate(left*0.5);
      }
    })
    
  }, [left])

  const styleClasses = `w-[300px] sm:w-[500px] min-h-[100px] max-h-[600px] fixed flex rounded-lg bg-dropdown p-5  shadow-modal transition-transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`

  return (
    <div className={styleClasses}>
        <div className='flex items-center w-full whitespace-pre-line overflow-auto'>
            { content }
        </div>
      <div onClick={handleClose} className='ml-3 cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x-filled" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" strokeWidth="0" fill="currentColor"></path>
        </svg>
      </div>
    </div>
  )
}

export default InfoMenu