import { useEffect, useState } from 'react';
import upIcon from '../../../../assets/images/upIcon.svg';
import {
  deleteCategoryFromDB,
  listAllCategories,
  sortCategoriesAlphabetically,
} from '../utils';
import filterLogo from '../../../../assets/images/filterLogo.svg';
import downloadLogo from '../../../../assets/images/downloadLogo.svg';
import createLogo from '../../../../assets/images/createLogo.svg';
import { classify } from '../../../../common/Utility';
import AddCategoriesModal from './AddCategoriesModal/AddCategoriesModal';
import Pagination from '../../../../sharedComponents/Pagination/Pagination';
import PaginationNew from '../../../../sharedComponents/Pagination/PaginationNew';

const BucketListCategories = ({
  allCategories,
  setAllCategories,
}: {
  allCategories: IdeaCategory[];
  setAllCategories: (newCategories: IdeaCategory[]) => void;
}) => {
  const [pageCategories, setPageCategories] = useState<IdeaCategory[]>([]);
  const [showAddCategoriesModal, setShowAddCategoriesModal] = useState(false);

  async function processDeleteCategory(categoryId: string) {
    deleteCategoryFromDB(categoryId).then((res) => {
      setAllCategories(
        sortCategoriesAlphabetically([
          ...allCategories.filter(
            (existingCategory) => existingCategory.category_id !== categoryId
          ),
        ])
      );
    });
  }

  const tableHeadingClasses =
    'text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-start bg-tableHeading';
  const tableCellClasses =
    'min-h-[40px] flex items-center justify-start [&_*]:truncate';

  return (
    <div>
      <div className="w-full flex justify-between items-center min-w-max">
        <h1 className="text-[32px] font-bold m-[40px]">Categories</h1>
        <div className="w-[200px] mr-10 flex justify-around">
          {/* <div className="cursor-pointer">
            <img onClick={() => {}} src={filterLogo} alt="" />
          </div>
          <div className="cursor-pointer">
            <img onClick={() => {}} src={downloadLogo} alt="" />
          </div> */}
          <div
            className="cursor-pointer"
            onClick={() => {
              // OPEN MODAL FOR MANUAL INPUT/FILE UPLOAD:
              setShowAddCategoriesModal(true);
            }}
          >
            <img src={createLogo} alt="" />
          </div>
        </div>
      </div>

      <div className="container max-w-full">
        <div className="flex min-w-[1200px]">
          {/* FIXED LHS SECTION OF TABLE */}
          <div className="flex flex-col">
            <div className="flex">
              <div
                className={`text-sm text-darkGray font-bold h-[51px] flex items-center bg-tableHeading justify-start w-[30vw] lg:w-[13vw] ml-10 pl-5 rounded-l-lg`}
              >
                Name
                <img
                  onClick={() => {}}
                  // className={`ml-2 ${
                  //   ascSort[1] ? 'rotate-180' : ''
                  // } cursor-pointer`}
                  // src={upIcon}
                  alt=""
                />
              </div>
            </div>
            {pageCategories.map((cat, i) => {
              return (
                <div className="flex" key={i}>
                  <div
                    className={`justify-start items-start h-[40px] flex truncate w-[30vw] lg:w-[13vw] ml-10 pl-5`}
                  >
                    <div className="w-[95%] overflow-auto">
                      {cat.category_name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* HORIZONTAL SCROLLING SECTION OF TABLE: */}
          <div
            className={classify(['flex flex-col w-[800px] overflow-x-auto'])}
          >
            {/* DATA HEADERS: */}
            <div className="flex w-fit">
              <div className={`${tableHeadingClasses} w-[75vw] lg:w-[25vw]`}>
                Description
              </div>
              <div className={`${tableHeadingClasses} w-[35vw] lg:w-[15vw]`}>
                Image URL(Vertical)
              </div>
              <div
                className={`${tableHeadingClasses} justify-center md:justify-start w-[35vw] lg:w-[15vw]`}
              >
                Image URL(Horizontal)
              </div>
              <div
                className={`${tableHeadingClasses} justify-center md:justify-start w-[35vw] lg:w-[15vw]`}
              >
                Merchant ID
              </div>

              {/* 'DELETE' button column: */}
              <div
                className={classify([tableHeadingClasses, 'w-[200px]'])}
              ></div>
            </div>

            {/* ACTUAL DATA LINES: */}
            {pageCategories.map((cat, i) => {
              return (
                <div className="flex w-fit" key={i}>
                  <div
                    className={`${tableCellClasses} w-[75vw] lg:w-[25vw] justify-center`}
                  >
                    <p style={{ width: '95%' }}>{cat.category_description}</p>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[35vw] lg:w-[15vw]',
                    ])}
                  >
                    <span className="">{cat.image_vertical_url}</span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[35vw] lg:w-[15vw]',
                    ])}
                  >
                    <span>{cat.image_horizontal_url}</span>
                  </div>
                  <div
                    className={classify([
                      tableCellClasses,
                      'w-[35vw] lg:w-[15vw]',
                    ])}
                  >
                    <span>{cat.merchant_id}</span>
                  </div>

                  {/* 'DELETE' button: */}
                  <div className={classify([tableCellClasses, 'w-[200px]'])}>
                    <span
                      className={classify([
                        'text-red-500 border border-red-500 p-1 text-sm cursor-pointer hover:bg-red-500 hover:text-white',
                      ])}
                      onClick={() => {
                        processDeleteCategory(cat.category_id);
                      }}
                    >
                      DELETE
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* PAGINATION AT BOTTOM: */}
      <PaginationNew
        type="Categories"
        allFilteredData={allCategories}
        setPageData={setPageCategories}
      />

      {/* 'ADD CATEGORIES' MODAL: */}
      <AddCategoriesModal
        show={showAddCategoriesModal}
        closeModal={() => {
          setShowAddCategoriesModal(false);
        }}
        allExistingCategories={allCategories}
        setAllCategories={setAllCategories}
      />
    </div>
  );
};

export default BucketListCategories;
