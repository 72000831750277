import React from 'react'

function LightCard({ title = "", desc = "" }) {
  return (
    <div className='min-h-[124px] w-full rounded-lg bg-gray-100 shadow-cardShadow flex flex-col gap-3 p-6'>
        <h3>{title}</h3>
        <p className='text-[28px] text-saathiPink'>{desc}</p>
    </div>
  )
}

export default LightCard