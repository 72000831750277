import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import axios from 'axios';
import { docClient } from '../aws-dynamodb-settings';
import { handleAPICall, checkStatusCode, createToken } from './APIUtils'

const initialState = {
    goalsLoading: false,
    goals: [],
    userIdList: [],
    usersList: [],
    goalsError: "",
    totalGoals: 0,
    valueOfGoals: 0,
    totalSavedAmount: 0,
    totalCustomers: 0,
    createConversionLoading: false,
    createConversionError: "",
    updateConversionLoading: false,
    updateConversionError: "",
    goalsMetric: {}
}

async function fetchAllElementsFromDB(params) {
    let scanResults = [];
    let items;
    do {
        items = await docClient.scan(params).promise();
        items.Items.forEach((item) => scanResults.push(item));
        params.ExclusiveStartKey = items.LastEvaluatedKey;
    } while (typeof items.LastEvaluatedKey !== "undefined");

    return scanResults;
}

async function querySingleItemFromDB(params) {
    try {
        const data = await docClient.get(params).promise();
        return data.Item;
    } catch (err) {
        console.error("Error getting item:", err);
        return null;
    }
}

async function queryUserData(id) {
    try {
        const data = await handleAPICall("saathiAPI", `/user/${id}`, {}, "GET");
        if (data.error && Object.keys(data.error).length > 0) {
            return null;
        }
        return data;
    } catch (err) {
        console.error("Error getting item:", err);
        return null;
    }
}

async function getCashKaroConversion(goalId) {
    try {
        // const t = await createToken();
        const TABLE_DEVTWO = "SaathiConversion-nfbore755namdaboqdcvmj7azy-devtwo";
        const TABLE_BURNER = "SaathiConversion-k66lii7zgbhmni3saovaf3j62m-burner";
        const CONVERSIONS_TABLE = process.env.REACT_APP_PUBLIC_ENV === 'production' ? TABLE_BURNER : TABLE_DEVTWO;
        const params = {
            TableName: CONVERSIONS_TABLE,
            FilterExpression: '#goalId = :goalId',
            ExpressionAttributeNames: {
                '#goalId': 'goalId'
            },
            ExpressionAttributeValues: {
                ':goalId': goalId
            }
        }

        const conversionsList = await fetchAllElementsFromDB(params);
        return conversionsList;
    } catch (error) {
        console.error("Error getting item:", error);
        return null;
    }
}

export const listAllMerchantGoalsAPI = createAsyncThunk("goals/merchant", async ({ pageNum = 1, limit = 10, merchantId = "", callback = () => { } }, thunkAPI) => {
    try {

        const SAVINGS_PRODUCT_TABLE = process.env.REACT_APP_PUBLIC_ENV === 'production' ? "SavingProduct-k66lii7zgbhmni3saovaf3j62m-burner" : "SavingProduct-nfbore755namdaboqdcvmj7azy-devtwo"

        const GOALS_TABLE = process.env.REACT_APP_PUBLIC_ENV === 'production' ? "Goal-k66lii7zgbhmni3saovaf3j62m-burner" : "Goal-nfbore755namdaboqdcvmj7azy-devtwo"

        const savingsProductTableParams = {
            TableName: SAVINGS_PRODUCT_TABLE
        }

        let savedProductsList = await fetchAllElementsFromDB(savingsProductTableParams);
        let filteredGoals = [];
        let valueOfGoals = 0;
        let totalSavedAmount = 0;
        let userIdList = [];
        let conversionsList = [];

        await Promise.all(savedProductsList.map(async (ele) => {
            if (ele && ele.goalId) {
                const t = await querySingleItemFromDB({
                    TableName: GOALS_TABLE,
                    Key: {
                        id: ele.goalId
                    }
                });

                if (t && t.goalStatus) {
                    if (t?.userId) {
                        const d = new Date(t?.createdAt).toDateString();

                        userIdList.push(t?.userId);
                        userIdList = userIdList.concat(t?.sharedUsersId || []);

                    }
                    const { netGoalAmount, savedAmount } = t;
                    const c = await getCashKaroConversion(ele.goalId);
                    if (c && c.length > 0) {
                        conversionsList.push(c[0])
                        filteredGoals.push({ ...t, conversions: c[0] });
                    } else {
                        filteredGoals.push({ ...t, conversions: {} });
                    }
                    valueOfGoals += netGoalAmount;
                    totalSavedAmount += savedAmount;
                }
            }
        }));
        const finalUserIdList = new Set(userIdList);
        const totalGoals = filteredGoals.length;
        let finalUserList = [];

        await Promise.all(Array.from(finalUserIdList).map(async (ele) => {
            try {
                const u = await queryUserData(ele);
                if (u) {
                    finalUserList.push(u);
                }
            } catch (error) {
                console.log(error);
            }
        }));

        return { data: filteredGoals, totalGoals, valueOfGoals, totalSavedAmount, totalCustomers: finalUserIdList.size, userIdList: Array.from(finalUserIdList), usersList: finalUserList };
    } catch (error) {
        console.log(error);
    }
})

export const createGoalConversionStatusAPI = createAsyncThunk("conversion/create", async ({ body, callback = () => { } }, thunkAPI) => {
    try {
        const goalId = body["goalId"];
        const options = {
            body: body
        }
        const res = await handleAPICall("merchantApi", "/merchant/conversion", options, "POST");
        callback(res);
        if (res.error && Object.keys(res.error).length > 0) {
            return thunkAPI.rejectWithValue("Create Goal Conversion Failed");
        }
        return { data: res, goalId: goalId };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateConversionStatusAPI = createAsyncThunk("conversion/update", async ({ body, conversionId, callback = () => { } }, thunkAPI) => {
    try {
        const goalId = body["goalId"];

        const options = {
            body: body
        }
        const res = await handleAPICall("merchantApi", `/merchant/conversion/${conversionId}`, options, "UPDATE");
        callback(res, true);
        if (res.error && Object.keys(res.error).length > 0) {
            return thunkAPI.rejectWithValue("Create Goal Conversion Failed");
        }
        return { data: res, goalId: goalId, conversionId: conversionId };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const goalsSlice = createSlice({
    name: "Goals",
    initialState,
    reducers: {
        updateGoalsList: (state, action) => {
            state.goals = action.payload;
        },
        updateGoalsMetric: (state, action) => {
            state.goalsMetric = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listAllMerchantGoalsAPI.pending, (state) => {
            state.goalsLoading = true;
            state.goalsError = ""
        })

        builder.addCase(listAllMerchantGoalsAPI.fulfilled, (state, action) => {
            state.goalsLoading = false;
            state.goals = action.payload.data;
            state.totalGoals = action.payload.totalGoals;
            state.valueOfGoals = action.payload.valueOfGoals;
            state.totalSavedAmount = action.payload.totalSavedAmount;
            state.totalCustomers = action.payload.totalCustomers;
            state.userIdList = action.payload.userIdList;
            state.usersList = action.payload.usersList;
        })

        builder.addCase(listAllMerchantGoalsAPI.rejected, (state, action) => {
            state.goalsLoading = false;
            state.goalsError = action.payload;
        })

        builder.addCase(createGoalConversionStatusAPI.pending, (state) => {
            state.createConversionError = "";
            state.createConversionLoading = true;
        })

        builder.addCase(createGoalConversionStatusAPI.fulfilled, (state, action) => {
            state.createConversionLoading = false;
            let currentGoals = [...state.goals];
            const idx = currentGoals.findIndex(e => e.id === action.payload.goalId);
            if (idx !== -1) {
                currentGoals[idx].conversions = action.payload.data;
            }
        })

        builder.addCase(createGoalConversionStatusAPI.rejected, (state, action) => {
            state.createConversionLoading = false;
            try {
                state.createConversionError = action.payload.error.response.data.error.message
            } catch (error) {
                state.createConversionError = "Create Goal Conversion Failed"
            }
        })

        builder.addCase(updateConversionStatusAPI.pending, (state) => {
            state.updateConversionLoading = true;
            state.updateConversionError = ""
        })
        builder.addCase(updateConversionStatusAPI.fulfilled, (state, action) => {
            state.updateConversionLoading = false;
            let currentGoals = [...state.goals];
            const idx = currentGoals.findIndex(e => e.id === action.payload.goalId);
            if (idx !== -1) {
                currentGoals[idx].conversions = action.payload.data;
            }
        })
        builder.addCase(updateConversionStatusAPI.rejected, (state, action) => {
            state.updateConversionLoading = false;
            try {
                state.updateConversionError = action.payload.error.response.data.error.message
            } catch (error) {
                state.updateConversionError = "Update Goal Conversion Failed"
            }
        })
    }
})
export const { updateGoalsList, updateGoalsMetric } = goalsSlice.actions;
export default goalsSlice.reducer;