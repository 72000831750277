import { useState } from 'react';
import { classify } from '../../../../../common/Utility';
import { handleCategoryFileUpload } from '../../utils';
import * as csv from 'csvtojson';
import * as XLSX from 'xlsx';

export type IdeaInput = {
  name?: string;
  description?: string;
  merchantId?: string;
  horizontalImageUrl?: string;
  verticalImageUrl?: string;
};

const ManualAddIdeas = () => {
  const [ideas, setIdeas] = useState([{}]);
  console.log('CATEGORIES', ideas);

  function addCategory() {
    console.log('ADDING CATEGORY');
    setIdeas([...ideas, {}]);
  }

  async function processSubmit() {
    // await uploadCategories();
  }

  // async function handleFileUpload(e) {
  // }

  return (
    <div className="flex flex-col items-start border border-blue-600 p-6">
      <div className="flex flex-col gap-8 mb-4 w-full">
        {ideas.map((idea, i) => {
          return (
            <AddIdeaInput
              key={i}
              processInput={(updatedValue: IdeaInput) => {
                ideas[i] = {
                  ...ideas[i],
                  ...updatedValue,
                };
                setIdeas([...ideas]);
              }}
            />
          );
        })}
      </div>
      <button
        onClick={addCategory}
        className="rounded-lg mb-8 border p-3 border-blue-600"
      >
        Add Another Idea
      </button>

      <button
        onClick={processSubmit}
        className={classify(['border border-red-600 rounded-lg p-3'])}
      >
        SUBMIT
      </button>
    </div>
  );
};
export default ManualAddIdeas;

const AddIdeaInput = ({
  processInput,
}: {
  processInput: (updatedValue: IdeaInput) => void;
}) => {
  return (
    <div
      className={classify([
        ' border-red-500 pb-4 border-b flex flex-col',
        '[&_input]:w-80 [&_input]:border [&_input]:border-blue-500',
        '[&_textarea]:border [&_textarea]:border-blue-500 [&_textarea]:w-80',
      ])}
    >
      <span>Name:</span>
      <input
        onChange={(e) => {
          processInput({
            name: e.target.value,
          });
        }}
        maxLength={10}
        className="inline-block w-44"
      />

      <span>Description:</span>
      <textarea
        onChange={(e) => {
          processInput({
            description: e.target.value,
          });
        }}
      />

      <span>Merchant ID</span>
      <input
        onChange={(e) => {
          processInput({
            merchantId: e.target.value,
          });
        }}
      />

      <input
        type="radio"
        name="verticalImage"
        value="URL"
        id="verticalImageUrl"
        onClick={(e) => {
          console.log('INPUT RADIO CHANGE');
        }}
      />
      <label htmlFor="verticalImageUrl">URL</label>
      <input
        type="radio"
        name="verticalImage"
        value="Unsplash"
        id="verticalImageUnsplash"
        onClick={() => {
          console.log('INPUT RADIO CHANGE');
        }}
      />
      <label htmlFor="verticalImageUnsplash">Unsplash</label>
    </div>
  );
};
