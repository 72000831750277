import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import toast from 'react-hot-toast'
import { createUserAPI, setUserError, updateUserAPI } from '../../../api/userSlice';
import { listMerchantData } from '../../../api/merchantSlice';
import InputField from '../../../sharedComponents/InputField/InputField'
import ProductParameterInput from '../Product/ProductParameterInput';
import SelectMenu from '../../../sharedComponents/SelectMenu/SelectMenu';

function CreateUserModal({ setOpenCreateModal, openCreateModal, editFlag, setEditFlag }) {
    const dispatch = useDispatch();
    const [merchants, setMerchants] = useState([]);
    const { currentUser, error } = useSelector(
        state => state.users
    )
    const { merchantList } = useSelector(state => state.merchant);
    useEffect(() => {
        if (merchantList && merchantList.length > 0) {
            let opts = [];
            merchantList.map((ele, id) => {
                opts.push({
                    label: ele.merchant_name,
                    value: ele.id
                })
            })
            setMerchants([...opts]);
        } else {
            dispatch(listMerchantData());
        }
    }, [])
    const onClose = () => {
        setOpenCreateModal(false);
        setEditFlag(false);
    }
    const callback = (data, flag = false) => {
        setOpenCreateModal(false);
        if (data.errors && Object.keys(data.errors).length > 0) {
            try {
                toast.error(`${data.error.error.response.data.error.message}`);
            } catch (error) {
                toast.error(`Error while Editing User`);
            }
            return;
        }
        toast.success("User info Edited successfully")
    }
    const onSubmitForm = async (e) => {
        if (!openCreateModal) {
            e.preventDefault();
            return;
        }
        e.preventDefault();
        try {
            const formElements = e.target.elements;
            let formObj = {};
            if (editFlag) {
                formObj["id"] = currentUser["merchant_user_id"];
            }
            for (let i = 0; i < formElements.length; i++) {
                const ele = formElements[i];
                const name = ele.name;
                if (name.length > 0) {
                    if (name === 'is_primary_user' || name === 'is_active') {
                        formObj[name] = ele.checked;
                        continue;
                    }
                    formObj[name] = ele.value;
                }
            }
            if (editFlag) {
                dispatch(updateUserAPI({ body: formObj, callback }));
            } else {
                dispatch(createUserAPI({ body: formObj, callback }));
            }
            onClose();

        } catch (error) {
            dispatch(setUserError(error.message));
        }
    }
    const userTypeItem = [
        {
            label: "User",
            value: "user"
        },
        {
            label: "Admin",
            value: "admin"
        }
    ]

    return (
        <div id='create-user-modal' className='absolute top-1/2 left-1/2 w-[90vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'>
            <div className="container p-3 py-6 flex flex-col items-center md:p-10">
                <h1 className="text-[24px] mb-4">
                    {editFlag ? "Edit User" : "Create User"}
                </h1>
                <form onSubmit={onSubmitForm} className="w-full flex flex-col items-center">
                    {editFlag ? <></> : (
                        <div className="w-full md:w-4/5">
                            <SelectMenu name='merchant_id' value={currentUser?.merchant_id} label='Merchant Name' options={merchants} />
                        </div>
                    )}
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='first_name' value={currentUser?.first_name} label="First Name" req />
                        </div>
                        <div className='w-full'>
                            <InputField name='last_name' value={currentUser.last_name} label="Last Name" req />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <SelectMenu name='user_type' value={currentUser?.user_type} label='User Type' options={userTypeItem} />
                        </div>
                        <div className='flex w-4/5 items-center h-10 mt-4'>
                            <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox" name="is_primary_user" defaultChecked={editFlag && currentUser?.is_primary_user} id="primary_user" value="true" />
                            <div className='text-sm ml-3'>
                                Is Primary User?
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='country_code' value={currentUser?.country_code} label="Country Code" type="number" req />
                        </div>
                        <div className='w-full'>
                            <InputField name='mobile_number' type='number' value={currentUser.mobile_number} label="Mobile Number" req />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full'>
                            <InputField name='email_address' value={currentUser?.email_address} label="Email Address" type="email" req />
                        </div>
                        <div className='flex w-4/5 items-center h-10 mt-4'>
                            <input className='bg-white shadow-sidebarActive w-5 h-5 rounded-md border border-inputBorder outline-none' type="checkbox" name="is_active" defaultChecked={editFlag && currentUser?.is_active} id="active_user" value="true" />
                            <div className='text-sm ml-3'>
                                Is Active?
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4">
                        <div className='w-full md:w-full'>
                            <button onClick={() => onClose()} className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center' >
                                Cancel
                            </button>
                        </div>
                        <div className='w-full md:w-full'>
                            <button type='submit' className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full flex justify-center items-center' >
                                {editFlag ? "Save" : "Create"}
                            </button>
                        </div>
                    </div>

                    {(error && error.length > 0) && (
                        <div className='block text-sm text-red-600'>{error}</div>
                    )}
                </form>
            </div>
        </div>
    )
}

export default CreateUserModal