import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { handleAPICall, checkStatusCode } from './APIUtils';
import { listArticless } from '../graphql/queries';
import { fetchAllDataFromGraphQLQuery } from '../common/Utility';

const initialState = {
  articleLoading: false,
  articleError: '',
  articles: [],
  currentArticle: {},
  status: null,
  createArticleStatus: null,
};

export const listArticlesAPI = createAsyncThunk(
  'articles/listArticles',
  async (_, thunkAPI) => {
    try {
      const res = await fetchAllDataFromGraphQLQuery({
        query: listArticless,
        variables: {
          limit: 2000,
        },
        queryName: 'listArticless',
      });
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createArticleAPI = createAsyncThunk(
  'articles/createArticle',
  async ({ body, callback = () => {} }, thunkAPI) => {
    try {
      const options = {
        body: body,
      };
      const data = await handleAPICall(
        'saathiAPI',
        '/articles',
        options,
        'POST',
      );
      callback(data);

      if (data.error && Object.keys(data.error).length > 0) {
        return thunkAPI.rejectWithValue(data.error || 'Something went wrong!');
      }
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateArticleAPI = createAsyncThunk(
  'articles/updateArticle',
  async ({ body, callback = () => {} }, thunkAPI) => {
    try {
      const id = body['ID'];
      delete body.ID;
      const options = {
        body: body,
      };
      const data = await handleAPICall(
        'saathiAPI',
        `/articles/${id}`,
        options,
        'UPDATE',
      );

      callback(data, true);
      if (data.error && Object.keys(data.error).length > 0) {
        return thunkAPI.rejectWithValue(
          data.error[0].message || 'Something went wrong!',
        );
      }
      return { ...data, id: id };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const articleSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    saveCurrentArticle: (state, action) => {
      state.currentArticle = action.payload;
    },
    setArticleError: (state, action) => {
      state.articleError = action.payload;
    },
    updateArticles: (state, action) => {
      state.articles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listArticlesAPI.pending, (state) => {
      state.articleLoading = true;
      state.articleError = '';
      state.status = 'loading';
    });
    builder.addCase(listArticlesAPI.fulfilled, (state, action) => {
      state.articleLoading = false;
      state.articleError = '';
      state.articles = action.payload;
      state.status = 'success';
    });
    builder.addCase(listArticlesAPI.rejected, (state, action) => {
      state.articleLoading = false;
      state.status = 'error';
      try {
        state.articleError = action.payload.error.response.data.error.message;
      } catch (error) {
        state.articleError = 'Something went Wrong';
      }
    });
    builder.addCase(createArticleAPI.pending, (state) => {
      state.articleLoading = true;
      state.createArticleStatus = 'loading';
    });
    builder.addCase(createArticleAPI.fulfilled, (state, action) => {
      state.articleLoading = false;
      state.articleError = '';
      state.createArticleStatus = 'success';
      let oldArticles = [...state.articles];
      oldArticles.push(action.payload.data);
      state.articles = oldArticles;
    });
    builder.addCase(createArticleAPI.rejected, (state, action) => {
      state.articleLoading = false;
      state.createArticleStatus = 'error';
      try {
        state.articleError =
          action.payload.error.response.data.errors[0].message;
      } catch (error) {
        state.articleError = 'Something went Wrong';
      }
    });

    builder.addCase(updateArticleAPI.pending, (state) => {
      state.articleLoading = false;
      state.articleError = '';
    });

    builder.addCase(updateArticleAPI.fulfilled, (state, action) => {
      state.articleError = '';
      let oldArr = [...state.articles];
      try {
        const { id, data } = action.payload;
        for (let i = 0; i < oldArr.length; i++) {
          const element = oldArr[i];
          if (element.id === id) {
            oldArr[i] = data;
            break;
          }
        }
      } catch (error) {}
      state.articles = oldArr;
      state.articleLoading = false;
    });

    builder.addCase(updateArticleAPI.rejected, (state, action) => {
      state.articleLoading = false;
      try {
        state.articleError = action.payload.error.response.data.error.message;
      } catch (error) {
        state.articleError = 'Something went Wrong';
      }
    });
  },
});
export const { saveCurrentArticle, setArticleError, updateArticles } =
  articleSlice.actions;
export default articleSlice.reducer;
