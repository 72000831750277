import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { v1 as uuidv1 } from 'uuid';
import toast from 'react-hot-toast';
import * as csv from 'csvtojson';
import { docClient } from '../aws-dynamodb-settings';

function Upload() {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [typeOfData, setTypeOfData] = useState('articles');

  const env = process.env.REACT_APP_ENVIRONMENT; //"production"; // 'production'

  const ARTICLES_TABLE = 'Articles-nfbore755namdaboqdcvmj7azy-devtwo';
  const MERCHANTS_TABLE = 'Merchant-nfbore755namdaboqdcvmj7azy-devtwo';
  const PRODUCTS_TABLE = 'MerchantProduct-nfbore755namdaboqdcvmj7azy-devtwo';
  const CATEGORY_TABLE =
    'MerchantProductCategory-nfbore755namdaboqdcvmj7azy-devtwo';

  const ARTICLES_TABLE_PROD = 'Articles-k66lii7zgbhmni3saovaf3j62m-burner';
  const MERCHANTS_TABLE_PROD = 'Merchant-k66lii7zgbhmni3saovaf3j62m-burner';
  const PRODUCTS_TABLE_PROD =
    'MerchantProduct-k66lii7zgbhmni3saovaf3j62m-burner';
  const CATEGORY_TABLE_PROD =
    'MerchantProductCategory-k66lii7zgbhmni3saovaf3j62m-burner';

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const convertCSVToArray = async (string) => {
    try {
      const jsonObj = await csv()
        .fromString(string)
        .subscribe((data) => {
          data['createdAt'] = new Date().toISOString();
          data['updatedAt'] = new Date().toISOString();
          if (typeOfData === 'merchant') {
            data['isMaxOffer'] = parseInt(data['isMaxOffer'] || 0);
            data['isReviewed'] = parseInt(data['isReviewed'] || 0);
          } else if (typeOfData === 'product') {
            data['featured'] = Boolean(data['featured']);
            data['isExpired'] = Boolean(data['isExpired']);
            data['cashkaroOfferList'] = data['cashkaroOfferList']
              ? JSON.parse(data['cashkaroOfferList'])
              : [];
            data['productParameters'] = data['productParameters']
              ? JSON.parse(data['productParameters'])
              : {};
            data['productTags'] = data['productTags']
              ? JSON.parse(data['productTags'])
              : [];
            data['relatedArticles'] = data['relatedArticles']
              ? JSON.parse(data['relatedArticles'])
              : {};
            data['deliveryFee'] = parseInt(data['deliveryFee']);
            data['deliveryPackingChange'] = parseInt(
              data['deliveryPackingChange']
            );
            data['packingFee'] = parseInt(data['packingFee']);
            data['price'] = parseInt(data['price']);
            data['productOffer'] = parseInt(data['productOffer']);
          }
          if (!data['id'] || data['id'].length <= 0) {
            data['id'] = uuidv1();
          }
        });
      setArray(jsonObj);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setError('');
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        convertCSVToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const createData = async (items) => {
    let table = ARTICLES_TABLE;
    if (env === 'development') {
      switch (typeOfData) {
        case 'articles':
          table = ARTICLES_TABLE;
          break;
        case 'merchant':
          table = MERCHANTS_TABLE;
          break;
        case 'product':
          table = PRODUCTS_TABLE;
          break;
        case 'categories':
          table = CATEGORY_TABLE;
          break;
        default:
          break;
      }
    } else {
      switch (typeOfData) {
        case 'articles':
          table = ARTICLES_TABLE_PROD;
          break;
        case 'merchant':
          table = MERCHANTS_TABLE_PROD;
          break;
        case 'products':
          table = PRODUCTS_TABLE_PROD;
          break;
        case 'categories':
          table = CATEGORY_TABLE_PROD;
          break;
        default:
          break;
      }
    }
    setLoading(true);
    const params = {
      RequestItems: {
        [`${table}`]: items.map((item) => ({
          PutRequest: {
            Item: item,
          },
        })),
      },
    };

    docClient.batchWrite(params, (err, data) => {
      if (err) {
        console.log(err.message);
        setError(err.message);
      } else {
        const { UnprocessedItems } = data;
        if (Object.keys(UnprocessedItems).length === 0) {
          toast.success('Items Added Successfully');
          setError('');
        } else {
          toast.error(
            `${JSON.stringify(UnprocessedItems)} items not processed`
          );
          setError(`${JSON.stringify(UnprocessedItems)} items not processed`);
        }
      }
      setLoading(false);
    });
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  const dashboardLayoutClasses = 'p-4 border-1 h-full absolute left-0 w-full';

  return (
    <>
      <div className={dashboardLayoutClasses}>
        <div className="w-full h-full mb-14">
          <div className="container max-w-full">
            <h1 className="text-[32px] font-bold m-[40px]">CSV DATA UPLOAD </h1>
            {error.length > 0 && (
              <div className="p-3 text-red-600">{error}</div>
            )}
            <form>
              <label htmlFor="type" className="mr-2">
                Type of Data
              </label>
              <select
                className="border border-black"
                value={typeOfData}
                onChange={(e) => setTypeOfData(e.target.value)}
                name="type"
              >
                <option value="merchant">Merchant</option>
                <option value="articles">Articles</option>
                <option value="product">Products</option>
                <option value="categories">Merchant Product Categories</option>
              </select>
              <br />

              {/* 'Choose file' button: */}
              <input
                className="my-4"
                type={'file'}
                id={'csvFileInput'}
                accept={'.csv'}
                onChange={handleOnChange}
              />

              <br />
              <button
                className="flex my-4 items-center justify-center h-8 p-4 border border-black"
                onClick={(e) => {
                  handleOnSubmit(e);
                }}
              >
                IMPORT CSV
              </button>
            </form>

            {loading ? (
              <>
                <TailSpin
                  height="120"
                  width="120"
                  color="#62A6FF"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              </>
            ) : (
              <>
                {array.length > 0 ? (
                  <>
                    <div>
                      <h1>Add Data to Database</h1>
                      <button
                        className="flex items-center justify-center h-8 p-4 border border-black"
                        onClick={() => createData(array)}
                      >
                        Submit
                      </button>
                    </div>

                    <br />

                    <div className="flex flex-col justify-center text-center flex-1 pb-12 min-w-max">
                      <div className="w-full flex mx-4">
                        <div className="text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-center bg-tableHeading min-w-[280px] border border-1 max-w-[300px]">
                          S.No
                        </div>
                        {headerKeys.map((key) => (
                          <div className="text-sm text-darkGray font-bold min-h-[51px] flex items-center justify-center bg-tableHeading min-w-[280px] border border-1 max-w-[300px]">
                            {key}
                          </div>
                        ))}
                      </div>
                      <>
                        {array.map((item, idx) => (
                          <div className="w-full flex my-3">
                            <div className="min-h-[40px] mx-4 flex items-center justify-start">
                              <td className="min-w-[280px] border border-1 max-w-[300px] truncate">
                                {idx + 1}
                              </td>
                              {Object.values(item).map((val) => {
                                let content = val;
                                if (typeof val === 'object') {
                                  content = JSON.stringify(val);
                                }
                                if (typeof val === 'boolean') {
                                  content = val ? 1 : 0;
                                }
                                return (
                                  <td className="min-w-[280px] border border-1 max-w-[300px] truncate">
                                    {content}
                                  </td>
                                );
                              })}
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </>
                ) : (
                  <>
                    <h3>Please add your data</h3>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Upload;
