import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import InputField from '../../../sharedComponents/InputField/InputField';

import {
  createArticleAPI,
  updateArticleAPI,
  setArticleError,
} from '../../../api/articleSlice';

const OPENAI_API_KEY = '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';
const API_URL = 'https://api.openai.com/v1/chat/completions';

function CreateArticleModal({
  setOpenCreateModal,
  editFlag,
  setEditFlag,
  openCreateModal,
}) {
  const dispatch = useDispatch();
  const { currentArticle, articleError } = useSelector(
    (state) => state.articles
  );

  const generate = async (e) => {
    e.preventDefault();
    const form = document.getElementById('article-form');
    const title = form.querySelector('input[name="articleTitle"]');
    const generateBtn = document.getElementById('autogenerate-btn');
    const articleBox = document.getElementById('articleContent');
    articleBox.innerText = 'Generating...';
    if (title.value === '') {
      toast.error('Please add a valid title');
      return;
    }
    const prompt = `Create HTML section content with an intro paragraph and bullet points about why you should add a ${title.value} to your bucket list, and a suggested budget for an Indian person in rupees.`;

    try {
      // Fetch the response from the OpenAI API with the signal from AbortController
      generateBtn.disabled = true;
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: prompt }],
          stream: true,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      articleBox.innerText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        // Massage and parse the chunk of data
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        const parsedLines = lines
          .map((line) => line.replace(/^data: /, '').trim())
          .filter((line) => line !== '' && line !== '[DONE]')
          .map((line) => JSON.parse(line));

        for (const parsedLine of parsedLines) {
          const { choices } = parsedLine;
          const { delta } = choices[0];
          const { content } = delta;
          // Update the UI with the new content
          if (content) {
            articleBox.innerText += content;
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      generateBtn.disabled = false;
      articleBox.innerText = '';
      toast.error('Error occurred while generating.');
    } finally {
      generateBtn.disabled = false;
    }
  };

  const callback = (data, flag = false) => {
    if (data.status.toLowerCase() === 'success') {
      toast.success(`Article ${flag ? 'Edited' : 'Created'} Successfully`);
    }
    if (data.error && Object.keys(data.error).length > 0) {
      toast.error(`Error while ${flag ? 'Editing' : 'Creating'} Article`);
    }
  };

  const onSubmitForm = (e) => {
    if (!openCreateModal) {
      e.preventDefault();
      return;
    }

    if (!e.currentTarget.checkValidity()) {
      toast.error('Please fill the details correctly');
      return;
    }
    e.preventDefault();
    try {
      const formElements = e.target.elements;
      const articleBox = document.getElementById('articleContent');
      let formData = {};
      for (let i = 0; i < formElements.length; i++) {
        const ele = formElements[i];
        const name = ele.name;
        if (name.length > 0) {
          formData[name.trim()] = ele.value;
        }
      }
      formData['articleContent'] = articleBox.innerText;
      if (editFlag) {
        formData['ID'] = currentArticle.id;
        dispatch(updateArticleAPI({ body: formData, callback }));
      } else {
        dispatch(createArticleAPI({ body: formData, callback }));
      }
      setOpenCreateModal(false);
    } catch (error) {
      setOpenCreateModal(true);
      dispatch(setArticleError(error));
    }
  };

  const closeModal = () => {
    dispatch(setArticleError(''));
    setEditFlag(false);
    setOpenCreateModal(false);
  };

  return (
    <div
      id='create-article-modal'
      className='absolute top-1/2 left-1/2 w-[80vw] h-[calc(100vh_-_100px)] md:w-[60vw] rounded-[16px] bg-white shadow-modal overflow-y-auto -translate-x-1/2 -translate-y-1/2'
    >
      <div className='container p-3 py-6 flex flex-col items-center md:p-10'>
        <h1 className='text-[24px] mb-4'>
          {editFlag ? 'Edit Article' : 'Create Article'}
        </h1>
        <form
          id='article-form'
          onSubmit={onSubmitForm}
          className='w-full flex flex-col items-center'
        >
          <div className='grid grid-cols-1 sm:grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4'>
            <div className='w-full'>
              <InputField
                req
                name='id'
                value={!editFlag ? '' : currentArticle?.id}
                label='Article ID'
                placeholder='Ex: a21324632'
              />
            </div>
            <div className='w-full'>
              <InputField
                req
                name='slug'
                value={!editFlag ? '' : currentArticle?.slug}
                label='Article Slug'
              />
            </div>
          </div>
          
          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              name='articleTitle'
              value={!editFlag ? '' : currentArticle?.articleTitle}
              label='Article Title'
              placeholder='Ex: Welcome to Saathi'
            />
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <InputField
              req
              value={!editFlag ? '' : currentArticle?.shortTitle}
              label='Short Title'
              name='shortTitle'
            />
          </div>
          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4'>
            <div className='w-full md:w-full'>
              <InputField
                req
                name='articleUrl'
                value={!editFlag ? '' : currentArticle?.articleUrl}
                placeholder='https://...'
                label='Article URL'
              />
            </div>
            <div className='w-full md:w-full'>
              <InputField
                req
                name='apiUrl'
                value={!editFlag ? '' : currentArticle?.apiUrl}
                placeholder='https://...'
                label='API URL'
              />
            </div>
          </div>

          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-4'>
            <div className='w-full md:w-full'>
              <InputField
                req
                name='imageUrl'
                value={!editFlag ? '' : currentArticle?.imageUrl}
                placeholder='https://...'
                label='Image URL'
              />
            </div>
            <div className='w-full md:w-full'>
              <InputField
                req
                name='articleType'
                value={!editFlag ? '' : currentArticle?.articleType}
                placeholder='Ex: bucketlist'
                label='Article Type'
              />
            </div>
          </div>

          <div className='mt-4 w-full md:w-4/5'>
            <label className='block mb-1 text-sm font-medium text-gray-900'>
              Article Content
              <span className='text-red-700'>*</span>
              <button
                id='autogenerate-btn'
                className='ml-3 border rounded-lg p-2 border-black'
                onClick={generate}
              >
                Autogenerate Content
              </button>
            </label>
            <div
              id='articleContent'
              className='bg-white w-full border border-inputBorder shadow-sidebarActive rounded-md outline-none p-3 text-sm min-h-[100px]'
              contentEditable
            >
              {!editFlag ? '' : currentArticle?.articleContent}
            </div>
          </div>
          <div className='mt-4 w-full md:w-4/5'>
            <label className='block mb-1 text-sm font-medium text-gray-900'>
              First 80 Characters
              <span className='text-red-700'>*</span>
            </label>
            <textarea
              placeholder='Add Something nice...'
              name='first80Characters'
              id='first80Characters'
              cols='auto'
              rows='10'
              required
              defaultValue={!editFlag ? '' : currentArticle?.first80Characters}
              className='bg-white w-full border border-inputBorder shadow-sidebarActive rounded-md outline-none p-3 text-sm'
            ></textarea>
          </div>

          <div className='grid grid-cols-2 mt-4 w-full md:w-4/5 gap-2 md:gap-4'>
            <div className='w-full md:w-full'>
              <button
                onClick={() => closeModal()}
                className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full font-bold flex justify-center items-center'
              >
                Cancel
              </button>
            </div>
            <div className='w-full md:w-full'>
              <button
                type='submit'
                className='bg-btnGray shadow-sidebarActive rounded-lg h-10 w-full flex justify-center items-center'
              >
                {editFlag ? 'Save' : 'Add'}
              </button>
            </div>
          </div>
        </form>
        {articleError && articleError.length > 0 && (
          <div className='block text-sm text-red-600'>{articleError}</div>
        )}
      </div>
    </div>
  );
}

export default CreateArticleModal;
