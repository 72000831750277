import React, { useState } from 'react'
import PasswordInput from '../../../sharedComponents/PasswordInput/PasswordInput'

function ChangePassword({ toggleResetPassword, handleResetPassword }) {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [err, setErr] = useState("");

  const handleSubmitResetPassword = () => {
    if (confirmPass === newPass) {
      setErr("");
      handleResetPassword(oldPass, newPass);
    } else {
      setErr("New and Confirm Password Don't match");
    }

  }

  const handleCurrentPass = (e) => {
    setOldPass(e.target.value);
  }

  const handleNewPass = (e) => {
    setNewPass(e.target.value);
  }

  const handleConfirmPass = (e) => {
    setConfirmPass(e.target.value);
  }

  return (
    <div>
      <div className="container w-fit absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 border border-black rounded-lg p-16">
        <h1 className='text-[23px]'>Change Password</h1>
        <div>
          <PasswordInput onChangeHandler={handleCurrentPass} label="Current Password" />
          <PasswordInput onChangeHandler={handleNewPass} label="New Password" />
          <PasswordInput onChangeHandler={handleConfirmPass} label="Confirm Password" />
        </div>
        <div className="mt-14">
          <div className='grid grid-cols-2 gap-3'>
            <button onClick={handleSubmitResetPassword} className='outline-none bg-btnPink h-11 w-36 flex items-center justify-center text-white rounded-lg mx-auto'>
              Confirm
            </button>
            <button onClick={toggleResetPassword} className='outline-none bg-btnGray h-11 w-36 flex items-center justify-center text-black rounded-lg mx-auto'>
              Cancel
            </button>
          </div>

          {err.length > 0 && (
            <div className='text-sm text-red-600'>{err}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangePassword